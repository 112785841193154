import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import { PageLayout, StyledHeatLogo, Title } from "../onboarding-styles";
import { useHistory } from "react-router-dom";
import { Success } from "../../../components/icons";
import { ContentWrapper, SuccessContainer } from "./success.styles";
import { PrimaryButton } from "../../shared/shared.styles";

const localeFn = (target: string) => import(`./../locale/${target.toLowerCase()}.json`);

const SurveySuccess = () => {
    const history = useHistory();

    const redirectToOverview = () => {
        history.push(`/overview`);
    }; 

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="privacy-page" fallback={null}>
            <PageLayout>

                <SuccessContainer>
                    <StyledHeatLogo />
                    
                    <ContentWrapper>
                        <Success />
                        <Title><FormattedMessage id={'success:title'} /></Title>
                        <PrimaryButton onClick={redirectToOverview}>
                            <FormattedMessage id={'success:goto-overview'} />
                        </PrimaryButton>
                    </ContentWrapper>

                </SuccessContainer>

            </PageLayout>
       </VRIntlProviderComponent>     
    )
}

export default SurveySuccess;