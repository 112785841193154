import { useState } from "react";
import { useUser } from "../../../../context/user";
import { FiltersProps } from "./filters.types";
import { FiltersList } from "../../../../enums/dashboard";
import { FiltersContainer } from "./filters.styles";
import { Roles } from "../../../../enums/user";
import TraineesFilter from "../../../../components/filters/trainees";
import DateFilter from "../../../../components/filters/date";
import SortingFilters from "../../../../components/filters/sorting-filters";

const Filters = ({
    onUserSelected,
    onDateChange,
    initialUserId,
    initialDateRange,
    dateRange,
    onSortingChange,
    sortingOption
}: FiltersProps) => {
    const {
        state: { role }
    } = useUser();
    
    const isAdminOrInstructor = role === Roles.Instructor || role === Roles.Admin;

    const [filterOpen, setFilterOpen] = useState(FiltersList.None);

    return (
        <FiltersContainer>
            {isAdminOrInstructor && (
                <TraineesFilter
                    isOpen={FiltersList.TraineesList === filterOpen}
                    handleOnClick={(val: any) => setFilterOpen(val)}
                    onUserSelected={onUserSelected}
                    initialUserId={initialUserId}
                />
            )}
            <DateFilter
                isOpen={FiltersList.DatePicker === filterOpen}
                handleOnClick={(val: any) => setFilterOpen(val)}
                onChange={onDateChange}
                initialDateRange={initialDateRange}
                dateRange={dateRange}
            />
            <SortingFilters
                isOpen={FiltersList.OrderBy === filterOpen}
                handleOnClick={(val: any) => setFilterOpen(val)}
                onSortingChange={onSortingChange}
                sortingOption={sortingOption}
            />
        </FiltersContainer>
    );
};

export default Filters;