import { useEffect, useState } from "react";
import { areaOptions, scatterOptions } from "./chart-data";
import { CustomAreaChart, ScatterChart } from "./styles";
import { convertDatatoApex, getMaxAxis, getMaxValue, getMinAxis } from './utils';
import { ChartData } from "../../../../types/combined-chart";
import { cloneDeep } from "lodash";
import { chartState } from "./index";
import { SessionDetails } from "../../../../constants/constants";

const BUFFER_VALUE = 0.1;

type CombinedChartProps = {
  chartStateByType: {
    [k: string]: boolean | undefined;
  },
  areaChartData: ChartData[],
  scatterChartData: ChartData[]
}

const getAreaSeriesData = (chartData: ChartData[]) => {
  const finalData = chartData.map((chart: ChartData, index: number) => {
    return {
       name: chart.name,
       data: convertDatatoApex(chart.data) || [],
       yAxis: index,
       color: chart.color,
       unit: chart.unit
    }
  });

  return finalData;
}

const getAreaOptionsData = (chartData: ChartData[]) => {
  const optionsCopy = cloneDeep(areaOptions)
  //to set yaxis for area chart
  const yAxisData = chartData?.map((chart: ChartData)=>  {
    return {
      title :{
        name: chart.name,
      },
      min: 0,
      max: getMaxValue(chart.data) + BUFFER_VALUE,
      show: false 
    }
  });
  optionsCopy.yaxis = cloneDeep(yAxisData);

  return optionsCopy;
} 

//set scatter chart axis to area chart data
const getScatterOptionsData = (chartData: ChartData[]) => {
  const finalOptions = cloneDeep(scatterOptions)
  const chartAxisData = chartData.flatMap((chart:ChartData)=>chart.data);

  finalOptions.xaxis.min = getMinAxis(chartAxisData);
  finalOptions.xaxis.max = getMaxAxis(chartAxisData);

  return finalOptions;
}

//create series data from API
const getScatterSeriesData = (scatterData: ChartData[], chartData: ChartData[]) => {
  const sortedData = scatterData.sort((a,b) => b.position - a.position);
  const chartAxisData = chartData.flatMap((chart:ChartData)=>chart.data);
  const minAxis = getMinAxis(chartAxisData);

  const finalData = sortedData?.map((chart:ChartData) => {
    return {
      data: convertDatatoApex(chart?.data) || [],
      color: chart?.color,
      name: chart.name,
      radioLabel: chart.radioLabel,
      startTime: new Date(minAxis)
    }
  })
  return finalData;
}

//filter series data on radio toggle
const filterSeriesData = (data: any, chartStateByType: chartState) => {
  let filteredData = cloneDeep(data);
  filteredData = filteredData.map((series: any) => {
    if(chartStateByType[series.name] === false){
      series.data = []
    }
   return series;
  })

  return filteredData;
}

const CombinedChartFiring = ({
  chartStateByType,
  areaChartData,
  scatterChartData
}: CombinedChartProps) => {
  const laserData = scatterChartData?.filter((chart:ChartData) => chart?.name === 'Laser');
  const hitData = scatterChartData.filter((chart:ChartData) => chart?.name !== 'Laser');
  const areaSeriesData = getAreaSeriesData(areaChartData);
  const areaOptions = getAreaOptionsData(areaChartData);

  const laserOptionsData = getScatterOptionsData(areaChartData);
  const laserSeriesData = getScatterSeriesData(laserData, areaChartData);

  const hitOptionsData = getScatterOptionsData(areaChartData);
  const hitSeriesData = getScatterSeriesData(hitData, areaChartData);

  const [seriesAreaData, setSeriesData] = useState(areaSeriesData);
  const [hitSeries, setHitSeries] = useState(hitSeriesData);
  const [laserSeries, setLaserSeries] = useState(laserSeriesData);

  useEffect(() => {
    //to disable area chart which is toggled off
    setSeriesData(filterSeriesData(areaSeriesData, chartStateByType));
    setHitSeries(filterSeriesData(hitSeriesData, chartStateByType));
    setLaserSeries(filterSeriesData(laserSeriesData, chartStateByType));
  }, [chartStateByType]);

  return (
    <>
      {chartStateByType[SessionDetails.LASER] && <ScatterChart options={laserOptionsData} series={laserSeries} type="scatter" height={40} />}
      {(chartStateByType[SessionDetails.FIRINGHIT] || chartStateByType[SessionDetails.FIRINGMISS]) && 
        <ScatterChart options={hitOptionsData} series={hitSeries} type="scatter" height={40} />
      }

      <CustomAreaChart
        options={areaOptions}
        series={seriesAreaData}
        type="area"
        height={300}
      />
    </>
  );
};

export default CombinedChartFiring;
