import { ChartDataSet } from "../../../../types/combined-chart";

//To get the time difference in minutes and seconds
export const getTimeDifference = (startTime:Date, endTime: Date) => {
  const difference = endTime.getTime() - startTime.getTime();
  const minutes = Math.floor(difference / (1000 * 60));
  const seconds = Math.floor((difference / 1000) % 60);

  const timeDifference = minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  return timeDifference;
}

/* When airspeed and altitude toggles are turned off, we still need to show the annotation.
 We can create it by showing empty chart, this function will provide the data for it */
export const createEmptyData = (airtimeData: ChartDataSet[]): [Date, any][] => (
    airtimeData.map(dataSet => [
      new Date(dataSet.DateTime),
      null
    ])
  );

//convert the data from API to APEX format  
export const convertDatatoApex = (data : ChartDataSet[]): [Date, number][] => (
    data.map(dataSet => [
      new Date(dataSet.DateTime),
      Math.trunc(dataSet.Data)
    ])
  ); 

export const getMaxValue = (axisData: ChartDataSet[]) => {
  const data = axisData?.map((data:ChartDataSet)=> data.Data);
  return Math.max(...data);
}
  


  