import { useHistory } from "react-router-dom";
import { getTimeDifference } from "../../pages/session-detail/components/timeline-chart-with-firing/utils";
import {
    BulletPoint,
    CardArrowContainer,
    DateTimeAndDurationContainer,
    TraineeNameDiv,
    SessionDetails,
    SessionIconContainer,
    SessionInfoContainer,
    SessionItemContainer,
    SessionTitle,
    DateAndTimeDiv,
    DurationDiv,
    DateDiv,
    TimeDiv,
    StyledImg,
    DetailItemText
} from "./session-item.styles";
import Users from "../icons/users";
import Time from "../icons/time";
import { SessionItemProps } from "./session-item.types";
import { CalendarIcon24, CardArrowIcon } from "../icons";
import moment from "moment";

const SessionItem = ({ session, selectedUserId }: SessionItemProps) => {
    const history = useHistory();

    const duration = (from: any, to: any) => {
        return getTimeDifference(new Date(from), new Date(to));
    };

    const handleClick = () => {
        const { id, name, simulationName, logoUrl, userId } = session;
        const { from, to, scenarioInstanceId, simulationId, scenarioName } =
            session.sessions[0];
        history.push(
            `/sessions/session-analysis?traineeName=${name}&simulationName=${simulationName}&userId=${userId}&from=${from}&to=${to}&scenarioId=${simulationId}&simulationInstanceId=${id}&scenarioInstanceId=${scenarioInstanceId}&name=${scenarioName}&logoUrl=${logoUrl}`
        );
    };

    const sessionDate = moment(session.sessions[0].from).format("DD/MM/YYYY");
    const fromTime = moment(session.sessions[0].from).format("HH:mm:ss");

    return (
        <SessionItemContainer onClick={handleClick}>
            <SessionIconContainer>
                {session.logoUrl && (
                    <StyledImg src={session.logoUrl} alt="Session Logo" />
                )}
            </SessionIconContainer>

            <SessionInfoContainer>
                <SessionTitle>
                    <span>{session.simulationName}</span>
                    <BulletPoint className="bullet-point">&bull;</BulletPoint>
                    <span>{session.sessions[0].scenarioName}</span>
                </SessionTitle>
                <SessionDetails>
                    <TraineeNameDiv>
                        <Users fill="#999999" />
                        <DetailItemText>{session.name}</DetailItemText>
                    </TraineeNameDiv>

                    <DateTimeAndDurationContainer>
                        <DateAndTimeDiv>
                            <DateDiv>
                                <CalendarIcon24 fill="#999999" />
                                <DetailItemText>{sessionDate}</DetailItemText>
                            </DateDiv>

                            <TimeDiv>
                                <Time fill="#999999" />
                                <DetailItemText>{fromTime}</DetailItemText>
                            </TimeDiv>
                        </DateAndTimeDiv>

                        <DurationDiv>
                            <BulletPoint>&bull;</BulletPoint>
                            <span>
                                {duration(
                                    session.sessions[0].from,
                                    session.sessions[0].to
                                )}
                            </span>
                        </DurationDiv>
                    </DateTimeAndDurationContainer>
                </SessionDetails>
            </SessionInfoContainer>

            <CardArrowContainer className="chevron-container">
                <CardArrowIcon />
            </CardArrowContainer>
        </SessionItemContainer>
    );
};

export default SessionItem;
