import { useCallback, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from 'react-router-dom';
import { Trainee, TraineeResponse } from '../../types/trainee';

import { getTrainees } from "../../api/trainee";
import { SearchIcon } from "../../components/icons";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Button from "../../components/ui/button";
import Loader, { CardLoader } from "../../components/ui/loader";
import Modal from "../../components/ui/modal";
import Page, { PageLoader } from "../../components/ui/page";
import { useFeatureToggles } from '../../context/feature-toggles';
import { Pages } from '../../enums/pages';
import AddTraineeModal from "../../modules/add-trainee";
import TraineesList from "../../modules/trainees-list";
import { AddTrainee, FiltersContainer, Header, LoadMore, Search, Tab, TabContainer } from "./trainees.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const fetchTrainees = async (
    token: string | null,
    search: string | null,
    status: string | null | undefined) => {
    return await getTrainees(token, search, status);
}

type TabsProps = {
    totalPending: number,
    onClick: () => void,
}

const Tabs = ({ totalPending = 0, onClick }: TabsProps) => {
    const intl = useIntl();

    const tabs = [
        { name: intl.formatMessage({ id: 'trainees:active-users', defaultMessage: 'Active Users' }), page: Pages.TraineesActive },
        { name: intl.formatMessage({ id: 'trainees:pending-invites', defaultMessage: 'Pending invites' }), page: Pages.TraineesPending }
    ];

    return <TabContainer>
        {tabs.map(({ name, page }) =>
            <Tab
                key={`tab-${name}`}
                to={page}
                onClick={onClick}
                isActive={(match, location) => page === location.pathname}>
                {page === Pages.TraineesPending ? `${name} (${totalPending})` : name}
            </Tab>)}
    </TabContainer>;
}

type FiltersProps = {
    onAddTraineeClick: () => void,
    onSearchChange: (e: any) => void,
    search: string | null
}

const Filters = ({ onAddTraineeClick, onSearchChange, search }: FiltersProps) => {
    const intl = useIntl();
    return <FiltersContainer>
        <Search>
            <SearchIcon />
            <DebounceInput
                debounceTimeout={200}
                value={search || ''}
                onChange={onSearchChange}
                placeholder={intl.formatMessage({ id: 'trainees:search' })} />
        </Search>
        <AddTrainee>
            <Button variant="orange" onClick={onAddTraineeClick}>
                <FormattedMessage id="trainees:add-trainee" />
            </Button>
        </AddTrainee>
    </FiltersContainer>
}

const Trainees = () => {
    const [page, setPage] = useState(1);
    const [token, setToken] = useState(null);
    const [trainees, setTrainees] = useState<Trainee[]>([]);
    const [openAddTraineeModal, setOpenAddTraineeModal] = useState(false);
    const [search, setSearch] = useState<string | null>('');
    const [loading, setLoading] = useState(false);
    const intl = useIntl();
    const params = useParams<any>();
    const { isFeatureActive } = useFeatureToggles();

    const pendingInvitesEnabled = isFeatureActive('PENDING_INVITES');

    useEffect(() => {
        setLoading(true);
        fetchTrainees(token, search, params?.tab).then(({ items, skipToken }) => {
            setTrainees([
                ...trainees,
                ...items.map(
                    ({ userId, displayName, createDate, email, lastInvitationSent, status }: TraineeResponse) => {
                        return {
                            id: userId,
                            email,
                            name: displayName,
                            dateAdded: createDate,
                            lastInvitationSent,
                            status
                        };
                    }
                ) || [],
            ]);
            setToken(skipToken);
            setLoading(false);
        });
    }, [page, search, params]);

    const handleOnClickAddTrainee = useCallback(() => {
        setOpenAddTraineeModal(true);
    }, []);

    const handleOnSearchChange = useCallback((e: any) => {
        setToken(null);
        setTrainees([]);
        setSearch(e.target.value);
        setPage(1);
    }, []);

    const handleOnLoadMore = useCallback(() => {
        setPage(page + 1);
    }, [token]);

    const handleOnTabClick = useCallback(() => {
        setToken(null);
        setTrainees([]);
        setSearch(null);
        setPage(1);
    }, []);

    const isFirstLoad = page === 1 && loading;

    return <>
        <Page
            code="trainees-page"
            title={intl.formatMessage({ id: "trainees:title" })}
            description={intl.formatMessage({ id: "trainees:description" })}
        >
            <Header.Container justify={pendingInvitesEnabled ? 'space-between' : 'flex-end'}>
                {pendingInvitesEnabled && <Header.Column>
                    <Tabs totalPending={3} onClick={handleOnTabClick} />
                </Header.Column>}
                <Header.Column justify="flex-end">
                    <Filters
                        onAddTraineeClick={handleOnClickAddTrainee}
                        search={search}
                        onSearchChange={handleOnSearchChange} />
                </Header.Column>
            </Header.Container>
            {isFirstLoad ? <CardLoader transparent={true} /> :
                <TraineesList
                    trainees={trainees}
                    tab={params?.tab} />}
            {token && <LoadMore onClick={handleOnLoadMore}>
                {loading ? <Loader /> : <FormattedMessage id="trainees:load-more" />}
            </LoadMore>}
        </Page>
        {openAddTraineeModal &&
            <Modal
                closeDisabled={false}
                width={"40rem"}
                height="30rem"
                onCloseClick={() => { setOpenAddTraineeModal(false) }}>
                <AddTraineeModal />
            </Modal>
        }</>
}

const TraineesPage = () =>
    <VRIntlProviderComponent
        localeFn={localeFn}
        id="trainees" fallback={<PageLoader />}>
        <Trainees />
    </VRIntlProviderComponent>

export default TraineesPage;