export const extractDate = (dateString: string | undefined): string => {
    let date = new Date(dateString || "");
    let year: number = date.getFullYear();
    let month: number = date.getMonth() + 1;
    let day: number = date.getDate();

    let formattedMonth: string = month < 10 ? "0" + month : month.toString();
    let formattedDay: string = day < 10 ? "0" + day : day.toString();

    return `${formattedDay}/${formattedMonth}/${year}`;
};
