import styled from "styled-components";
import Text from "../../../../components/ui/text";

export const SessionCardWrapperDiv = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.grey[800]};
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.grey[600]};
        cursor: pointer;
    }
`;

export const SecondaryWrapperDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto 0.4375rem auto 1rem;
    gap: 0.75rem;
`;

export const ContentWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;

export const LogoWrapper = styled.div``;
export const StyledImage = styled.img``;

export const TitleWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
export const UserInfoWrapper = styled.div`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 0.25rem;
    align-items: center;
    width: fit-content;
`;
export const TruncatedStyledText = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    grid-column: 3;
`;

export const DateTimeAndDurationWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
export const TimeWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
export const DurationWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const ArrowWrapper = styled.div`
    align-self: center;
`;
