import { UserStatus } from "../components/users-table/users-table.types";

export const getStatus = (
    isRegistrationCompleted: boolean,
    isActive: boolean
) => {
    if (isRegistrationCompleted && isActive) {
        return UserStatus.Active;
    } else if (!isRegistrationCompleted && isActive) {
        return UserStatus.Pending;
    } else if (!isActive) {
        return UserStatus.Deactivated;
    }
};
