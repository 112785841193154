import styled from "styled-components";
import { get } from "lodash";
import { ReactNode } from "react";

type StyledTextProps = {
  [prop: string]: any,
}

const StyledText = styled.span<StyledTextProps>`
  ${({ align }) => align && `text-align:${align}`};
  font-size: ${({ theme, variant, fontSize }) => fontSize || theme.typography[variant].fontSize};
  font-weight: ${({ theme, variant, fontWeight }) => fontWeight || theme.typography[variant].fontWeight};
  line-height: ${({ theme, variant }) => theme.typography[variant].lineHeight};
  letter-spacing: ${({ theme, variant, letterSpacing }) => letterSpacing || theme.typography[variant].letterSpacing};

  ${({ marginTop }) => marginTop && `margin-top:${marginTop}`};
  ${({ marginRight }) => marginRight && `margin-right:${marginRight}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom:${marginBottom}`};
  ${({ marginLeft }) => marginLeft && `margin-left:${marginLeft}`};

  ${({ theme, color }) => color && `color:${get(theme.colors, color)}`};
`;

type TextProps = {
  variant?: string,
  children: ReactNode,
  [prop: string]: any
}

const Text = ({
  variant = 'smallMedium',
  children,
  ...props
}: TextProps) => <StyledText {...props} variant={variant}>{children}</StyledText>;

export default Text;
