import { ChangeEvent } from "react";
import { Option } from "../users-filter";

export interface OptionsBarProps {
    searchInputValue: string | null;
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    setShouldUpdateUsersList: React.Dispatch<React.SetStateAction<boolean>>;
    onRoleChange: (roles: Option[]) => void;
    onStatusChange: (status: string) => void;
}

export enum AddUsersEmailErrorAPIResponse {
    InvalidEmail = "RegularExpressionValidator",
    EmailAlreadyExists = "EmailAlreadyExists",
    MaxiSizeReached = "MaxSizeReached"
}