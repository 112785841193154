import { Wrapper, Main } from "./styles";
import Instruments from './instruments';
import { useFlightDataTimeline } from './hooks/useFlightDataTimeline';
import Controls from './controls';
import React, { useMemo } from 'react';

interface ChartData {
  AirSpeed: any[];
  AttitudePitch: any[];
  AttitudeBank: any[];
  Heading: any[];
  VerticalSpeed: any[];
  Altimeter: any[];
}

const CockpitTimeline = ({ chartData }: { chartData: ChartData }) => {
  const {
    currentTick,
    setTick,
    isPlaying,
    play,
    pause,
    totalTickCount,
    currentTimecode,
    durationTimecode,
    currentObservations,
  } = useFlightDataTimeline(chartData);

  const noData = useMemo(() => durationTimecode === '0:00', [durationTimecode]);

  return (
    <Wrapper
      bottomGap={true}
      borderTopRadius={true}
      borderBottomRadius={true}
    >
      <Main>
        <Instruments
          noData={noData}
          airSpeed={currentObservations.AirSpeed}
          bank={currentObservations.AttitudeBank}
          pitch={currentObservations.AttitudePitch}
          altitude={currentObservations.Altimeter}
          heading={currentObservations.Heading}
          verticalSpeed={currentObservations.VerticalSpeed}
        />
        <Controls
          isDisabled={noData}
          isPlaying={isPlaying}
          play={play}
          pause={pause}
          currentTick={currentTick}
          setTick={setTick}
          totalTickCount={totalTickCount}
          currentTime={currentTimecode}
          duration={durationTimecode}
        />
      </Main>
    </Wrapper>
  )
};
  
export default CockpitTimeline;
