import styled from "styled-components";
import base from "../../../themes/base/base";
import { RadioGroup } from "@mui/material";

export const WelcomeTitle = styled.span`
    ${base.typography.extraLarge2Regular}; 
`;

export const WelcomeDesc = styled.span`
    ${base.typography.regular}; 
`;

export const SurveyTitle = styled.div`
    ${base.typography.extraLargeMedium};  
    border-bottom: 1px solid ${base.colors.primary.orange[500]};
    text-align: center; 
    width: 100%;
`;

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
`;

export const Label = styled.label`
    ${base.typography.baseMedium}; 
    margin-bottom: 1.5rem;
`;

export const Error = styled.span`
    ${base.typography.smallRegular}; 
    margin-top: 4px;
    color: ${base.colors.semantic.red[500]};
`;

export const FormInput = styled.input`
    ${base.typography.smallRegular}; 
    background: ${base.colors.neutral.grey[900]};
    color: ${base.colors.neutral.grey[50]};
    width: 28.5rem;
    padding: 0.5rem 0.75rem;
    border: 0;
    outline: none;
    &:focus {
        border: 1px solid #ee7127;
    }
    &.error{
        border: 1px solid ${base.colors.semantic.red[500]};
    }
`;

export const FieldsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const RadioLabel = styled.label`
    display: flex;
    gap: 0.5rem;
    ${base.typography.baseRegular}; 
`;

export const ErrorBanner = styled.div`
    width: 100%;
    text-align: center;
    padding: 0.625rem;
    margin-bottom: 1.5rem;
    background-color: ${base.colors.semantic.red[900]};
    color: ${base.colors.semantic.red[500]};
    ${base.typography.smallRegular}; 
`;

export const CustomIcon = styled('span')(({
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxShadow: `0 0 0 1px ${base.colors.neutral.grey[50]}`,
    backgroundColor: "transparent",

    '.Mui-focusVisible &': {
      outline: '1px auto #F4A06E',
    },

    'input:hover ~ &': {
      boxShadow: `0 0 0 1px ${base.colors.primary.orange[500]}`,
    },

    'input:disabled ~ &': {
      boxShadow: `0 0 0 1px ${base.colors.neutral.grey[700]}`,
      background: '#121212',
    },
  }));
  
export const CustomCheckedIcon = styled(CustomIcon)({
    boxShadow: `0 0 0 2px ${base.colors.primary.orange[500]}`,
    backgroundColor: `${base.colors.primary.orange[500]}`,
    
    '&::before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: 'radial-gradient(#000,#000 28%,transparent 40%)',
      content: '""',
    },

    'input:hover ~ &': {
      boxShadow: `0 0 0 2px ${base.colors.primary.orange[300]}`,
      backgroundColor: `${base.colors.primary.orange[300]}`,

    },

    'input:disabled ~ &': {
        boxShadow: `0 0 0 1px ${base.colors.neutral.grey[700]}`,
        background: `${base.colors.neutral.grey[700]}`,
      },
  });

  export const StyledRadioGroup = styled(RadioGroup)`
    gap: 1rem;

    .MuiFormControlLabel-root{
        align-self: flex-start;
        width: 12rem;

        .MuiButtonBase-root{
            color: ${base.colors.neutral.grey[50]};
            padding: 0 9px;
        }

        &:hover{
            background-color: ${base.colors.neutral.grey[600]};
        }
    }

    .MuiTypography-root{
        ${base.typography.baseRegular}; 
    }

    .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }

    &.error {
        .radio-icon {
            box-shadow: ${base.colors.semantic.red[500]} 0px 0px 0px 1px;
        }
    }
`;
