import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getScore } from "../../../../api/sessions";
import Loader from "../../../../components/ui/loader";
import Message from "../../../../components/ui/message";
import BaseColors from "../../../../themes/base/base";
import useQueryParams from "../../../../utils/use-query-params";
import { LoaderContainer } from "../../session-detail.styles";
import BarC from "./BarC";
import RadarC from "./RadarC";
import RadialC from "./RadialC";
import { Container } from "./score.styles";

export enum AttributesTypes {
  Procedural = "PROCEDURAL",
  Physiological = "PHYSIOLOGICAL",
}

export const LINES_COLORS = {
  scoreAchieved: BaseColors.colors.success,
  expectedScore: "#B7B7B7",
  procedural: BaseColors.colors.primary.orange[500],
  physiological: "#C62771",
};

export type AttributesProps = {
  attributes: Attribute[];
}

export type Attribute = {
  name: string;
  score: number;
  weight: number,
  type: string, // "Procedural" | "Physiological"
};

type ScoreChartProps = {
  attributes: Attribute[];
  totalScore?: number;
  isRadial?: boolean;
};

export type ChartPoint = {
  x: number;
  y: number;
  value: number;
  width?: number;
};

const NoData = () => <Message>
  <FormattedMessage id="no-data" />
</Message>;

const ScoreChart = ({ attributes, isRadial, totalScore }: ScoreChartProps) => {
  switch (attributes.length) {
    case 1:
    case 2:
      return <BarC attributes={attributes} />;

    default:
      return isRadial && totalScore ? <RadialC attributes={attributes} totalScore={totalScore} /> : <RadarC attributes={attributes} />;
  }
};

const Score = () => {
  const params: any = useQueryParams();
  const userId = params.get("userId");
  const scenarioInstanceId = params.get("scenarioInstanceId");
  const scoreId = params.get("scenarioId");
  const simulationInstanceId = params.get("simulationInstanceId");

  const { data, isLoading } = useQuery(
    ["getScore", userId, scenarioInstanceId, scoreId, simulationInstanceId],
    () => getScore({ userId, scenarioInstanceId, scoreId, simulationInstanceId }),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  if (isLoading) return <LoaderContainer><Loader /></LoaderContainer>;
  if (!data) return <Container><NoData /></Container>;

  return (
    <Container>
      {data.scoreAttributes.length > 0 ?
        <>
          <ScoreChart attributes={data.scoreAttributes} totalScore={data.totalScore} isRadial={true} />
          <ScoreChart attributes={data.scoreAttributes} isRadial={false} />
        </>
        : <NoData />
      }
    </Container>
  )
};

export default Score;
