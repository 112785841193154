import {
  DashboardIcon,
  LockIcon,
  SupportIcon,
  UsersIcon,
  SessionsIcon,
  ProgressIcon,
  Settings2Icon,
  ReportsIcon
} from "../../components/icons";
import PizzaChart from "../../components/icons/pizza-chart";
import { useFeatureToggles } from "../../context/feature-toggles";
import { useUser } from "../../context/user";
import { Features } from "../../enums/features";
import { Pages } from "../../enums/pages";
import { Roles } from "../../enums/user";

interface SiteMap {
  childNodes: any[];
  code: string;
  description: string;
  order: number;
  title: string;
  url: string;
}

interface MenuItem {
  code: string;
  icon: any;
  order: number;
  description?: string;
  label: string;
  notAllowedTo?: Roles[];
  path: string;
  enabled: boolean;
  section: string;
}

const getNavigationItems = () => {
  const { state: user } = useUser();
  const { isFeatureActive } = useFeatureToggles();
  let siteMapStringify = window.localStorage.getItem("siteMap");

  let siteMap = siteMapStringify ? JSON.parse(siteMapStringify) : [];

  const iconMapping: any = {
    "overview-page": { icon: PizzaChart, enabled: true },
    "sessions-page": { icon: SessionsIcon, enabled: true },
    "authentication-page": { icon: LockIcon, enabled: isFeatureActive(Features.AuthenticationPage) },
    "trainees-page": { icon: UsersIcon, enabled: true },
    "users-page": { icon: UsersIcon, enabled: true },
    "reports-page": { icon: ReportsIcon, enabled: true },
    "progress-page": { icon: ProgressIcon, enabled: true },
    "setting-page": { icon: Settings2Icon, enabled: true },
    "support-page": { icon: SupportIcon, enabled: true },
  };

  siteMap.forEach((section: any) => {
    // Filter out navigation items that are not enabled
    section.navigationItems = section.navigationItems.filter((item: any) => {
      const mapping = iconMapping[item.code];
      if (mapping) {
        item.icon = mapping.icon;
        item.enabled = mapping.enabled;
        return item.enabled; // Keep only enabled items
      }
      return false; // Exclude items without a mapping
    });

    // Sort the filtered navigation items within each section by order
    section.navigationItems.sort((a: any, b: any) => a.order - b.order);
  });

  // Filter out sections that are not visible or have no items after filtering
  siteMap = siteMap.filter((section: any) => section.navigationItems.length > 0);

  // Sort the filtered sections by order
  siteMap.sort((a: any, b: any) => a.order - b.order);

  return siteMap;
};

export default getNavigationItems;
