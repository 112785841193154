import styled from "styled-components";

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
	background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
  border-radius: 0.5rem;
`;

export const Container = styled.div`
	padding: 1.7rem;
	background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
	display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 1.4rem;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.neutral.grey[700]};
`;

export const TimeLeft = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 1rem;
`;
