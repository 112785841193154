import styled from "styled-components";

export const ToolTip = styled.div`
  background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
  padding: 0.6rem 1rem;
`;

export const CustomTooltip = {
  Container: styled.div`
    background-color: ${({ theme }) => theme.colors.legacy.gray[400]};
    padding: 0.6rem;
  `,
};
