import { FormattedMessage,useIntl } from "react-intl";
import { useModal } from "../../../../../context/modal/modal";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import {
    ButtonsWrapper,
    LogoutDescription,
    ModalContentWrapperDiv,
    StyledCancelButton,
    StyledConfirmButton,
    Title
} from "../modal-contents.styles";
import Loader from "../../../loader";
interface Props {
    title: string;
    description?: string;
}

const LogoutConfirmationModal = ({
    title,
    description,

}: Props) => {
    const { instance } = useMsal();
    const { closeModal } = useModal();
    const [loading, setLoading] = useState(false);

    const handleLogOut = () => {
        setLoading(true)
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      };

    const handleOnCancel = () => {
        closeModal();
    };

    const intl = useIntl();
    const localizedText = intl.formatMessage({ id: description });    
    const logoutText = localizedText.split('\\n');  

    return (
        <ModalContentWrapperDiv>
            <LogoutDescription>
                    {logoutText?.map((logoutText, index) => (
        <div key={index}>{logoutText}</div>
      ))}
            </LogoutDescription>

            <ButtonsWrapper>
                <StyledConfirmButton
                    variant="orange"
                    onClick={handleLogOut}
                    disabled={loading}
                >
                    {loading ? <Loader size="medium" color="#666666" fontSize="1.5rem"/> :
                        <FormattedMessage id="logout:modal-contents:title" />
                    } 
                </StyledConfirmButton>

                <StyledCancelButton
                    onClick={handleOnCancel}
                >
                    <FormattedMessage id="logout:modal-contents:cancel" />
                </StyledCancelButton>
            </ButtonsWrapper>

        </ModalContentWrapperDiv>
    );
};

export default LogoutConfirmationModal;
