import styled from "styled-components";
import base from "../../../themes/base/base";

export const SearchBarWrapperDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 18.25rem;
    background-color: ${base.colors.neutral.grey[800]};
    border-radius: 2px;
    padding: 0.4375rem 0.75rem;

    & input {
        ${base.typography.smallRegular};
        color: ${base.colors.neutral.grey[50]};
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;

        &::placeholder {
            color: ${base.colors.neutral.grey[500]};
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
`;