import { DefaultTheme } from "styled-components";

const base: DefaultTheme = {
  fonts: {
    main: "Roboto",
  },
  typography: {
    //NEW DESIGN SYSTEM STYLES - TO USE
    extraLarge3Light: {
      fontWeight: 300,
      fontSize: "2.25rem",
      lineHeight: "3.375rem",
      letterSpacing: "0.0225rem",
    },
    extraLarge2Bold: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: "3rem",
      letterSpacing: "0.02rem ",
    },
    extraLarge2Regular: {
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "3rem",
      letterSpacing: "0.02rem",
    },
    extraLargeMedium: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
      letterSpacing: "0.015rem",
    },
    largeMedium: {
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: "1.875rem",
      letterSpacing: "0.0125rem",
    },
    baseRegular: {
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
      letterSpacing: "0.01125rem",
    },
    baseMedium: {
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
      letterSpacing: "0.01125rem",
    },
    smallRegular: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.01rem",
    },
    smallMedium: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.01rem",
    },
    extraSmallRegular: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.00875rem",
    },
    extraSmallMedium: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.00875rem",
    },
    //OLD STYLES - DO NOT USE
    extraLarge3: {
      fontWeight: 700,
      fontSize: "2.5rem",
      lineHeight: "3rem",
    },
    extraLarge2: {
      fontWeight: 300,
      fontSize: "2rem",
      lineHeight: "2.5rem",
    },
    extraLarge: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    large: {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
    },
    medium: {
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
    },
    regular: {
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
    },
    sMedium: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
    sRegular: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
    xsMedium: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1rem",
    },
    xsRegular: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "1rem",
    },
  },
  colors: {
    primary: {
      orange: {
        50: "#fdf1e9",
        100: "#fad3bc",
        200: "#f7be9c",
        300: "#f4a06e",
        400: "#f18d52",
        500: "#ee7127",
        600: "#d96723",
        700: "#a9501c",
        800: "#833e15",
        900: "#642f10",
        text: "#111111",
      },
      purple: {
        50: "#f4e9ef",
        100: "#dcbace",
        200: "#cb99b6",
        300: "#b36b94",
        400: "#a44e80",
        500: "#8d2260",
        600: "#801f57",
        700: "#641844",
        800: "#4e1335",
        900: "#3b0e28",
        text: "#111111",
      },
      blue: {
        50: "#e7eef1",
        100: "#b3cad3",
        200: "#8fb0bd",
        300: "#5c8c9f",
        400: "#3c758d",
        500: "#0b5370",
        600: "#0a4c66",
        700: "#083b50",
        800: "#062e3e",
        900: "#05232f",
        text: "#111111",
      },
    },
    neutral: {
      grey: {
        50: "#f2f2f2",
        100: "#cccccc",
        200: "#b3b3b3",
        300: "#999999",
        400: "#7f7f7f",
        500: "#666666",
        600: "#4d4d4d",
        700: "#383838",
        800: "#262626",
        900: "#121212",
      },
    },
    semantic: {
      green: {
        50: "#f2f3ea",
        100: "#d8d9be",
        200: "#c5c69e",
        300: "#abad72",
        400: "#9a9d56",
        500: "#81842c",
        600: "#757828",
        700: "#5c5e1f",
        800: "#474918",
        900: "#363712",
      },
      red: {
        50: "#f9ecec",
        100: "#ecc3c4",
        200: "#e2a6a7",
        300: "#d57d7f",
        400: "#cd6466",
        500: "#c13d40",
        600: "#b0383a",
        700: "#892b2d",
        800: "#6a2223",
        900: "#511a1b",
      },
    },
    legacy: {
      yellow: {
        300: "#f7b96e",
        800: "#7c3d18",
        900: "#7C3D18"
      },
      pink: {
        400: "#ff647c",
      },
      dark: {
        800: "#1a1a1a",
      },
      gray: {
        50: "#f2f2f2",
        100: "#999999",
        150: "#555555",
        200: "#383838",
        250: "#333333",
        300: "#1a1a1a",
        400: "#111111",
        500: "#0b0b0b",
      },
    },
    white: "#f2f2f2",
    success: "#81842c",
    error: "#c13d40",
    danger: "#c13d40",
    warning: "#f5b15f",
  },
};

export default base;
