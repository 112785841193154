import styled, { keyframes } from "styled-components";
import { CardLoaderProps } from ".";

export const pulse = keyframes`
  0% {
        opacity: 0.65;
    }
    50% {
        opacity:1 ;
    }
    100% {
        opacity: 0.65;
    }
`;

export const spin = keyframes`
  0% {
        transform:rotate(0deg);
        opacity: 0.5;
    }
    50% {
        transform:rotate(180deg);
        opacity:1 ;
    }
    100% {
        transform:rotate(360deg);
        opacity: 0.5;
    }
`;

export const CardLoaderContainer = styled.div<CardLoaderProps>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
  width: 100%;
  ${({ transparent }) => transparent && `background: transparent`};
  height: ${({ height }) => height || "11rem"};
  margin-right: 1rem;
  animation: ${pulse} 1s infinite linear;
  overflow: hidden;
  border-radius: 0.6rem;
`;
