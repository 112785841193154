import moment from "moment";
import { Suspense, useMemo, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import ArrowRight from "../../components/icons/arrow-right";
import Trending from "../../components/icons/trending";
import Timeline from "../../components/icons/timeline.svg";
import Trainee from "../../components/icons/trainee.svg";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import Styled from "../../components/ui/styled";
import { Pages } from "../../enums/pages";
import useQueryParams from "../../utils/use-query-params";
import ActionsPerformed from "./components/actions-performed";
import Score from "./components/score";
import { Main, Section, StyledImg, StyledImgTimeline, OverviewButtonContainer } from "./session-detail.styles";
import { SessionProvider, useSession } from "../../context/session";
import { LandingScore } from "./components/landing-score";
import CustomTopStats from "./components/custom-top-stats";
import TimelineChartFiring from "./components/timeline-chart-with-firing";
import TimelineCockPitTab from "./components/timline-cockpit-tab";
import MapTimeline from './components/map-timeline';
import { getTimeDifference } from "./components/timeline-chart-with-firing/utils";
import LayoutGrid, { LayoutRow } from '../../components/layout-grid/layout-grid';
import { DummyContainer } from './session-detail.styles';

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

const SessionDetail = () => {  
  const offset: number = 200;
  const { sessionPeriodFrom, sessionPeriodTo } =
    useSession();
  const intl = useIntl();
  const history = useHistory();
  const params: any = useQueryParams();
  const name = params.get("name");
  const simulationName = params.get("simulationName");
  const traineeName = params.get("traineeName");
  const logoUrl = params.get("logoUrl");
  const sessionDuration = getTimeDifference(new Date(sessionPeriodFrom),new Date(sessionPeriodTo));
  const { state }: {state: Record<string, string>} = useLocation();

  const handleOnClickOverview = useCallback(() => {
    history.push(
      `${Pages.SessionsOverview}?userId=${params.get(
        "userId"
      )}&name=${encodeURIComponent(
        name
      )}&from=${sessionPeriodFrom}&to=${sessionPeriodTo}`
    );
  }, [history, params, name, sessionPeriodFrom, sessionPeriodTo]);

  const getSubtitleSessionLabel = useMemo(() => {
    const sessionDate = moment(sessionPeriodFrom || sessionPeriodTo).format("DD/MM/YYYY");
    const fromTime = moment(sessionPeriodFrom).format("HH:mm");

    return (
      <span>
        <StyledImg width={24} src={Trainee} />
        {state?.traineeName || traineeName}
        <StyledImgTimeline width={20} src={Timeline} />
        {sessionDate} {`${fromTime} \u2022 ${sessionDuration}`}
      </span>
    );
  }, [sessionPeriodFrom, sessionPeriodTo, state?.traineeName, traineeName, sessionDuration]);

  const title = useMemo(() => {
    const sessionTitle = name || intl.formatMessage({ id: "session-detail:title" });
    return `${state?.simulationName || simulationName || ''} \u2022 ${sessionTitle}`;
  }, [state?.simulationName, simulationName, name, intl]);

  const SessionDetailScore = () => (
    <Section.Container>
      <Section.Header justifyContent="space-between">
        <Styled display="flex" alignItems="center">
          <Styled marginRight={"1rem"} display="flex" alignItems="center">
            <Trending width={22} height={22} />
          </Styled>
          <FormattedMessage id="session-detail:score" />
        </Styled>
      </Section.Header>
      <Section.Body hasBackground={false}>
        <Score />
      </Section.Body>
    </Section.Container>
  );

  interface SessionDetailActionsPerformedProps {
    offset: number;
  }

  const SessionDetailActionsPerformed: React.FC<SessionDetailActionsPerformedProps> = ({ offset }) => (
    <ErrorBoundary
      fallback={
        <Styled color="gray[100]" textAlign="center">
          <FormattedMessage id="session-detail:actions-performed:error" />
        </Styled>
      }
    >
      <Suspense fallback={null}>
        <ActionsPerformed offset={offset} />
      </Suspense>
    </ErrorBoundary>
  );

  interface ScenarioOverviewButtonProps {
    handleOnClickOverview: () => void;
  }

  const ScenarioOverviewButton: React.FC<ScenarioOverviewButtonProps> = ({ handleOnClickOverview }) => {
    return (
      <OverviewButtonContainer onClick={handleOnClickOverview}>
        <Styled marginRight={"0.65rem"}>
          <FormattedMessage id="session-detail:scenario-overview" />
        </Styled>
        <ArrowRight />
      </OverviewButtonContainer>
    );
  };

  const LandingScoreLayout2: React.FC = () => {
    return <DummyContainer style={{ height: '655px' }}>Scores</DummyContainer>;
  };

  const TopStats2: React.FC = () => {
    return <DummyContainer style={{ height: '197px' }}>Top Stats</DummyContainer>;
  };

  const ResponseTime: React.FC = () => {
    return <DummyContainer style={{ height: '713px' }}>Response Time</DummyContainer>;
  };

  const FireMissions: React.FC = () => {
    return <DummyContainer style={{ height: '443px' }}>Fire Missions</DummyContainer>;
  };

  const getComponentById = (component: string) => {
    switch (component) {
      case 'LandingScore':
        return <LandingScore />;
      case 'CustomTopStats':
        return <CustomTopStats />;
      case 'TimelineChartFiring':
        return <TimelineChartFiring />;
      case 'TimelineCockPitTab':
        return <TimelineCockPitTab />;
      case 'MapTimeline':
        return <MapTimeline />;
      case 'SessionDetailScore':
        return <SessionDetailScore />;
      case 'SessionDetailActionsPerformed':
        return <SessionDetailActionsPerformed offset={offset} />;
      case 'ScenarioOverviewButton':
        return <ScenarioOverviewButton handleOnClickOverview={handleOnClickOverview} />;
      case 'LandingScoreLayout2':
        return <LandingScoreLayout2 />;
      case 'TopStats2':
        return <TopStats2 />;
      case 'ResponseTime':
        return <ResponseTime />;
      case 'FireMissions':
        return <FireMissions />;
      default:
        return null;
    }
  };
  

  return (
    <Page
      pageTitle={false}
      title={title}
      showBackButton={true}
      showDetails={true}
      subtitleDetailText={getSubtitleSessionLabel}
      logoUrl={state?.logoUrl || logoUrl}
    >
      <Main>
        <LayoutGrid getComponentById={getComponentById} />
      </Main>
    </Page>
  );
};

const SessionDetailPage = () => (
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="session-detail"
    fallback={<PageLoader />}
  >
    <SessionProvider>
      <SessionDetail />
    </SessionProvider>
  </VRIntlProviderComponent>
);

export default SessionDetailPage;
