import styled from "styled-components";
import base from "../../../../themes/base/base";
import Button from "../../../../components/ui/button";
import { UserMultiSelectDropdown } from "../users-filter";

export const OptionsBarWrapperDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 0.625rem;
`;

export const StyledButton = styled(Button)`
    padding: 0.4375rem 1rem;
    border-radius: 3.125rem;
    gap: 0.25rem;

    &:hover {
        background-color: ${base.colors.primary.orange[300]};
        border-color: ${base.colors.primary.orange[300]};
    }

    &:active {
        background-color: ${base.colors.primary.orange[500]};
        border-color: ${base.colors.primary.orange[500]};
    }
`;

export const ButtonText = styled.span`
    ${base.typography.largeMedium};
`;

export const StyledMultiSelectDropdown = styled(UserMultiSelectDropdown)`
    button{
        border-color: transparent;

        &:hover{
            border-color: transparent;  
        }

        &:focus{
            border-color: ${base.colors.primary.orange[500]};
        }
    }
`;

