import { IconProps } from "../../types/icon";

const Expand = ({ width = 24, height = 24, fill = "#f2f2f2" }:IconProps) =>
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5 19V13H7V17H11V19H5ZM17 11V7H13V5H19V11H17Z" fill={fill}/>
    </svg>

export default Expand;