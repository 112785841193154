import client from "../../client";

export const addTrainee = async (email) => {
    const { data } = await client.post('v1/trainee', { emailAddress: email });
    return data;
};

export const addTraineeBulk = async (addresses) => {
    const { data } = await client.post('v1/trainee/bulk', { emailAddresses: addresses });
    return data;
};

export const getTrainees = async (token, search, status) => {
    const { data } = await client.get(`v1/trainee?pageSize=10${token ? `&skipToken=${token}` : ''}${search ? `&searchString=${encodeURIComponent(search)}` : ''}${status ? `&status=${status}` : ''}`);

    //TODO: Remove this (mocked data)
    // data.items = data.items.map((item) => {

    //     const start = new Date(2021, 0, 1);
    //     const end = new Date();
    //     const lastInvitationSent = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

    //     return {
    //         ...item,
    //         lastInvitationSent,
    //         status: Math.random() > 0.5 ? `Link opened` : `Email Sent`
    //     }
    // });
    return data;
}