import styled from "styled-components";
import base from "../../../../themes/base/base";
import UIButton from "../../../../components/ui/button";

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const Toggle = styled(UIButton)`
  ${base.typography.extraSmallRegular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  max-width: 460px;
  border: 0;
  border-radius: 2px;
  padding: 4px 6px;
  border: 1px solid ${base.colors.neutral.grey[300]};

  &:hover {
    background-color: ${base.colors.neutral.grey[600]};
    border: 1px solid ${base.colors.neutral.grey[300]};
  }   

  &:focus {
    box-shadow: none;
    border: 1px solid ${base.colors.primary.orange[500]};
  }
`;

export const PopUp = styled.div<{ width: string | number }>`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  width: ${({ width }) => width};
  border: 0;
  border-radius: 2px;
  background-color: ${base.colors.neutral.grey[800]};
  z-index: 1;
`;

export const ContextFilterPopUp = styled(PopUp)`
  position: absolute;
  right: 0;
  border: 1px solid #999;
`;

export const PresetsList = styled.ul`
  min-width: 9.3rem;
  margin: 0;
  padding: 0 0.5rem;

  li:first-child{
    margin-top: 8px;
  }

  li:last-child{
    margin-bottom: 8px;
  }

`;

export const Preset = styled.li`
  list-style: none;
  padding: 0 0.25rem;
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

export const PresetsContainer = styled.div`
  width: 9rem;
  box-shadow: -1px 0px 0px 0px #999 inset;
`;

export const Link = styled.a<{ selected: boolean }>`
  padding: 0rem 0.25rem;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  border-radius: 0.2rem;
  color: ${({ selected }) => (selected ? '#EE7127' : 'inherit')};
  
  &:hover {
    color: #EE7127;
  }
`;

export const FilterContainer = styled.div`
    position: relative;
`;

export const LoaderContainer = styled.div`
  padding: 0.65625rem 0;
`;