import styled from "styled-components";

export const Container = styled.div``;

export const SessionContainer = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: minmax(auto, 200px) 3fr;
  margin-bottom: 1rem;
`;

export const SessionContent = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 1rem;
  padding-top: 1rem;
  align-items: start;
`;

type SessionVerticalLineType = {
  height: number;
};

export const SessionVerticalLine = styled.div<SessionVerticalLineType>`
  width: 1px;
  height: ${({ height }) => height}px;
  position: absolute;
  top: 25px;
  background: ${({ theme }) => theme.colors.legacy.gray[200]};
`;

export const ScenariosContainer = styled.div`
  min-width: 500px;
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.legacy.dark[800]};
  border-radius: 0.5rem;
`;

export const ScenarioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.legacy.dark[800]};
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const ScenarioContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: 1fr;
  gap: 1rem;
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.legacy.gray[200]};
  border-radius: 50%;
  position: relative;
`;

export const ScenarioVerticalLine = styled.div`
  width: 1px;
  height: 70px;
  position: absolute;
  top: 25px;
  background: ${({ theme }) => theme.colors.legacy.gray[200]};
`;

export const TimeStack = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledImg = styled.img``;

export const Time = styled.time``;
