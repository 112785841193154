import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getCustomerSettings } from "../../../api/settings";
import { GamepadIcon } from "../../../components/icons";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import Button from "../../../components/ui/button";
import Loader from "../../../components/ui/loader";
import Styled from "../../../components/ui/styled";
import Text from "../../../components/ui/text";
import { Session } from "../../../types/session";
import { Container, Duration, Item, SessionListContainer } from "./session-list.styles";
import SessionLogo from "../../../components/icons/session-logo";
import ChevronLeft from "../../../components/icons/chevron-left";
import { convertMillisecondsToTimespan } from "../../../utils/functions";

type SessionsListProps = {
  data: Session[];
  sessionId: string;
  onItemClick: (params: { session: Session; sessionId: string }) => void;
};

type SessionsListItemProps = {
  image?: string;
  onClick?: () => void;
};

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const getDurationFromPeriod = (from: string, to: string) => {
  if (!from || !to) {
    return "0m 0s";
  }

  const momentFrom = moment(from);
  const momentTo = moment(to);
  const diff = momentTo.diff(momentFrom, "milliseconds");

  return convertMillisecondsToTimespan(diff);
};

const SessionsListItem = ({ from, to, name, image = "", score, onClick }: Session & SessionsListItemProps) => (
  <Item.Container onClick={onClick}>
    <Item.ImageContainer>{<LazyImage rounded url={image} />}</Item.ImageContainer>
    <Item.Content>
      <Item.Name>
        <Text variant="medium">{name}</Text>
      </Item.Name>
      <Item.Info>
        <Duration>{getDurationFromPeriod(from, to)}</Duration>
      </Item.Info>
    </Item.Content>
    <Item.PeriodLabel>
      {moment(from).format("DD/MM/YYYY")} {moment(from).format("HH:mm")} - {moment(to).format("HH:mm")}
    </Item.PeriodLabel>
  </Item.Container>
);

const LazyImage = ({ url, rounded = false }: { url: string; rounded: boolean }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>(url);

  const img = new Image();

  const handleOnLoad = () => setLoaded(true);
  useEffect(() => {
    setImgUrl(url);
    img.addEventListener("load", handleOnLoad);
    img.src = url;
    return () => {
      img.removeEventListener("load", handleOnLoad);
    };
  }, [url]);
  // TODO: improve this part to think in a fallback image if loaded is false and imgUrl is empty or undefined
  return loaded && imgUrl === "" ? (
    <SessionLogo width={56} height={56} />
  ) : loaded ? (
    <Item.Image rounded={rounded} style={{ backgroundImage: `url(${imgUrl})` }} />
  ) : (
    <Loader size="small" />
  );
};

const List = ({ data, onItemClick, sessionId }: SessionsListProps) => {
  const options = {
    suspense: false,
    refetchOnMount: false,
    useErrorBoundary: true,
  };
  const { isLoading, data: d } = useQuery([getCustomerSettings], getCustomerSettings, options);

  let counter = 0;

  return (
    <SessionListContainer>
      {data.map((session: Session) => (
        <Container key={counter++}>
          <SessionsListItem
            {...session}
            key={session.id || session.scenarioId}
            name={session.name || session.scenario}
            image={d?.scenarioImageUrl || session.image}
            score={session.score}
            onClick={() => {
              onItemClick({
                session,
                sessionId,
              });
            }}
          />
        </Container>
      ))}
    </SessionListContainer>
  );
};

const SessionsList = (props: SessionsListProps) => (
  <VRIntlProviderComponent localeFn={localeFn} id="sessions-list" fallback={null}>
    <List {...props} />
  </VRIntlProviderComponent>
);

export default SessionsList;
