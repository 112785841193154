import React, { ChangeEvent } from "react";
import { IconWrapper, SearchBarWrapperDiv } from "./search-bar.styles";
import { SearchIcon24 } from "../../icons";
import { DebounceInput } from "react-debounce-input";

interface SearchBarProps {
    disabled?: boolean;
    debounceTimeout: number;
    placeholder: string;
    value: string | null;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = ({
    disabled = false,
    debounceTimeout,
    placeholder,
    value,
    onChange
}: SearchBarProps) => {
    return (
        <SearchBarWrapperDiv>
            <IconWrapper>
                <SearchIcon24 width={24} height={24} />
            </IconWrapper>
            <DebounceInput
                disabled={disabled}
                debounceTimeout={debounceTimeout}
                value={value || ""}
                onChange={onChange}
                placeholder={placeholder}
            />
        </SearchBarWrapperDiv>
    );
};

export default SearchBar;
