import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { FormattedMessage } from "react-intl";
import {
  CustomTooltip,
  StyledImg,
  Title,
  TooltipContent,
  LoaderContainer,
  Container
} from "../../session-detail.styles";
import TooltipSVG from "../../../../components/icons/tooltip.svg";
import { Wrapper } from './styles';
import styled from 'styled-components';

import Controls from '../cockpit-timeline/controls';
import { ExpandDiagonalIcon } from "../../../../components/icons";

const accessToken = 'pk.eyJ1IjoianBhYyIsImEiOiJjaXF6ZjF1N2QwMDY5aHVubmc1emZlY20yIn0.kRvynfQ0yBdL6pXJl01jPA';
const mapboxURL = `https://api.mapbox.com/styles/v1/jpac/clvcw0bnt00kz01o01tbdg4g1/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapEffect = () => {
  const map = useMap();

  useEffect(() => {
    const invalidateMapSize = () => map.invalidateSize();
    const resizeObserver = new ResizeObserver(invalidateMapSize);
    const mapContainer = map.getContainer();
    resizeObserver.observe(mapContainer);

    return () => {
      resizeObserver.unobserve(mapContainer);
    };
  }, [map]);

  return null;
};

const SpeedControlContainer = styled.div`
  display: flex;
  width: 100%;
  height: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
`;

const SpeedControlInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 1.25rem;
`;

const SpeedBlock = styled.div`
  flex-grow: 1;
  background-color: ${props => props.color};
  width: 1.25rem;
  height: 1.25rem;
`;

const SpeedBlockLabel = styled.div`
  flex-grow: 1;
  color: #999;
  margin: 0 0.5rem;
  font-size: 14px;
  align-items: center;
  display: flex;
`;

const SpeedControl = () => {
  return (
    <SpeedControlContainer>
      <SpeedControlInnerContainer>
        <SpeedBlockLabel>Slower</SpeedBlockLabel>
        <SpeedBlock color="#0B5370" />
        <SpeedBlock color="#5C8C9F" />
        <SpeedBlock color="#CCCCCC" />
        <SpeedBlock color="#F4A06E" />
        <SpeedBlock color="#EE7127" />
        <SpeedBlockLabel>Faster</SpeedBlockLabel>
      </SpeedControlInnerContainer>
    </SpeedControlContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FullScreenDiv = styled.div<{ isFullScreen: boolean }>`
  height: ${props => props.isFullScreen ? 'calc(100% - 170px)' : '28.5rem'};
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
`;

const ExpandIconContainer = styled.div`
  cursor: pointer;
`;

const MapTimeline = (): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = async () => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setTimeout(() => wrapperRef.current?.scrollIntoView(), 40);
        setIsFullScreen(false);
      }
    };

    try {
      if (!document.fullscreenElement) {
        await wrapperRef.current?.requestFullscreen();
        setIsFullScreen(true);
      } else {
        await document.exitFullscreen();
        handleFullScreenChange();
      }
    } catch (err) {
      const error = err as Error;
      alert(`Error attempting to change full-screen mode: ${error.message} (${error.name})`);
    }
  };

  const pathSegments: { coords: [number, number][]; color: string; }[] = [
    { coords: [[55.945, -3.162], [55.946, -3.161]], color: 'orange' },
    { coords: [[55.946, -3.161], [55.947, -3.160]], color: 'blue' },
    { coords: [[55.947, -3.160], [55.948, -3.159]], color: 'green' },
    // Add more segments as needed
  ];

  return (
    <Wrapper ref={wrapperRef}
      bottomGap={true}
      borderTopRadius={true}
      borderBottomRadius={true}
    >
      <HeaderContainer>
        <Title>
          <FormattedMessage id={"session-detail:timeline"} />
        </Title>
        <ExpandIconContainer onClick={toggleFullScreen}>
          <ExpandDiagonalIcon />
        </ExpandIconContainer>
      </HeaderContainer>
      <FullScreenDiv isFullScreen={isFullScreen}>
        <MapContainer center={[55.946, -3.160]} zoom={14} style={{ height: "100%", width: "100%" }}>
          <TileLayer
              url={mapboxURL}
              accessToken={accessToken}
          />
          {pathSegments.map((segment, index) => (
            <>
              <Polyline key={`outline-${index}`} positions={segment.coords} color="black" weight={12} />
              <Polyline key={index} positions={segment.coords} color={segment.color} weight={10} />
            </>
          ))}
          <Marker position={[55.945, -3.162]}>
              <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
          </Marker>
          <MapEffect />
        </MapContainer>
      </FullScreenDiv>
      <SpeedControl />
      <Controls
        isDisabled={false}
        isPlaying={false}
        play={() => {}}
        pause={() => {}}
        currentTick={0}
        setTick={() => {}}
        totalTickCount={1000}
        currentTime={'00:00'}
        duration={'10:00'}
      />
    </Wrapper>
  );
};

export default MapTimeline;