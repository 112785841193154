export const AltitudeBackgroundSvg = `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 28.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_2_00000166646297438232061200000004960899090485893020_"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400.7 400.7"
	 style="enable-background:new 0 0 400.7 400.7;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#161215;}
	.st1{fill:#F2F2F2;}
	.st2{fill:none;}
</style>
<g id="Layer_2-2">
	<g>
		<path class="st0" d="M200.4,47.3c-84.5,0-153,68.5-153,153s68.5,153,153,153s153-68.5,153-153S284.9,47.3,200.4,47.3z M276,221.8
			c1.9-6.8,3-14,3-21.4s-1-14.5-3-21.3l49.1-13.9c3.2,11.2,4.8,23,4.8,35.2s-1.7,24.1-4.9,35.4L276,221.8z"/>
		<rect x="198.4" y="50.2" class="st1" width="4" height="23.4"/>
		<rect x="270" y="86.4" transform="matrix(0.5898 -0.8076 0.8076 0.5898 44.2148 263.7552)" class="st1" width="23.4" height="4"/>
		
			<rect x="320.3" y="155.6" transform="matrix(0.9503 -0.3112 0.3112 0.9503 -32.5427 111.1385)" class="st1" width="23.5" height="4"/>
		
			<rect x="330" y="231.3" transform="matrix(0.3112 -0.9503 0.9503 0.3112 -2.3071 482.9717)" class="st1" width="4" height="23.5"/>
		
			<rect x="279.8" y="300.6" transform="matrix(0.8077 -0.5896 0.5896 0.8077 -129.9773 226.1909)" class="st1" width="4" height="23.4"/>
		<rect x="198.4" y="327.1" class="st1" width="4" height="23.4"/>
		
			<rect x="107.3" y="310.3" transform="matrix(0.5898 -0.8076 0.8076 0.5898 -203.3535 224.2301)" class="st1" width="23.4" height="4"/>
		<rect x="57" y="241.1" transform="matrix(0.9503 -0.3112 0.3112 0.9503 -72.2227 33.4753)" class="st1" width="23.5" height="4"/>
		
			<rect x="66.8" y="145.8" transform="matrix(0.3112 -0.9503 0.9503 0.3112 -102.3424 173.9267)" class="st1" width="4" height="23.5"/>
		<rect x="117" y="76.7" transform="matrix(0.8076 -0.5898 0.5898 0.8076 -29.207 87.2024)" class="st1" width="4" height="23.4"/>
		<rect x="210.2" y="58.4" transform="matrix(0.126 -0.992 0.992 0.126 131.7215 268.3369)" class="st1" width="15.9" height="2"/>
		
			<rect x="227.7" y="61.7" transform="matrix(0.2499 -0.9683 0.9683 0.2499 116.1144 275.2501)" class="st1" width="16" height="2"/>
		<rect x="244.6" y="67.2" transform="matrix(0.3681 -0.9298 0.9298 0.3681 96.2199 277.9979)" class="st1" width="16" height="2"/>
		<rect x="260.8" y="74.9" transform="matrix(0.4816 -0.8764 0.8764 0.4816 72.8397 274.8904)" class="st1" width="16" height="2"/>
		<rect x="289.7" y="95.8" transform="matrix(0.6884 -0.7254 0.7254 0.6884 22.5763 246.0643)" class="st1" width="16" height="2"/>
		<rect x="301.9" y="108.7" transform="matrix(0.7695 -0.6386 0.6386 0.7695 1.356 223.219)" class="st1" width="16" height="2"/>
		
			<rect x="312.5" y="123.2" transform="matrix(0.8462 -0.5329 0.5329 0.8462 -16.8983 189.8794)" class="st1" width="16" height="2"/>
		
			<rect x="321" y="138.9" transform="matrix(0.9044 -0.4267 0.4267 0.9044 -28.2439 153.7146)" class="st1" width="15.9" height="2"/>
		
			<rect x="334.2" y="190.4" transform="matrix(0.998 -6.244516e-02 6.244516e-02 0.998 -11.2868 21.7414)" class="st1" width="15.9" height="2"/>
		
			<rect x="332.1" y="172.7" transform="matrix(0.9822 -0.1876 0.1876 0.9822 -26.5533 66.8787)" class="st1" width="16" height="2"/>
		
			<rect x="341.1" y="201.4" transform="matrix(6.279217e-02 -0.998 0.998 6.279217e-02 111.7536 537.6273)" class="st1" width="2" height="15.9"/>
		<rect x="339" y="218.9" transform="matrix(0.1877 -0.9822 0.9822 0.1877 53.2919 518.288)" class="st1" width="2" height="16"/>
		<rect x="328" y="252.9" transform="matrix(0.427 -0.9042 0.9042 0.427 -47.4103 446.9516)" class="st1" width="2" height="15.9"/>
		
			<rect x="319.4" y="268.5" transform="matrix(0.5329 -0.8462 0.8462 0.5329 -84.2785 400.2754)" class="st1" width="2" height="15.9"/>
		
			<rect x="308.9" y="283" transform="matrix(0.6384 -0.7697 0.7697 0.6384 -111.9468 343.7285)" class="st1" width="2" height="16"/>
		
			<rect x="296.6" y="295.9" transform="matrix(0.7256 -0.6881 0.6881 0.7256 -127.4788 288.2118)" class="st1" width="2" height="16"/>
		
			<rect x="267.7" y="316.9" transform="matrix(0.8762 -0.4819 0.4819 0.8762 -123.2925 169.7246)" class="st1" width="2" height="16"/>
		
			<rect x="251.7" y="324.4" transform="matrix(0.9298 -0.3681 0.3681 0.9298 -104.6283 116.3683)" class="st1" width="2" height="16"/>
		<rect x="234.8" y="330" transform="matrix(0.9683 -0.2499 0.2499 0.9683 -76.9961 69.643)" class="st1" width="2" height="16"/>
		
			<rect x="217.2" y="333.5" transform="matrix(0.9921 -0.1255 0.1255 0.9921 -41.1207 30.0772)" class="st1" width="2" height="15.9"/>
		
			<rect x="174.6" y="340.4" transform="matrix(0.126 -0.992 0.992 0.126 -179.1686 479.4619)" class="st1" width="15.9" height="2"/>
		<rect x="157" y="337" transform="matrix(0.2499 -0.9683 0.9683 0.2499 -203.486 413.3107)" class="st1" width="16" height="2"/>
		
			<rect x="140.1" y="331.4" transform="matrix(0.3702 -0.9289 0.9289 0.3702 -215.5696 346.927)" class="st1" width="15.9" height="2"/>
		
			<rect x="123.9" y="323.8" transform="matrix(0.4816 -0.8764 0.8764 0.4816 -216.2564 284.0142)" class="st1" width="16" height="2"/>
		
			<rect x="95.1" y="303" transform="matrix(0.6848 -0.7287 0.7287 0.6848 -189.0027 170.9242)" class="st1" width="15.9" height="2"/>
		
			<rect x="82.9" y="289.9" transform="matrix(0.7695 -0.6386 0.6386 0.7695 -164.8255 125.1171)" class="st1" width="16" height="2"/>
		
			<rect x="72.5" y="275.5" transform="matrix(0.8462 -0.5329 0.5329 0.8462 -134.9733 85.4136)" class="st1" width="16" height="2"/>
		<rect x="63.7" y="259.9" transform="matrix(0.9053 -0.4248 0.4248 0.9053 -104.0251 55.187)" class="st1" width="16" height="2"/>
		<rect x="52.9" y="226" transform="matrix(0.9822 -0.1876 0.1876 0.9822 -41.4934 15.4454)" class="st1" width="16" height="2"/>
		
			<rect x="50.5" y="208.2" transform="matrix(0.998 -6.244516e-02 6.244516e-02 0.998 -12.9518 4.0645)" class="st1" width="16" height="2"/>
		
			<rect x="57.5" y="183.5" transform="matrix(6.244519e-02 -0.998 0.998 6.244519e-02 -136.2066 237.9103)" class="st1" width="2" height="16"/>
		
			<rect x="59.8" y="165.8" transform="matrix(0.1877 -0.9822 0.9822 0.1877 -121.341 200.8751)" class="st1" width="2" height="16"/>
		
			<rect x="70.8" y="131.8" transform="matrix(0.4245 -0.9054 0.9054 0.4245 -85.2406 145.4878)" class="st1" width="2" height="16"/>
		
			<rect x="79.4" y="116.3" transform="matrix(0.5329 -0.8462 0.8462 0.5329 -67.5899 126.0441)" class="st1" width="2" height="15.9"/>
		
			<rect x="89.9" y="101.7" transform="matrix(0.6384 -0.7697 0.7697 0.6384 -51.5697 109.6631)" class="st1" width="2" height="16"/>
		<rect x="102" y="88.8" transform="matrix(0.7287 -0.6848 0.6848 0.7287 -38.3027 96.7984)" class="st1" width="2" height="15.9"/>
		<rect x="130.9" y="67.9" transform="matrix(0.8762 -0.4819 0.4819 0.8762 -20.2442 72.932)" class="st1" width="2" height="16"/>
		<rect x="147.1" y="60.1" transform="matrix(0.9298 -0.3681 0.3681 0.9298 -14.6817 59.3086)" class="st1" width="2" height="16"/>
		<rect x="164.1" y="54.7" transform="matrix(0.9683 -0.2499 0.2499 0.9683 -10.443 43.239)" class="st1" width="2" height="16"/>
		<rect x="181.5" y="51.4" transform="matrix(0.9921 -0.1255 0.1255 0.9921 -5.999 23.3779)" class="st1" width="2" height="15.9"/>
		<g>
			<path class="st1" d="M194.7,90.3c0-2.1,0.2-3.8,0.6-5.1c0.4-1.3,1.1-2.3,1.9-3c0.8-0.7,1.9-1,3.2-1c1,0,1.8,0.2,2.5,0.6
				c0.7,0.4,1.3,0.9,1.8,1.7c0.5,0.7,0.8,1.6,1.1,2.7c0.3,1,0.4,2.5,0.4,4.2c0,2.1-0.2,3.8-0.6,5.1c-0.4,1.3-1.1,2.3-1.9,3
				c-0.9,0.7-1.9,1.1-3.2,1.1c-1.7,0-3.1-0.6-4.1-1.9C195.2,96.2,194.7,93.8,194.7,90.3L194.7,90.3z M196.9,90.3
				c0,2.9,0.3,4.9,1,5.9s1.5,1.5,2.5,1.5s1.9-0.5,2.6-1.5c0.7-1,1-2.9,1-5.9s-0.3-4.9-1-5.9s-1.5-1.5-2.6-1.5s-1.8,0.4-2.4,1.3
				C197.2,85.3,196.9,87.4,196.9,90.3L196.9,90.3z"/>
		</g>
		<g>
			<path class="st1" d="M200.5,99.9c-1.9,0-3.4-0.7-4.5-2c-1.2-1.6-1.9-4.1-1.9-7.6c0-2.2,0.2-3.9,0.7-5.3c0.5-1.4,1.2-2.5,2.1-3.2
				c0.9-0.8,2.1-1.2,3.6-1.2c1,0,2,0.2,2.8,0.6s1.5,1,2,1.8c0.5,0.8,0.9,1.7,1.2,2.8s0.4,2.5,0.4,4.3c0,2.1-0.2,3.9-0.7,5.2
				c-0.5,1.4-1.2,2.5-2.1,3.2C203.1,99.6,201.9,99.9,200.5,99.9L200.5,99.9z M200.5,81.7c-1.2,0-2.1,0.3-2.9,0.9
				c-0.8,0.6-1.4,1.6-1.8,2.8c-0.4,1.2-0.6,2.9-0.6,5c0,3.2,0.6,5.6,1.7,7c0.9,1.1,2.1,1.7,3.7,1.7c1.2,0,2.2-0.3,2.9-0.9
				c0.8-0.6,1.4-1.6,1.8-2.8c0.4-1.2,0.6-2.9,0.6-4.9c0-1.7-0.1-3.1-0.4-4.1c-0.2-1-0.6-1.8-1.1-2.5c-0.4-0.7-1-1.2-1.6-1.5
				C202.2,81.8,201.4,81.8,200.5,81.7L200.5,81.7z M200.5,98.2c-1.2,0-2.2-0.6-2.9-1.7c-0.8-1.1-1.1-3.1-1.1-6.2
				c0-3.1,0.4-5.1,1.2-6.3c0.7-1,1.6-1.5,2.8-1.5s2.2,0.6,3,1.7c0.8,1.1,1.1,3.1,1.1,6.2c0,3.1-0.4,5.1-1.1,6.2
				C202.7,97.6,201.7,98.2,200.5,98.2L200.5,98.2z M200.5,83.6c-0.8,0-1.5,0.3-2,1.1c-0.7,1-1,2.9-1,5.8c0,2.9,0.3,4.7,0.9,5.6
				c0.6,0.8,1.3,1.2,2.1,1.2s1.5-0.4,2.1-1.2c0.6-0.9,0.9-2.8,0.9-5.6c0-3.5-0.5-5-0.9-5.6C202.1,83.9,201.4,83.6,200.5,83.6
				L200.5,83.6z"/>
		</g>
		<g>
			<path class="st1" d="M176.7,162.9l6.9-17.9h2.6l7.3,17.9h-2.7l-2.1-5.4h-7.5l-2,5.4H176.7L176.7,162.9z M181.9,155.6h6.1l-1.9-5
				c-0.6-1.5-1-2.7-1.3-3.7c-0.2,1.2-0.6,2.3-1,3.4L181.9,155.6L181.9,155.6z"/>
			<path class="st1" d="M195.2,162.9v-17.9h2.4v15.8h8.8v2.1H195.2L195.2,162.9z"/>
			<path class="st1" d="M212,162.9v-15.8h-5.9v-2.1h14.2v2.1h-5.9V163L212,162.9L212,162.9z"/>
		</g>
		<g>
			<path class="st1" d="M193.8,163.2h-3.2l-2.1-5.4h-7.1l-2,5.4h-3.1l7.1-18.4h2.9L193.8,163.2z M190.9,162.7h2.1l-7.1-17.4h-2.2
				l-6.7,17.4h2l2-5.4h7.8L190.9,162.7L190.9,162.7z M188.2,155.9h-6.8l2.1-5.6c0.4-1.1,0.7-2.2,0.9-3.4l0.2-1l0.3,1
				c0.3,0.9,0.7,2.2,1.3,3.7L188.2,155.9L188.2,155.9z M182.2,155.4h5.3l-1.7-4.6c-0.4-1.1-0.7-2-1-2.8c-0.2,0.8-0.5,1.7-0.8,2.5
				L182.2,155.4L182.2,155.4z"/>
			<path class="st1" d="M206.7,163.2H195v-18.4h2.9v15.8h8.8V163.2L206.7,163.2z M195.5,162.7h10.7v-1.6h-8.8v-15.8h-1.9V162.7
				L195.5,162.7z"/>
			<path class="st1" d="M214.6,163.2h-2.9v-15.8h-5.9v-2.6h14.7v2.6h-5.9L214.6,163.2L214.6,163.2z M212.2,162.7h1.9v-15.7h5.9v-1.6
				h-13.7v1.6h5.9L212.2,162.7L212.2,162.7z"/>
		</g>
		<g>
			<path class="st1" d="M266.5,121.3h-2.2v-14c-0.5,0.5-1.2,1-2.1,1.5c-0.9,0.5-1.6,0.9-2.3,1.1v-2.1c1.2-0.6,2.3-1.3,3.2-2.1
				c0.9-0.8,1.6-1.6,1.9-2.4h1.4v18H266.5z"/>
		</g>
		<g>
			<path class="st1" d="M267,121.8h-3.2v-13.4c-0.4,0.3-0.8,0.6-1.3,0.9c-0.9,0.5-1.7,0.9-2.4,1.2l-0.7,0.2v-3.2l0.3-0.1
				c1.2-0.6,2.2-1.2,3.1-2c0.9-0.8,1.5-1.5,1.8-2.2l0.1-0.3h2.2L267,121.8L267,121.8z M264.9,120.8h1.2v-17h-0.6
				c-0.4,0.7-1.1,1.5-1.9,2.3c-0.9,0.8-1.9,1.5-3.1,2v1.1c0.5-0.2,1-0.5,1.6-0.8c0.8-0.5,1.5-1,2-1.4l0.8-0.8V120.8L264.9,120.8z"/>
		</g>
		<g>
			<path class="st1" d="M312.1,159.2v2.1h-11.8c0-0.5,0.1-1,0.3-1.5c0.3-0.8,0.8-1.6,1.4-2.4c0.6-0.8,1.6-1.7,2.9-2.7
				c2-1.6,3.3-2.9,3.9-3.8c0.6-0.9,1-1.8,1-2.6c0-0.9-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.4-0.9c-1.1,0-1.9,0.3-2.5,0.9
				c-0.6,0.6-1,1.5-1,2.6l-2.3-0.2c0.1-1.7,0.7-3,1.8-3.8c1-0.9,2.4-1.3,4.1-1.3s3.1,0.5,4.1,1.4c1,0.9,1.5,2.1,1.5,3.5
				c0,0.7-0.1,1.4-0.4,2.1s-0.8,1.4-1.5,2.2s-1.8,1.8-3.4,3.2c-1.3,1.1-2.2,1.9-2.5,2.2c-0.4,0.4-0.7,0.8-0.9,1.2L312.1,159.2
				L312.1,159.2z"/>
		</g>
		<g>
			<path class="st1" d="M312.6,161.8h-12.8v-0.5c0-0.6,0.1-1.2,0.3-1.7c0.3-0.8,0.8-1.7,1.5-2.5c0.7-0.8,1.7-1.7,2.9-2.8
				c1.9-1.6,3.2-2.8,3.9-3.7c0.6-0.8,0.9-1.6,0.9-2.3c0-0.7-0.2-1.3-0.8-1.8c-0.5-0.5-1.2-0.8-2.1-0.8s-1.6,0.3-2.2,0.8
				s-0.8,1.3-0.8,2.3v0.6l-3.3-0.3v-0.5c0.2-1.8,0.8-3.2,1.9-4.2c1.1-1,2.6-1.4,4.4-1.4s3.3,0.5,4.4,1.6c1.1,1.1,1.7,2.4,1.7,3.9
				c0,0.8-0.2,1.6-0.5,2.3s-0.8,1.5-1.5,2.3s-1.9,1.8-3.4,3.2c-1.3,1.1-2.1,1.8-2.5,2.2c-0.1,0.1-0.2,0.2-0.3,0.3h8.3V161.8
				L312.6,161.8z M300.9,160.8h10.8v-1.1h-9.2l0.5-0.8c0.3-0.4,0.6-0.9,1-1.3c0.4-0.4,1.2-1.2,2.6-2.3c1.5-1.3,2.7-2.4,3.3-3.1
				c0.6-0.7,1.1-1.4,1.4-2.1c0.3-0.6,0.4-1.3,0.4-1.9c0-1.3-0.4-2.3-1.4-3.2c-0.9-0.9-2.2-1.3-3.7-1.3s-2.8,0.4-3.7,1.2
				c-0.8,0.7-1.3,1.7-1.5,3l1.2,0.1c0.1-1,0.5-1.8,1.1-2.4c0.7-0.7,1.7-1.1,2.9-1.1s2.1,0.3,2.8,1s1.1,1.6,1.1,2.6
				c0,0.9-0.4,1.9-1.1,2.9s-2,2.2-4,3.9c-1.2,1-2.2,1.9-2.8,2.7c-0.6,0.7-1.1,1.5-1.4,2.2C300.9,160.2,300.9,160.4,300.9,160.8
				L300.9,160.8z"/>
		</g>
		<g>
			<path class="st1" d="M300.5,252.4l2.2-0.3c0.2,1.2,0.7,2.1,1.3,2.7c0.6,0.6,1.3,0.8,2.2,0.8c1,0,1.9-0.4,2.6-1.1
				c0.7-0.7,1.1-1.6,1.1-2.7s-0.3-1.8-1-2.5c-0.7-0.6-1.5-1-2.5-1c-0.4,0-0.9,0.1-1.5,0.2l0.2-1.9c0.1,0,0.3,0,0.4,0
				c0.9,0,1.8-0.2,2.5-0.7c0.8-0.5,1.1-1.2,1.1-2.3c0-0.8-0.3-1.5-0.8-2s-1.2-0.8-2.1-0.8s-1.6,0.3-2.1,0.8s-0.9,1.3-1.1,2.4
				l-2.2-0.4c0.3-1.5,0.9-2.6,1.8-3.4c0.9-0.8,2.1-1.2,3.5-1.2c1,0,1.9,0.2,2.7,0.6s1.5,1,1.9,1.7s0.6,1.5,0.6,2.3s-0.2,1.5-0.6,2.1
				s-1,1.1-1.8,1.5c1.1,0.2,1.9,0.8,2.5,1.5s0.9,1.7,0.9,2.9c0,1.6-0.6,2.9-1.7,4s-2.6,1.6-4.3,1.6c-1.6,0-2.9-0.5-3.9-1.4
				C301.4,255.2,300.8,253.9,300.5,252.4L300.5,252.4z"/>
		</g>
		<g>
			<path class="st1" d="M306.2,258c-1.7,0-3.1-0.5-4.2-1.5s-1.8-2.4-1.9-4L300,252l3.1-0.4l0.1,0.5c0.2,1.1,0.6,1.9,1.1,2.4
				s1.1,0.7,1.9,0.7c0.9,0,1.6-0.3,2.3-0.9c0.6-0.6,0.9-1.4,0.9-2.3c0-0.9-0.3-1.6-0.8-2.1c-0.6-0.6-1.3-0.8-2.2-0.8
				c-0.4,0-0.9,0.1-1.4,0.2l-0.7,0.1l0.4-3.1l0.5,0.1c0.1,0,0.2,0,0.3,0c0.8,0,1.6-0.2,2.2-0.6c0.6-0.4,0.9-1,0.9-1.8
				c0-0.7-0.2-1.2-0.7-1.6c-0.5-0.4-1-0.6-1.8-0.6s-1.3,0.2-1.8,0.7s-0.8,1.2-1,2.1l-0.1,0.5l-3.2-0.6l0.1-0.5c0.3-1.6,1-2.8,2-3.7
				s2.3-1.3,3.9-1.3c1,0,2,0.2,2.9,0.7s1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.7,0.7,2.6s-0.2,1.7-0.7,2.4c-0.3,0.4-0.6,0.8-1.1,1.1
				c0.7,0.3,1.2,0.8,1.7,1.3c0.6,0.9,1,1.9,1,3.2c0,1.7-0.6,3.2-1.9,4.3C309.6,257.4,308.1,258,306.2,258L306.2,258z M301.1,252.9
				c0.2,1.1,0.7,2.1,1.6,2.8c1,0.9,2.1,1.3,3.6,1.3c1.6,0,2.9-0.5,4-1.5c1-1,1.5-2.2,1.5-3.6c0-1.1-0.2-1.9-0.8-2.6
				c-0.5-0.7-1.2-1.1-2.2-1.3l-1.4-0.3l1.3-0.6c0.7-0.3,1.3-0.8,1.6-1.3c0.4-0.6,0.5-1.2,0.5-1.8c0-0.7-0.2-1.4-0.6-2.1
				c-0.4-0.6-1-1.1-1.7-1.5c-0.8-0.4-1.6-0.6-2.5-0.6c-1.3,0-2.4,0.4-3.2,1.1c-0.8,0.6-1.3,1.5-1.6,2.6l1.2,0.2
				c0.2-0.9,0.6-1.7,1.2-2.2c0.7-0.6,1.5-0.9,2.5-0.9s1.8,0.3,2.5,0.9c0.6,0.6,1,1.4,1,2.4c0,1.2-0.5,2.1-1.4,2.7
				c-0.8,0.5-1.7,0.8-2.7,0.8l-0.1,0.8c0.4-0.1,0.7-0.1,1-0.1c1.1,0,2.1,0.4,2.9,1.1c0.8,0.8,1.1,1.7,1.1,2.9s-0.4,2.2-1.2,3
				c-0.8,0.8-1.8,1.2-3,1.2c-1,0-1.9-0.3-2.5-0.9c-0.6-0.6-1.1-1.4-1.3-2.5L301.1,252.9L301.1,252.9z"/>
		</g>
		<g>
			<path class="st1" d="M267,295.5v-4.3h-7.8v-2l8.2-11.6h1.8v11.6h2.4v2h-2.4v4.3H267z M267,289.2v-8.1l-5.6,8.1H267z"/>
		</g>
		<g>
			<path class="st1" d="M269.8,296h-3.2v-4.3h-7.8V289l8.4-11.9h2.6v11.6h2.4v3h-2.4V296L269.8,296z M267.5,295h1.2v-4.3h2.4v-1
				h-2.4v-11.6h-1l-7.9,11.2v1.4h7.8L267.5,295L267.5,295z M267.5,289.8h-7.1l7.1-10.2V289.8z M262.4,288.8h4.1v-6L262.4,288.8z"/>
		</g>
		<g>
			<path class="st1" d="M194.8,314.1l2.3-0.2c0.2,1.1,0.6,2,1.2,2.5c0.6,0.6,1.4,0.9,2.2,0.9c1.1,0,1.9-0.4,2.7-1.2
				c0.7-0.8,1.1-1.9,1.1-3.2s-0.4-2.2-1.1-3c-0.7-0.7-1.6-1.1-2.8-1.1c-0.7,0-1.4,0.2-1.9,0.5c-0.6,0.3-1,0.7-1.3,1.2l-2.1-0.3
				l1.7-9.2h8.9v2.1h-7.1l-1,4.8c1.1-0.8,2.2-1.1,3.4-1.1c1.6,0,2.9,0.5,4,1.6s1.6,2.5,1.6,4.2s-0.5,3-1.4,4.2
				c-1.1,1.5-2.7,2.2-4.7,2.2c-1.6,0-3-0.5-4-1.4S194.9,315.6,194.8,314.1L194.8,314.1z"/>
		</g>
		<g>
			<path class="st1" d="M200.5,319.6c-1.8,0-3.2-0.5-4.3-1.5s-1.8-2.3-1.9-4l-0.1-0.5l3.3-0.3l0.1,0.5c0.1,1,0.5,1.8,1,2.2
				c0.5,0.5,1.2,0.7,1.9,0.7c0.9,0,1.7-0.3,2.3-1s1-1.6,1-2.8c0-1.1-0.3-2-0.9-2.6c-0.6-0.6-1.4-0.9-2.4-0.9c-0.6,0-1.2,0.1-1.7,0.4
				s-0.9,0.6-1.2,1.1l-0.2,0.3l-2.9-0.4l1.9-10.1h9.8v3.1H199l-0.7,3.3c0.9-0.4,1.8-0.6,2.7-0.6c1.7,0,3.1,0.6,4.3,1.8
				c1.2,1.2,1.8,2.7,1.8,4.5c0,1.7-0.5,3.2-1.5,4.5C204.4,318.9,202.7,319.6,200.5,319.6L200.5,319.6z M195.4,314.6
				c0.2,1.1,0.7,2.1,1.5,2.8c0.9,0.8,2.1,1.2,3.6,1.2c1.8,0,3.2-0.6,4.3-2c0.9-1.1,1.3-2.4,1.3-3.9s-0.5-2.8-1.5-3.8
				s-2.2-1.5-3.6-1.5c-1.1,0-2.1,0.4-3.1,1l-1,0.7l1.3-6.4h7.1v-1.1h-8l-1.6,8.3l1.2,0.2c0.4-0.5,0.8-0.9,1.3-1.1
				c0.6-0.4,1.4-0.5,2.2-0.5c1.3,0,2.3,0.4,3.1,1.2c0.8,0.8,1.2,1.9,1.2,3.3s-0.4,2.6-1.2,3.5s-1.9,1.4-3.1,1.4c-1,0-1.9-0.3-2.6-1
				c-0.6-0.6-1-1.4-1.3-2.4L195.4,314.6L195.4,314.6z"/>
		</g>
		<g>
			<g>
				<path class="st1" d="M139.6,283l-2.2,0.2c-0.2-0.9-0.5-1.5-0.8-1.9c-0.6-0.6-1.3-0.9-2.2-0.9c-0.7,0-1.3,0.2-1.8,0.6
					c-0.7,0.5-1.2,1.2-1.6,2.2c-0.4,1-0.6,2.4-0.6,4.1c0.5-0.8,1.2-1.4,1.9-1.8c0.7-0.4,1.6-0.6,2.4-0.6c1.5,0,2.7,0.5,3.7,1.6
					s1.5,2.5,1.5,4.2c0,1.1-0.2,2.2-0.7,3.1c-0.5,1-1.2,1.7-2,2.2s-1.8,0.8-2.9,0.8c-1.8,0-3.3-0.7-4.5-2c-1.2-1.3-1.7-3.6-1.7-6.7
					c0-3.5,0.6-6,1.9-7.5c1.1-1.4,2.6-2.1,4.5-2.1c1.4,0,2.6,0.4,3.5,1.2C138.9,280.6,139.4,281.6,139.6,283L139.6,283z
					 M130.6,290.8c0,0.8,0.2,1.5,0.5,2.2c0.3,0.7,0.8,1.2,1.3,1.6c0.5,0.4,1.2,0.5,1.8,0.5c0.9,0,1.7-0.4,2.4-1.1
					c0.7-0.7,1-1.8,1-3.1c0-1.3-0.3-2.2-1-2.9c-0.7-0.7-1.5-1.1-2.5-1.1s-1.8,0.4-2.5,1.1S130.6,289.6,130.6,290.8L130.6,290.8z"/>
				<path class="st1" d="M134.2,297.4c-2,0-3.6-0.7-4.9-2.2s-1.8-3.7-1.8-7c0-3.6,0.7-6.2,2-7.9c1.2-1.5,2.9-2.2,4.9-2.2
					c1.5,0,2.8,0.4,3.8,1.3c1,0.9,1.6,2.1,1.8,3.6l0.1,0.5l-3.1,0.2l-0.1-0.4c-0.2-0.8-0.4-1.3-0.7-1.6c-0.5-0.5-1.1-0.8-1.8-0.8
					c-0.6,0-1.1,0.2-1.6,0.5c-0.6,0.4-1.1,1.1-1.5,2c-0.3,0.6-0.4,1.5-0.5,2.5c0.3-0.3,0.7-0.6,1.2-0.8c0.8-0.4,1.7-0.6,2.6-0.6
					c1.6,0,3,0.6,4.1,1.8c1.1,1.2,1.7,2.7,1.7,4.5c0,1.2-0.3,2.3-0.8,3.4c-0.5,1-1.3,1.9-2.2,2.4S135.4,297.5,134.2,297.4
					L134.2,297.4z M134.4,279.1c-1.8,0-3.1,0.6-4.1,1.9c-1.2,1.5-1.8,3.9-1.8,7.2c0,3,0.5,5.1,1.6,6.3c1.1,1.2,2.4,1.9,4.1,1.9
					c1,0,1.9-0.2,2.6-0.7c0.8-0.5,1.4-1.1,1.8-2c0.4-0.9,0.7-1.9,0.7-2.9c0-1.6-0.5-2.8-1.4-3.8c-0.9-1-2.1-1.5-3.4-1.5
					c-0.8,0-1.5,0.2-2.2,0.5c-0.7,0.4-1.3,0.9-1.8,1.6l-0.9,1.4v-1.7c0-1.9,0.2-3.3,0.7-4.3c0.4-1.1,1-1.9,1.8-2.4
					c0.6-0.4,1.3-0.7,2.1-0.7c1,0,1.9,0.4,2.6,1.1c0.4,0.4,0.6,0.9,0.8,1.7l1.2-0.1c-0.2-1-0.7-1.8-1.4-2.4
					C136.8,279.5,135.8,279.1,134.4,279.1L134.4,279.1z M134.2,295.5c-0.7,0-1.4-0.2-2.1-0.6c-0.7-0.4-1.2-1-1.5-1.8
					c-0.3-0.8-0.5-1.6-0.5-2.4c0-1.3,0.4-2.3,1.2-3.1c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.1,0.4,2.9,1.2c0.7,0.8,1.1,1.9,1.1,3.3
					c0,1.4-0.4,2.5-1.1,3.4C136.2,295.1,135.2,295.5,134.2,295.5L134.2,295.5z M134.2,287.5c-0.9,0-1.6,0.3-2.2,0.9
					c-0.6,0.6-0.9,1.4-0.9,2.4c0,0.7,0.2,1.3,0.4,2c0.3,0.6,0.7,1.1,1.2,1.4s1,0.5,1.6,0.5c0.8,0,1.4-0.3,2-1
					c0.6-0.6,0.9-1.6,0.9-2.7c0-1.1-0.3-2-0.9-2.6C135.7,287.7,134.9,287.5,134.2,287.5L134.2,287.5z"/>
			</g>
		</g>
		<g>
			<path class="st1" d="M90.3,227.6v-2.1h11.6v1.7c-1.1,1.2-2.3,2.8-3.4,4.8c-1.1,2-2,4.1-2.6,6.2c-0.4,1.5-0.7,3.1-0.8,4.9h-2.3
				c0-1.4,0.3-3.1,0.8-5.1s1.3-3.9,2.3-5.8c1-1.9,2-3.4,3.2-4.7h-8.8V227.6z"/>
		</g>
		<g>
			<path class="st1" d="M95.7,243.7h-3.3v-0.5c0-1.4,0.3-3.2,0.9-5.2c0.5-2,1.3-4,2.3-5.9c0.8-1.5,1.7-2.8,2.5-3.9h-8.2v-3.1h12.6
				v2.4l-0.1,0.1c-1.1,1.2-2.2,2.8-3.3,4.7c-1.1,2-2,4-2.5,6.1c-0.4,1.5-0.7,3.1-0.8,4.8L95.7,243.7L95.7,243.7z M93.4,242.7h1.3
				c0.1-1.6,0.4-3.2,0.8-4.6c0.6-2.1,1.5-4.3,2.6-6.3s2.2-3.6,3.3-4.8v-1H90.9v1.1h9.4l-0.7,0.8c-1.1,1.2-2.1,2.8-3.1,4.6
				s-1.7,3.7-2.2,5.7C93.8,239.9,93.6,241.4,93.4,242.7L93.4,242.7z"/>
		</g>
		<g>
			<path class="st1" d="M91.7,165.9c-0.9-0.3-1.6-0.8-2-1.4c-0.4-0.6-0.7-1.4-0.7-2.2c0-1.3,0.5-2.4,1.4-3.3
				c0.9-0.9,2.2-1.3,3.7-1.3s2.8,0.4,3.8,1.4s1.4,2,1.4,3.3c0,0.8-0.2,1.6-0.7,2.2c-0.4,0.6-1.1,1.1-2,1.4c1.1,0.4,1.9,0.9,2.5,1.7
				c0.6,0.8,0.9,1.8,0.9,2.9c0,1.5-0.5,2.8-1.6,3.9c-1.1,1-2.5,1.6-4.3,1.6c-1.8,0-3.2-0.5-4.3-1.6c-1.1-1-1.6-2.3-1.6-3.9
				c0-1.2,0.3-2.1,0.9-2.9C89.8,166.7,90.6,166.2,91.7,165.9L91.7,165.9z M90.4,170.4c0,0.6,0.2,1.2,0.5,1.8c0.3,0.6,0.7,1,1.3,1.4
				c0.6,0.4,1.2,0.5,1.9,0.5c1,0,1.9-0.3,2.6-1s1-1.5,1-2.6c0-1.1-0.3-1.9-1.1-2.6c-0.8-0.7-1.6-1-2.6-1s-1.9,0.3-2.6,1
				C90.8,168.6,90.4,169.4,90.4,170.4z M91.2,162.2c0,0.8,0.3,1.5,0.8,2.1c0.5,0.6,1.2,0.8,2.1,0.8c0.9,0,1.5-0.3,2.1-0.8
				c0.5-0.5,0.8-1.2,0.8-2s-0.3-1.5-0.8-2c-0.6-0.6-1.2-0.8-2.1-0.8c-0.9,0-1.5,0.3-2.1,0.8C91.4,160.8,91.2,161.4,91.2,162.2
				L91.2,162.2z"/>
		</g>
		<g>
			<path class="st1" d="M94.2,176.4c-1.9,0-3.5-0.6-4.6-1.7c-1.2-1.1-1.8-2.6-1.8-4.3c0-1.3,0.3-2.3,1-3.2c0.4-0.6,1-1,1.7-1.4
				c-0.5-0.3-0.9-0.7-1.2-1.1c-0.5-0.7-0.8-1.6-0.8-2.5c0-1.4,0.5-2.7,1.6-3.7c1-1,2.4-1.5,4.1-1.5s3.1,0.5,4.1,1.5s1.6,2.2,1.6,3.7
				c0,0.9-0.2,1.8-0.7,2.5c-0.3,0.4-0.7,0.8-1.2,1.1c0.7,0.4,1.3,0.9,1.7,1.5c0.6,0.9,1,1.9,1,3.2c0,1.7-0.6,3.1-1.8,4.2
				C97.6,175.9,96.1,176.4,94.2,176.4L94.2,176.4z M94.1,158.2c-1.4,0-2.5,0.4-3.4,1.2c-0.8,0.8-1.2,1.8-1.2,2.9
				c0,0.8,0.2,1.4,0.6,1.9c0.4,0.5,1,0.9,1.8,1.2l1.5,0.6l-1.5,0.4c-1,0.3-1.7,0.7-2.2,1.4c-0.5,0.7-0.8,1.6-0.8,2.6
				c0,1.4,0.5,2.6,1.5,3.6s2.3,1.4,3.9,1.4c1.6,0,2.9-0.5,3.9-1.4c1-0.9,1.5-2.1,1.5-3.5c0-1-0.2-1.8-0.8-2.6
				c-0.5-0.7-1.3-1.2-2.3-1.6l-1.4-0.4l1.3-0.5c0.8-0.3,1.4-0.7,1.8-1.2s0.6-1.2,0.6-1.9c0-1.2-0.4-2.1-1.3-2.9
				C96.7,158.6,95.4,158.2,94.1,158.2L94.1,158.2z M94.2,174.6c-0.8,0-1.5-0.2-2.1-0.5c-0.7-0.4-1.2-0.9-1.5-1.6
				c-0.3-0.7-0.5-1.3-0.5-2c0-1.2,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.2c1.1,0,2.2,0.4,3,1.2c0.8,0.8,1.2,1.8,1.2,3
				s-0.4,2.2-1.2,2.9C96.4,174.2,95.3,174.6,94.2,174.6L94.2,174.6z M94.1,167.4c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2
				c0,0.5,0.1,1.1,0.4,1.6c0.2,0.5,0.6,0.9,1.1,1.2s1.1,0.4,1.7,0.4c0.9,0,1.7-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2
				s-0.3-1.7-0.9-2.3C95.8,167.6,95.1,167.4,94.1,167.4L94.1,167.4z M94.2,165.6c-1,0-1.8-0.3-2.5-0.9c-0.6-0.6-1-1.4-1-2.4
				c0-0.9,0.3-1.7,1-2.3c0.7-0.6,1.5-0.9,2.4-0.9s1.8,0.3,2.4,1c0.6,0.7,1,1.4,1,2.4s-0.3,1.7-1,2.3
				C95.8,165.4,95.2,165.6,94.2,165.6z M94.2,159.9c-0.7,0-1.3,0.2-1.7,0.7c-0.5,0.4-0.7,1-0.7,1.6c0,0.7,0.2,1.3,0.7,1.7
				s1,0.7,1.8,0.7c0.8,0,1.3-0.2,1.7-0.7c0.5-0.4,0.7-1,0.7-1.6c0-0.7-0.2-1.2-0.7-1.7C95.3,160.2,94.8,159.9,94.2,159.9L94.2,159.9
				z"/>
		</g>
		<g>
			<path class="st1" d="M131.4,116.2l2.1-0.2c0.2,1,0.5,1.7,1,2.2s1.2,0.7,1.9,0.7c0.7,0,1.3-0.2,1.8-0.5s0.9-0.7,1.2-1.2
				c0.3-0.5,0.6-1.2,0.8-2.1c0.2-0.9,0.3-1.8,0.3-2.7c0-0.1,0-0.2,0-0.4c-0.4,0.7-1,1.3-1.8,1.7c-0.8,0.4-1.6,0.7-2.5,0.7
				c-1.5,0-2.7-0.5-3.8-1.6s-1.5-2.5-1.5-4.2c0-1.7,0.5-3.3,1.6-4.4c1.1-1.1,2.4-1.7,4-1.7c1.2,0,2.2,0.3,3.2,0.9s1.7,1.5,2.2,2.7
				c0.5,1.2,0.8,2.8,0.8,5c0,2.2-0.2,4.1-0.7,5.5c-0.5,1.4-1.2,2.4-2.2,3.1s-2.1,1.1-3.4,1.1c-1.4,0-2.5-0.4-3.4-1.2
				C132.1,118.8,131.6,117.5,131.4,116.2L131.4,116.2z M140.4,108.3c0-1.3-0.3-2.3-1-3c-0.7-0.7-1.5-1.1-2.4-1.1
				c-0.9,0-1.9,0.3-2.6,1.1c-0.7,0.8-1.1,1.8-1.1,3.1c0,1.1,0.3,2.1,1,2.8c0.7,0.7,1.5,1.1,2.5,1.1s1.8-0.4,2.5-1.1
				S140.4,109.6,140.4,108.3L140.4,108.3z"/>
		</g>
		<g>
			<path class="st1" d="M136.4,121.2c-1.5,0-2.8-0.4-3.8-1.3s-1.6-2.1-1.8-3.6l-0.1-0.5l3.1-0.3l0.1,0.4c0.2,0.9,0.4,1.5,0.9,1.9
				c0.4,0.4,0.9,0.5,1.6,0.5c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.8-0.6,1.1-1.1s0.6-1.1,0.8-1.9c0.1-0.5,0.2-1.1,0.3-1.6
				c-0.3,0.3-0.7,0.5-1,0.7c-0.8,0.5-1.8,0.7-2.7,0.7c-1.6,0-3-0.6-4.1-1.8c-1.1-1.2-1.7-2.7-1.7-4.6c0-1.9,0.6-3.5,1.8-4.7
				c1.2-1.2,2.6-1.8,4.4-1.8c1.3,0,2.4,0.3,3.5,1c1.1,0.7,1.8,1.7,2.4,2.9c0.6,1.2,0.8,3,0.8,5.2c0,2.3-0.3,4.2-0.8,5.6
				s-1.3,2.6-2.4,3.3C139.1,120.8,137.8,121.2,136.4,121.2z M131.9,116.7c0.2,1,0.7,1.8,1.3,2.4c0.8,0.7,1.8,1,3.1,1
				c1.2,0,2.3-0.3,3.2-1s1.6-1.6,2-2.9c0.5-1.3,0.7-3.1,0.7-5.3c0-2.2-0.2-3.8-0.7-4.8c-0.5-1.1-1.1-1.9-2-2.5
				c-0.9-0.6-1.9-0.9-2.9-0.9c-1.5,0-2.7,0.5-3.7,1.5s-1.5,2.3-1.5,4c0,1.7,0.5,2.9,1.4,3.9c0.9,1,2.1,1.5,3.4,1.5
				c0.8,0,1.5-0.2,2.2-0.6s1.2-0.9,1.6-1.5l0.9-1.4l0.1,1.6c0,0.2,0,0.4,0,0.5c0,0.9-0.1,1.9-0.3,2.8s-0.5,1.7-0.9,2.2
				c-0.4,0.6-0.8,1.1-1.4,1.4c-0.6,0.4-1.3,0.5-2,0.5c-0.9,0-1.7-0.3-2.3-0.8c-0.5-0.4-0.9-1.1-1.1-2L131.9,116.7L131.9,116.7z
				 M136.9,112.8c-1.2,0-2.1-0.4-2.9-1.2s-1.2-1.9-1.2-3.1c0-1.4,0.4-2.5,1.2-3.4c0.8-0.9,1.8-1.4,2.9-1.4c1.1,0,2,0.4,2.8,1.3
				c0.8,0.8,1.1,2,1.1,3.3c0,1.3-0.4,2.5-1.1,3.3C138.9,112.4,137.9,112.8,136.9,112.8L136.9,112.8z M136.9,104.7
				c-0.8,0-1.6,0.3-2.2,1c-0.6,0.7-1,1.6-1,2.8c0,1,0.3,1.8,0.9,2.4c0.6,0.6,1.3,0.9,2.2,0.9s1.6-0.3,2.1-0.9
				c0.6-0.6,0.8-1.5,0.8-2.6s-0.3-2-0.9-2.7C138.4,104.9,137.7,104.7,136.9,104.7L136.9,104.7z"/>
		</g>
		<g>
			<path class="st2" d="M174.2,244.2l61.5,38.6c3.1-1.3,6.1-2.8,8.9-4.5l-66.1-41.4L174.2,244.2L174.2,244.2z"/>
			<path class="st2" d="M165.2,259.5l47.5,29.8c4.1-0.6,8.1-1.4,12.1-2.5l-55.2-34.6L165.2,259.5L165.2,259.5z"/>
			<path class="st2" d="M214.8,238.6c-3.8,1.4-7.8,2.3-12,2.6l34.7,21.7l-9.6-16.1L214.8,238.6L214.8,238.6z"/>
			<path class="st2" d="M156.2,274.6l18.4,11.6c7,2.1,14.4,3.4,22,3.7l-36-22.6C160.6,267.4,156.2,274.6,156.2,274.6z"/>
			<path class="st1" d="M154.6,277.4c6.2,3.7,13,6.7,20.1,8.8l-18.4-11.6L154.6,277.4z"/>
			<path class="st1" d="M160.4,267.4l36,22.6c1.3,0.1,2.6,0.1,3.8,0.1c4.2,0,8.3-0.3,12.3-0.8l-47.5-29.8L160.4,267.4L160.4,267.4z"
				/>
			<path class="st1" d="M169.6,252.1l55.2,34.6c3.8-1.1,7.5-2.4,11.1-3.9l-61.5-38.6L169.6,252.1L169.6,252.1z"/>
			<path class="st1" d="M200.4,241.2c-7.6,0-14.8-2.1-20.9-5.8l-0.9,1.4l66.1,41.4c0.5-0.3,1-0.5,1.4-0.8l-8.7-14.6L202.7,241
				C202.1,241.2,201.2,241.2,200.4,241.2L200.4,241.2z"/>
			<path class="st1" d="M221.2,235.5c-2,1.2-4.2,2.2-6.4,3.1l13.2,8.3L221.2,235.5L221.2,235.5z"/>
		</g>
		<g>
			<path class="st1" d="M210.9,81.7v-7.9h5.3v0.9h-4.3V77h3.7v0.9h-3.7v3.6L210.9,81.7L210.9,81.7z"/>
			<path class="st1" d="M217.6,81.7v-7.9h5.7v0.9h-4.6V77h4.4v0.9h-4.4v2.7h4.8v0.9L217.6,81.7L217.6,81.7z"/>
			<path class="st1" d="M224.9,81.7v-7.9h5.7v0.9h-4.6V77h4.4v0.9h-4.4v2.7h4.8v0.9L224.9,81.7L224.9,81.7z"/>
			<path class="st1" d="M234.2,81.7v-7h-2.6v-0.9h6.2v0.9h-2.6v7H234.2L234.2,81.7z"/>
		</g>
		<g>
			<path class="st1" d="M178.5,81.7h-1v-6.2c-0.2,0.2-0.5,0.4-0.9,0.7c-0.4,0.2-0.7,0.4-1,0.5v-0.9c0.5-0.2,1-0.6,1.4-0.9
				c0.4-0.3,0.7-0.7,0.9-1h0.6V81.7L178.5,81.7z"/>
			<path class="st1" d="M181,77.8c0-0.9,0.1-1.7,0.3-2.2s0.5-1,0.9-1.3c0.4-0.3,0.9-0.5,1.4-0.5c0.4,0,0.8,0.1,1.1,0.2
				c0.3,0.1,0.6,0.4,0.8,0.7s0.4,0.7,0.5,1.2s0.2,1.1,0.2,1.9c0,0.9-0.1,1.7-0.3,2.2c-0.2,0.6-0.5,1-0.9,1.3
				c-0.4,0.3-0.9,0.5-1.4,0.5c-0.8,0-1.4-0.3-1.8-0.8C181.2,80.3,181,79.2,181,77.8z M182,77.8c0,1.3,0.1,2.2,0.4,2.6
				c0.3,0.4,0.7,0.6,1.1,0.6s0.8-0.2,1.1-0.6s0.4-1.3,0.4-2.6s-0.1-2.2-0.4-2.6c-0.3-0.4-0.7-0.6-1.1-0.6s-0.8,0.2-1.1,0.6
				S182,76.6,182,77.8L182,77.8z"/>
			<path class="st1" d="M187.1,77.8c0-0.9,0.1-1.7,0.3-2.2c0.2-0.6,0.5-1,0.9-1.3c0.4-0.3,0.9-0.5,1.4-0.5c0.4,0,0.8,0.1,1.1,0.2
				c0.3,0.1,0.6,0.4,0.8,0.7s0.4,0.7,0.5,1.2s0.2,1.1,0.2,1.9c0,0.9-0.1,1.7-0.3,2.2s-0.5,1-0.9,1.3c-0.4,0.3-0.9,0.5-1.4,0.5
				c-0.8,0-1.4-0.3-1.8-0.8C187.4,80.3,187.1,79.2,187.1,77.8L187.1,77.8z M188.1,77.8c0,1.3,0.1,2.2,0.4,2.6
				c0.3,0.4,0.7,0.6,1.1,0.6s0.8-0.2,1.1-0.6c0.3-0.4,0.4-1.3,0.4-2.6s-0.1-2.2-0.4-2.6c-0.3-0.4-0.7-0.6-1.1-0.6s-0.8,0.2-1.1,0.6
				C188.2,75.7,188.1,76.6,188.1,77.8L188.1,77.8z"/>
		</g>
		<g>
			<path class="st1" d="M179.6,137.9h-1v-6.2c-0.2,0.2-0.5,0.4-0.9,0.7c-0.4,0.2-0.7,0.4-1,0.5v-0.9c0.5-0.2,1-0.6,1.4-0.9
				s0.7-0.7,0.9-1.1h0.6V137.9L179.6,137.9z"/>
			<path class="st1" d="M182,134.1c0-0.9,0.1-1.7,0.3-2.2s0.5-1,0.9-1.3s0.9-0.5,1.4-0.5c0.4,0,0.8,0.1,1.1,0.2
				c0.3,0.1,0.6,0.4,0.8,0.7s0.4,0.7,0.5,1.2s0.2,1.1,0.2,1.9c0,0.9-0.1,1.7-0.3,2.2c-0.2,0.6-0.5,1-0.9,1.3s-0.9,0.5-1.4,0.5
				c-0.8,0-1.4-0.3-1.8-0.8C182.2,136.6,182,135.6,182,134.1z M183,134.1c0,1.3,0.1,2.2,0.4,2.6c0.3,0.4,0.7,0.6,1.1,0.6
				s0.8-0.2,1.1-0.6c0.3-0.4,0.4-1.3,0.4-2.6c0-1.3-0.1-2.2-0.4-2.6c-0.3-0.4-0.7-0.6-1.1-0.6s-0.8,0.2-1.1,0.6
				C183.2,131.9,183,132.8,183,134.1L183,134.1z"/>
			<path class="st1" d="M188.2,134.1c0-0.9,0.1-1.7,0.3-2.2c0.2-0.6,0.5-1,0.9-1.3s0.9-0.5,1.4-0.5c0.4,0,0.8,0.1,1.1,0.2
				c0.3,0.1,0.6,0.4,0.8,0.7s0.4,0.7,0.5,1.2s0.2,1.1,0.2,1.9c0,0.9-0.1,1.7-0.3,2.2s-0.5,1-0.9,1.3s-0.9,0.5-1.4,0.5
				c-0.8,0-1.4-0.3-1.8-0.8C188.4,136.6,188.2,135.6,188.2,134.1L188.2,134.1z M189.2,134.1c0,1.3,0.1,2.2,0.4,2.6
				c0.3,0.4,0.7,0.6,1.1,0.6s0.8-0.2,1.1-0.6c0.3-0.4,0.4-1.3,0.4-2.6c0-1.3-0.1-2.2-0.4-2.6c-0.3-0.4-0.7-0.6-1.1-0.6
				s-0.8,0.2-1.1,0.6C189.2,131.9,189.2,132.8,189.2,134.1L189.2,134.1z"/>
			<path class="st1" d="M194.2,134.1c0-0.9,0.1-1.7,0.3-2.2s0.5-1,0.9-1.3s0.9-0.5,1.4-0.5c0.4,0,0.8,0.1,1.1,0.2
				c0.3,0.1,0.6,0.4,0.8,0.7s0.4,0.7,0.5,1.2s0.2,1.1,0.2,1.9c0,0.9-0.1,1.7-0.3,2.2c-0.2,0.6-0.5,1-0.9,1.3s-0.9,0.5-1.4,0.5
				c-0.8,0-1.4-0.3-1.8-0.8C194.5,136.6,194.2,135.6,194.2,134.1z M195.2,134.1c0,1.3,0.1,2.2,0.4,2.6c0.3,0.4,0.7,0.6,1.1,0.6
				s0.8-0.2,1.1-0.6c0.3-0.4,0.4-1.3,0.4-2.6c0-1.3-0.1-2.2-0.4-2.6c-0.3-0.4-0.7-0.6-1.1-0.6s-0.8,0.2-1.1,0.6
				C195.4,131.9,195.2,132.8,195.2,134.1L195.2,134.1z"/>
			<path class="st1" d="M203.9,137.9v-7.9h5.3v0.9h-4.3v2.4h3.7v1h-3.7v3.6H203.9L203.9,137.9z"/>
			<path class="st1" d="M210.6,137.9v-7.9h5.7v0.9h-4.6v2.4h4.4v1h-4.4v2.7h4.8v0.9H210.6L210.6,137.9z"/>
			<path class="st1" d="M217.9,137.9v-7.9h5.7v0.9h-4.6v2.4h4.4v1h-4.4v2.7h4.8v0.9H217.9L217.9,137.9z"/>
			<path class="st1" d="M227.2,137.9v-6.9h-2.6v-0.9h6.2v0.9h-2.6v6.9H227.2L227.2,137.9z"/>
		</g>
		<g>
			<path class="st1" d="M111.4,189.2l1.1,0.3c-0.2,0.9-0.7,1.6-1.3,2.1c-0.6,0.5-1.4,0.7-2.3,0.7c-0.9,0-1.7-0.2-2.3-0.6
				s-1-0.9-1.3-1.6c-0.3-0.7-0.5-1.5-0.5-2.3c0-0.9,0.2-1.7,0.5-2.3c0.3-0.6,0.8-1.2,1.5-1.5c0.7-0.3,1.3-0.5,2.1-0.5
				c0.9,0,1.6,0.2,2.2,0.7c0.6,0.5,1,1.1,1.2,1.9l-1.1,0.3c-0.2-0.6-0.5-1.1-0.9-1.4s-0.9-0.4-1.4-0.4c-0.7,0-1.2,0.2-1.7,0.5
				s-0.8,0.7-0.9,1.3c-0.1,0.6-0.3,1.1-0.3,1.6c0,0.7,0.1,1.4,0.3,1.9c0.2,0.5,0.5,0.9,1,1.2s0.9,0.4,1.5,0.4s1.2-0.2,1.6-0.6
				C110.8,190.5,111.2,190,111.4,189.2L111.4,189.2z"/>
			<path class="st1" d="M113,192.2l3.3-8.6h1.2l3.5,8.6h-1.3l-1-2.6h-3.6l-0.9,2.6H113L113,192.2z M115.5,188.8h2.9l-0.9-2.4
				c-0.3-0.7-0.5-1.3-0.6-1.8c-0.1,0.6-0.3,1.1-0.5,1.6L115.5,188.8z"/>
			<path class="st1" d="M121.9,192.2v-8.6h1.1v7.6h4.3v1H121.9z"/>
			<path class="st1" d="M128.9,192.2v-8.6h1.1v8.6H128.9z"/>
			<path class="st1" d="M131.9,192.2v-8.6h3.2c0.7,0,1.2,0.1,1.6,0.3c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.4,0.3,0.7,0.3,1.1
				c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.6-0.9,0.8c0.5,0.1,0.9,0.4,1.2,0.8c0.3,0.4,0.4,0.8,0.4,1.3c0,0.4-0.1,0.8-0.2,1.1
				c-0.1,0.3-0.4,0.6-0.6,0.8s-0.6,0.3-0.9,0.4s-0.8,0.1-1.4,0.1L131.9,192.2L131.9,192.2z M133.1,187.2h1.9c0.5,0,0.9,0,1.1-0.1
				c0.3-0.1,0.5-0.2,0.7-0.4s0.2-0.4,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.3-0.4-0.6-0.4c-0.3,0-0.7-0.1-1.3-0.1h-1.7
				L133.1,187.2L133.1,187.2z M133.1,191.2h2.1c0.4,0,0.6,0,0.8,0c0.3-0.1,0.5-0.1,0.7-0.2s0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.2-0.7
				s-0.1-0.6-0.2-0.8c-0.1-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.7-0.1-1.3-0.1h-2V191.2L133.1,191.2z"/>
			<path class="st1" d="M140.1,192.2v-8.6h3.8c0.8,0,1.3,0.1,1.8,0.2s0.7,0.4,1,0.8s0.4,0.8,0.4,1.3c0,0.6-0.2,1.1-0.6,1.5
				c-0.4,0.4-1,0.7-1.8,0.8c0.3,0.1,0.5,0.3,0.7,0.4c0.3,0.3,0.7,0.7,0.9,1.1l1.5,2.3h-1.4l-1.1-1.8c-0.3-0.5-0.6-0.9-0.8-1.2
				c-0.2-0.3-0.4-0.5-0.6-0.6s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.6,0h-1.3v3.8C141.2,192.2,140.1,192.2,140.1,192.2z M141.2,187.5h2.4
				c0.5,0,0.9-0.1,1.2-0.2c0.3-0.1,0.5-0.3,0.7-0.5s0.2-0.5,0.2-0.8c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.8-0.4-1.4-0.4h-2.7L141.2,187.5
				L141.2,187.5z"/>
			<path class="st1" d="M147.8,192.2l3.3-8.6h1.2l3.5,8.6h-1.3l-1-2.6h-3.6l-0.9,2.6H147.8L147.8,192.2z M150.2,188.8h2.9l-0.9-2.4
				c-0.3-0.7-0.5-1.3-0.6-1.8c-0.1,0.6-0.3,1.1-0.5,1.6L150.2,188.8z"/>
			<path class="st1" d="M157.9,192.2v-7.6h-2.8v-1h6.8v1h-2.8v7.6H157.9z"/>
			<path class="st1" d="M163.2,192.2v-8.6h6.2v1h-5.1v2.6h4.8v1h-4.8v2.9h5.3v1L163.2,192.2L163.2,192.2z"/>
			<path class="st1" d="M171.2,192.2v-8.6h3c0.7,0,1.2,0,1.5,0.1c0.5,0.1,0.9,0.3,1.3,0.6c0.4,0.4,0.8,0.9,1,1.5s0.3,1.3,0.3,2
				c0,0.6-0.1,1.2-0.2,1.7s-0.4,0.9-0.6,1.2s-0.5,0.6-0.8,0.8s-0.6,0.3-1,0.4s-0.9,0.1-1.4,0.1L171.2,192.2L171.2,192.2z
				 M172.2,191.2h1.8c0.6,0,1-0.1,1.3-0.2c0.3-0.1,0.6-0.2,0.8-0.4c0.3-0.3,0.5-0.6,0.6-1.1s0.2-1,0.2-1.7c0-0.9-0.1-1.6-0.4-2.1
				s-0.6-0.8-1.1-1c-0.3-0.1-0.8-0.2-1.5-0.2h-1.8L172.2,191.2L172.2,191.2z"/>
		</g>
		<g>
			<path class="st1" d="M131.9,204.2v-7.6h-2.8v-1h6.8v1h-2.8v7.6H131.9z"/>
			<path class="st1" d="M136.6,200.1c0-1.4,0.4-2.5,1.2-3.4c0.8-0.9,1.8-1.2,3-1.2c0.8,0,1.5,0.2,2.1,0.6c0.6,0.4,1.1,0.9,1.5,1.6
				c0.4,0.7,0.5,1.4,0.5,2.3s-0.2,1.6-0.5,2.3s-0.8,1.2-1.5,1.6s-1.3,0.5-2.1,0.5c-0.8,0-1.5-0.2-2.2-0.6s-1.1-0.9-1.4-1.6
				S136.6,200.9,136.6,200.1L136.6,200.1z M137.7,200.2c0,1,0.3,1.9,0.8,2.4s1.2,0.9,2.1,0.9c0.9,0,1.6-0.3,2.1-0.9s0.8-1.5,0.8-2.6
				c0-0.7-0.1-1.3-0.3-1.8c-0.2-0.5-0.6-0.9-1-1.2c-0.4-0.3-1-0.4-1.5-0.4c-0.8,0-1.5,0.3-2.1,0.8
				C138,197.9,137.7,198.9,137.7,200.2L137.7,200.2z"/>
		</g>
		<g>
			<path class="st1" d="M112.3,215.2v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
				c0.9-0.8,1.6-1.4,1.9-1.8s0.5-0.9,0.5-1.3c0-0.4-0.2-0.8-0.5-1.1c-0.3-0.3-0.7-0.4-1.2-0.4s-0.9,0.1-1.2,0.5
				c-0.3,0.4-0.5,0.7-0.5,1.3l-1.1-0.1c0.1-0.8,0.3-1.4,0.8-1.9s1.1-0.6,2-0.6s1.5,0.2,2,0.7c0.5,0.5,0.7,1,0.7,1.7
				c0,0.3-0.1,0.7-0.2,1s-0.4,0.7-0.7,1.1c-0.3,0.4-0.9,0.9-1.6,1.5c-0.6,0.5-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.5,0.6h4.3V215.2z"/>
			<path class="st1" d="M113.4,214.1l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.2s0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.6s0.5-0.9,0.5-1.5
				s-0.2-1.1-0.5-1.4s-0.8-0.5-1.3-0.5c-0.3,0-0.7,0.1-0.9,0.2s-0.5,0.4-0.6,0.6l-1-0.1l0.8-4.4h4.3v1h-3.5l-0.5,2.3
				c0.5-0.4,1.1-0.5,1.6-0.5c0.8,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,2s-0.2,1.4-0.7,2c-0.6,0.7-1.3,1-2.3,1c-0.8,0-1.4-0.2-1.9-0.7
				S113.5,214.8,113.4,214.1L113.4,214.1z"/>
			<path class="st1" d="M123.5,212.1c0-1,0.1-1.8,0.3-2.4s0.5-1.1,0.9-1.4s0.9-0.5,1.6-0.5c0.5,0,0.9,0.1,1.2,0.3s0.6,0.4,0.9,0.8
				s0.4,0.8,0.5,1.3c0.1,0.5,0.2,1.2,0.2,2c0,1-0.1,1.8-0.3,2.4c-0.2,0.6-0.5,1.1-0.9,1.4s-0.9,0.5-1.6,0.5c-0.8,0-1.5-0.3-1.9-0.9
				C123.8,214.9,123.4,213.7,123.5,212.1L123.5,212.1z M124.5,212.1c0,1.4,0.2,2.4,0.5,2.8s0.7,0.7,1.2,0.7s0.9-0.2,1.2-0.7
				s0.5-1.4,0.5-2.8s-0.2-2.4-0.5-2.8s-0.7-0.7-1.2-0.7s-0.9,0.2-1.2,0.6C124.8,209.7,124.5,210.7,124.5,212.1L124.5,212.1z"/>
			<path class="st1" d="M130.2,212.1c0-1,0.1-1.8,0.3-2.4c0.2-0.6,0.5-1.1,0.9-1.4c0.4-0.3,0.9-0.5,1.6-0.5c0.5,0,0.9,0.1,1.2,0.3
				c0.3,0.2,0.6,0.4,0.9,0.8s0.4,0.8,0.5,1.3c0.1,0.5,0.2,1.2,0.2,2c0,1-0.1,1.8-0.3,2.4c-0.2,0.6-0.5,1.1-0.9,1.4
				c-0.4,0.3-0.9,0.5-1.6,0.5c-0.8,0-1.5-0.3-1.9-0.9C130.4,214.9,130.2,213.7,130.2,212.1L130.2,212.1z M131.2,212.1
				c0,1.4,0.2,2.4,0.5,2.8s0.7,0.7,1.2,0.7s0.9-0.2,1.2-0.7c0.3-0.5,0.5-1.4,0.5-2.8s-0.2-2.4-0.5-2.8s-0.7-0.7-1.2-0.7
				s-0.9,0.2-1.2,0.6C131.4,209.7,131.2,210.7,131.2,212.1L131.2,212.1z"/>
			<path class="st1" d="M136.9,212.1c0-1,0.1-1.8,0.3-2.4c0.2-0.6,0.5-1.1,0.9-1.4c0.4-0.3,0.9-0.5,1.6-0.5c0.5,0,0.9,0.1,1.2,0.3
				c0.3,0.2,0.6,0.4,0.9,0.8s0.4,0.8,0.5,1.3c0.1,0.5,0.2,1.2,0.2,2c0,1-0.1,1.8-0.3,2.4c-0.2,0.6-0.5,1.1-0.9,1.4
				c-0.4,0.3-0.9,0.5-1.6,0.5c-0.8,0-1.5-0.3-1.9-0.9C137.2,214.9,136.9,213.7,136.9,212.1L136.9,212.1z M137.9,212.1
				c0,1.4,0.2,2.4,0.5,2.8s0.7,0.7,1.2,0.7s0.9-0.2,1.2-0.7c0.3-0.5,0.5-1.4,0.5-2.8s-0.2-2.4-0.5-2.8s-0.7-0.7-1.2-0.7
				s-0.9,0.2-1.2,0.6C138.2,209.7,137.9,210.7,137.9,212.1L137.9,212.1z"/>
			<path class="st1" d="M147.4,216.2v-8.6h5.8v1h-4.7v2.7h4v1h-4v3.9L147.4,216.2L147.4,216.2z"/>
			<path class="st1" d="M154.7,216.2v-8.6h6.2v1h-5.1v2.6h4.8v1h-4.8v2.9h5.3v1L154.7,216.2L154.7,216.2z"/>
			<path class="st1" d="M162.7,216.2v-8.6h6.2v1h-5.1v2.6h4.8v1h-4.8v2.9h5.3v1L162.7,216.2L162.7,216.2z"/>
			<path class="st1" d="M172.9,216.2v-7.6h-2.8v-1h6.8v1h-2.8v7.6C173.9,216.2,172.9,216.2,172.9,216.2z"/>
		</g>
	</g>
</g>
</svg>
`