import client from "../../client";

export const getCustomerSettings = async () => {
  const { data } = await client.get(`/v1/settings/customer-settings`);
  return data;
};

export const getLayoutGrid = async () => {
  const { data } = await client.get(`/v1/Layout`);
  return data;
}

export const deleteAccount = async (userId: string) => {
  const { data } = await client.delete(`/v1/User/${userId}`);
  return data;
}