import styled from "styled-components";
import base from "../../../themes/base/base";

export const StyledRadio = styled.div <{color: string} >`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: ${props => props.color};
`;

export const RadioInner = styled.div <{selected: boolean, color: string} >`
    border: 3px solid ${base.colors.neutral.grey[800]};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: ${props => props.selected ? props.color : base.colors.neutral.grey[800]};
`