import styled, { css } from "styled-components";
import Button from "../../components/ui/button";
import base from "../../themes/base/base";

export const ButtonStyles = css`
    width: 28.5rem;
    padding: 0.5rem 1rem;
    gap: 0.25rem;
    border-radius: 3.125rem;
    border: 0;
`;

export const PrimaryButton = styled(Button)`
    ${ButtonStyles}

    color: ${base.colors.neutral.grey[900]};
    background: ${base.colors.primary.orange[500]};
    ${base.typography.largeMedium};

    &:hover{
        background: ${base.colors.primary.orange[300]};
    }

    &:disabled{
        background: ${base.colors.neutral.grey[700]};
    }
`;    

export const TertiaryButton = styled(Button)`
    ${ButtonStyles}

    color: ${base.colors.primary.orange[500]};
    background: transparent;
    ${base.typography.largeMedium};

    &:hover{
        background: ${base.colors.neutral.grey[700]};
    }
`; 

export const SecondaryButton = styled(Button)`
    ${ButtonStyles}

    background: transparent;
    border: 1px solid ${base.colors.neutral.grey[50]};
    ${base.typography.largeMedium};

    &:hover{
        background: ${base.colors.neutral.grey[700]};
    }
`; 
