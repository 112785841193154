import React, {useState } from "react";
import {
    Container,
    Header,
    Title,
    HDivider,
    StatName,
    StatValue,
    StatsWrapper,
    IconAndSubtitleWrapper,
    SubTitle,
    TitleContainer  
} from "../../index.styles";
import ColumnChart from "../column-chart";
import { CustomTooltip, StyledImg, StyledImgMargin, TooltipContent, TopStats, Wrapper } from "./styles";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getProgressBarChart, getProgressStats, getSessionsStart } from "../../../../api/overview";
import { LoaderContainer } from "../sessions/sessions.styles";
import Loader from "../../../../components/ui/loader";
import { useProgressDatePreset } from "../../utils";
import { convertMillisecondsToTimespan } from "../../../../utils/functions";
import { BenefitsPropTypes, progressTopStat } from "../../../../types/overview";
import EmptyState from "../../../../components/empty-state";        
import FilterGroup from "../filter-group";
import { CalendarIcon24 } from "../../../../components/icons";
import moment from "moment";
import { ProgressFilters } from "../../../../enums/overview";
import TooltipSVG from '../../../../components/icons/tooltip.svg';


const ProgressOverTime: React.FC<BenefitsPropTypes> = ({ userId}) => {
    const [activeKey, setActiveKey] = useState<string>('last7days');
    const [selectedMonth, setSelectedMonth] = useState<number>();
    const [selectedYear, setSelectedYear] = useState<number>();
    const [date, setDate] = useState<moment.Moment | null>(moment());

    const {from, to, type, year} = useProgressDatePreset(activeKey, selectedMonth, selectedYear);

    const onChange = (key: string) => {
        setActiveKey(key);
    };
    
    const calendarHandler = (date: moment.Moment | null) => {
        setSelectedMonth(date?.month());
        setSelectedYear(date?.year());
        setDate(date);
    }

    const yearHandler = (date: moment.Moment | null) => {
        setSelectedYear(date?.year());
        setDate(date);
    }

    const { data: sessionsStartData } = useQuery(["getSessionsStart", userId],
        () =>
        getSessionsStart({ userId }),
        {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: true,
        }
    );

    const { isFetching, data: statsData } = useQuery(["getProgressStats",from, to, type, userId, selectedMonth, selectedYear],
        () =>
        getProgressStats({from, to, type, userId, year}),
        {
          suspense: false,
          refetchOnMount: false,
          useErrorBoundary: true,
        }
      );

    const { isFetching: loadingBarChart, data: chartData} = useQuery(["getProgressBarChart", from, to, type, userId, selectedMonth, selectedYear, year],
      () =>
      getProgressBarChart({from, to, type, userId, year}),
      {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: true,
      }
    );  

    const isLoading = isFetching || loadingBarChart;
    const formatValue = (val: number, unit: string) => {
        if(unit === 'ms')
            return convertMillisecondsToTimespan(val);
        else
            return Math.trunc(val);
    }

    const getSubTitle = () => {
        switch(activeKey){
            case ProgressFilters.Last7Days:
            case ProgressFilters.Lifetime:
                return <FormattedMessage id={`overview:${activeKey}`} />
            case ProgressFilters.Month:
                return <span>{date?.format('MMMM')} {date?.year()}</span>
            case ProgressFilters.Year:  
                return <span>{date?.year()}</span>  
        }
    }

    const isMonthorYear = activeKey === ProgressFilters.Month || activeKey === ProgressFilters.Year;

    const renderProgressComponent = () => {
        if (isLoading) {
            return <LoaderContainer><Loader size="large"/></LoaderContainer>;
        }
        
        if(chartData.datasets.length === 0) {
            return <EmptyState 
                    title="overview:progress:empty-state:title" 
                    description="overview:sessions:empty-state:description" />
        }

        return(
            <Wrapper>
                <ColumnChart data={chartData?.datasets} filter={activeKey} year={date?.year()}/>
                <TopStats>
                    {statsData?.datasets?.map((stat: progressTopStat, index: number) => {
                        return <React.Fragment key={stat.name}>
                            <StatsWrapper>
                                <StatName>{stat.name}</StatName>
                                <StatValue>{formatValue(stat.result, stat.unit)}</StatValue>
                            </StatsWrapper>
                            {index < (statsData?.datasets.length-1) && <HDivider/>}
                        </React.Fragment>
                        }
                    )}
                </TopStats>
            </Wrapper>
        )
    }

    return (
       <Container>
            <Header>
                <TitleContainer>
                    <Title>
                        <FormattedMessage id="overview:progress-over-time:title" />
                        {isMonthorYear && <CustomTooltip title={
                                <TooltipContent>
                                    <StyledImg src={TooltipSVG} width="14px" />
                                    <FormattedMessage id="overview:progress-over-time:tooltip" />
                                </TooltipContent>
                            } arrow>
                            <StyledImgMargin alt="tooltip" src={TooltipSVG} width="18px"/>
                        </CustomTooltip>}
                    </Title>

                   <FilterGroup 
                        activeKey={activeKey} 
                        onChange={onChange} 
                        date={date} 
                        onDateChange={calendarHandler} 
                        onYearChange={yearHandler}
                        sessionStart={sessionsStartData}
                    />
                </TitleContainer>

                 <IconAndSubtitleWrapper>
                    <CalendarIcon24 fill="#999999" />
                    <SubTitle>
                        {getSubTitle()}
                    </SubTitle>
                </IconAndSubtitleWrapper>
            </Header>

            {renderProgressComponent()} 
              
        </Container>
    )
};

export default ProgressOverTime;
