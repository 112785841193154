import React from "react";
import {
    AddUserErrorMessage,
    ButtonsWrapper,
    Description,
    ModalContentWrapperDiv,
    NotAddedUser,
    NotAddedUsersWrapper,
    StyledCancelButton,
    StyledConfirmButton,
    Title
} from "../../modal-contents.styles";
import AddUserModalContent, {
    AddMultipleUsersSuccessfulAPIResponse,
} from "../add-user/add-user-modal-content";
import { useModal } from "../../../../../../context/modal/modal";
import { FormattedMessage } from "react-intl";

interface ConfirmationModalProps {
    title: string;
    titleIntlValues?: {};
    description?: string;
    descriptionIntlValues?: {};
    isAddUserConfirmationModal?: boolean;
    addMultipleUsersSuccessfulAPIResponse?: AddMultipleUsersSuccessfulAPIResponse;
    setIsNewUserAdded?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationModalContent = ({
    title,
    titleIntlValues,
    description,
    descriptionIntlValues,
    isAddUserConfirmationModal = false,
    addMultipleUsersSuccessfulAPIResponse,
    setIsNewUserAdded
}: ConfirmationModalProps) => {
    const { closeModal, openModal } = useModal();

    const handleAddMoreUsersClick = () => {
        openModal(
            <AddUserModalContent
                title="users:users-table:modal-contents:add-user:title"
                description="users:users-table:modal-contents:add-user:description"
                setShouldUpdateUsersList={setIsNewUserAdded}
            />
        );
    };

    const handleAddUsersConfirmationModalClose = () => {
        closeModal();
    };

    const someUsersNotAdded = addMultipleUsersSuccessfulAPIResponse && addMultipleUsersSuccessfulAPIResponse?.notCreatedUsers.length > 0;
    const usersNotAdded = addMultipleUsersSuccessfulAPIResponse?.notCreatedUsers;

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id={title} values={titleIntlValues} />
            </Title>
            {description && (
                <Description>
                    <FormattedMessage
                        id={description}
                        values={descriptionIntlValues}
                    />
                </Description>
            )}
            {!isAddUserConfirmationModal && (
                <ButtonsWrapper>
                    <StyledConfirmButton variant="orange" onClick={closeModal}>
                        <FormattedMessage id="users:users-table:modal-contents:confirmation-modal:primary-button" />
                    </StyledConfirmButton>
                </ButtonsWrapper>
            )}

            {isAddUserConfirmationModal && (
                <>
                    {someUsersNotAdded && 
                        <>
                            <AddUserErrorMessage>
                                <FormattedMessage id="users:users-table:modal-contents:add-user:confirmation-modal:multiple-users-error" />
                            </AddUserErrorMessage>

                            <NotAddedUsersWrapper>
                                {someUsersNotAdded && usersNotAdded?.map((user, index) => {
                                    return (
                                        <React.Fragment key={`${user.emailAddress}${index}`}>
                                            <NotAddedUser>{user.emailAddress}</NotAddedUser>
                                            {index < usersNotAdded.length - 1 && <NotAddedUser>,&nbsp;</NotAddedUser>}
                                        </React.Fragment>
                                    );
                                })}
                            </NotAddedUsersWrapper>
                        </>
                    }

                    <ButtonsWrapper>
                        <StyledConfirmButton
                            variant="orange"
                            onClick={handleAddMoreUsersClick}
                        >
                            <FormattedMessage id="users:users-table:modal-contents:add-user:confirmation-modal:primary-button" />
                        </StyledConfirmButton>
                        <StyledCancelButton
                            onClick={handleAddUsersConfirmationModalClose}
                        >
                            <FormattedMessage id="users:users-table:modal-contents:confirmation-modal:primary-button" />
                        </StyledCancelButton>
                    </ButtonsWrapper>
                </>
            )}
        </ModalContentWrapperDiv>
    );
};

export default ConfirmationModalContent;
