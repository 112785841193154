import React from "react";
import {
    StatName,
    StatValue,
    StatsWrapper,
    StyledDivider,
} from "../../index.styles";
import { FormattedMessage } from "react-intl";

const BENEFITS_CATEGORIES = [
    "overview:benefits:cost-savings",
    "overview:benefits:c02-tonnes-reduced",
    "overview:benefits:railcars-of-coal-saved",
    "overview:benefits:trees-planted"
];

const EmptyState = () => {
    return (
        <>
            {BENEFITS_CATEGORIES.map((item, index) => {
                return (
                    <React.Fragment key={item}>
                        <StatsWrapper>
                            <StatName>
                                <FormattedMessage id={item} />
                            </StatName>
                            <StatValue>n/a</StatValue>
                        </StatsWrapper>
                        {index < BENEFITS_CATEGORIES.length -1 && <StyledDivider />}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default EmptyState;

