import styled from "styled-components";

type ScoreProps = {
  color: string;
};

type SectionProps = {
  space: number;
};

type ProgressOuterProps = {
  value: number;
  color: string;
};

type SizeProp = {
  size: number;
};

export const RadialChart = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `,

  Charts: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  `,
};

export const Legend = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.1rem;

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  `,

  Item: styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
  `,

  Section: styled.p<SectionProps>`
    display: flex;
    align-items: center;
    gap: ${({ space }) => space}rem;
  `,

  Circle: styled.div<ScoreProps>`
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${({ color }) => color};
  `,

  Name: styled.span``,

  Score: styled.span<ScoreProps>`
    color: ${({ color }) => color};
  `,

  Percentage: styled.span``,
};

export const Progress = {
  Container: styled.div<SizeProp>`
    width: ${({ size }) => size}rem;
    height: ${({ size }) => size}rem;
  `,
  Outer: styled.div<ProgressOuterProps>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: conic-gradient(
      ${({ color }) => color} ${({ value }) => (value / 100) * 360}deg,
      ${({ theme }) => theme.colors.neutral.grey[700]} 0
    );
  `,
  Inner: styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.legacy.gray[300]};

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  `,
  Score: styled.p<ScoreProps>`
    color: ${({ color }) => color};
    font-size: 1.5rem;
  `,
  Percentage: styled.p``,
};
