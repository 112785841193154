import styled from "styled-components";
import base from "../../../../themes/base/base";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const Wrapper = styled.div`
  display: flex;
`;  

export const TopStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.2;
  padding-left: 1.5rem;
  border-left: 1px solid ${base.colors.neutral.grey[900]};
`;

export const TooltipContent = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
    padding: 0.5rem;
`;

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#383838' 
    },
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 230,
      color: '#F2F2F2',
      fontSize: `1rem`,
      fontWeight: 500,
      lineHeight: `1.5rem`,
      background: '#383838'
    },
  });

export const StyledImg = styled.img`
  vertical-align: middle;
`;

export const StyledImgMargin = styled(StyledImg)`
  margin-top: 3px;
`;  





