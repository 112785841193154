import styled from "styled-components";

export const Passcode = {
  Container: styled.div`
    display: flex;
  `,
  Char: styled.span`
    border-radius: 0.3rem;
    background-color: ${({ theme }) => theme.colors.legacy.gray[250]};
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.4rem;
    font-size: 2rem;
    font-weight: 600;

    @media only screen and (max-width: 1023px) {
      width: 2rem;
      height: 2rem;
      border-radius: 0;
      font-size: 1.2rem;
    }
  `,
};
