import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;
`;

export const IconWrapper = styled.span`
    margin-right: 0.7rem;
`;

export const FAQ = styled.section`
    width: 50%;
    margin-right: 0.6rem;
`;

export const Contact = styled.section`
    width: 50%;
    margin-left: 0.6rem;
`;

export const Form = styled.form`
    margin-top: 1.2rem;
`;

export const FormRow = styled.div`
    margin-top: 0.8rem;
    display: flex;
    justify-content: ${({ align }) => align || 'flex-start'};
`;

export const Content = styled.div`
    background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
    padding: 1.3rem;
    border-radius: 0.3rem;  
`;

export const QuestionsContainer = styled.div`
    margin-top: -1rem;
`;

export const QuestionTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0.9rem 0;
    border-bottom: 1px solid  ${({ theme, open }) => open ? 'transparent' : theme.colors.neutral.grey[700]};
`;

export const QuestionList = styled.ul`
    list-style: none;
    padding-left: 1rem;
    margin: 0;
`;