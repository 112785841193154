import { ChartContainer, CustomColumnChart } from "./styles";
import { options, series } from './chart-config'
import { transformData } from "./utils";
import { cloneDeep } from "lodash";
import { chartDataSet } from "../../../../types/overview";
import { ProgressFilters } from "../../../../enums/overview";

const ColumnChart = ({data, filter, year}:{
    data: chartDataSet[]
    filter: string
    year: number | undefined
}) => {
    const optionsData = cloneDeep(options);
    const seriesData = cloneDeep(series);
    seriesData[0].data = transformData(data, filter, year);

    switch(filter){
        case ProgressFilters.Last7Days:
        case ProgressFilters.Month:    
        case ProgressFilters.Year:
            optionsData.xaxis.labels.formatter = function(val:string){
                return val.substring(0,2);
            }
            break;
        default: 
            break;
    }

    return (
       <ChartContainer>
            <CustomColumnChart  
                options={optionsData}
                series={seriesData}
                type="bar"
                height={378}
                className={filter === ProgressFilters.Month ? 'month' : ''}
            />
        </ChartContainer>
    )
};

export default ColumnChart;
