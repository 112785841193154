import React, { useState } from "react";
import {
    Container,
    Header,
    IconAndSubtitleWrapper,
    StatName,
    StatValue,
    StatsWrapper,
    StyledDivider,
    SubTitle,
    Title,
    TitleContainer
} from "../../index.styles";
import { FormattedMessage } from "react-intl";
import { ContentWrapper, LoaderContainer } from "./benefits.styles";
import { useQuery } from "react-query";
import { getOverviewCustomTopStats } from "../../../../api/overview";
import { applyDatePreset, convertBenefitsValues } from "./utils";
import Loader from "../../../../components/ui/loader";
import { useCallback } from "react";
import EmptyState from "./empty-state";
import { BenefitsPropTypes, BenefitsType, Value } from "../../../../types/overview";
import ContextFilter from "./context-filter";
import { CalendarIcon24 } from "../../../../components/icons";

const Benefits = ({ userId }: BenefitsPropTypes) => {

    const [selectedPreset, setSelectedPreset] = useState<string>("last7days");
    const {from, to, type} = applyDatePreset(selectedPreset);

    const {
        isFetching,
        data: customTopStats,
        isError
    } = useQuery<BenefitsType>(
        ["getOverviewCustomTopStats", from, to, userId],
        () => getOverviewCustomTopStats({ userId, from, to, type }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true
        }
    );

    const noBenefitsData = customTopStats?.value.length === 0 && !isError;

    const renderBenefitsComponent = useCallback(() => {
        if (isFetching) {
            return (
                <LoaderContainer>
                    <Loader size="large" />
                </LoaderContainer>
            );
        }

        if (isError || noBenefitsData) {
            return <EmptyState />;
        }

        return (
            <>
                {customTopStats?.value?.map((item: Value, index: number) => {
                    return (
                        <React.Fragment key={item.title}>
                            <StatsWrapper>
                                <StatName>{item.title}</StatName>
                                <StatValue>
                                    {convertBenefitsValues(
                                        item.result,
                                        item.unit,
                                        item.title
                                    )}
                                </StatValue>
                            </StatsWrapper>
                            {index < customTopStats.value.length - 1 && (
                                <StyledDivider />
                            )}
                        </React.Fragment>
                    );
                })}
            </>
        );
    }, [customTopStats, isFetching, noBenefitsData]);

    return (
        <Container>
            <Header>
                <TitleContainer>
                    <Title>
                        <FormattedMessage id="overview:benefits:title" />
                    </Title>
                    <ContextFilter selectedPreset={selectedPreset} onSelect={setSelectedPreset}/>
                </TitleContainer>
                <IconAndSubtitleWrapper>
                    <CalendarIcon24 fill="#999999" />
                    <SubTitle>
                        <FormattedMessage id={`overview:${selectedPreset}`} />
                    </SubTitle>
                </IconAndSubtitleWrapper>
            </Header>

            <ContentWrapper>
                {renderBenefitsComponent()}
            </ContentWrapper>
        </Container>
    );
};

export default Benefits;
