import styled from "styled-components";
import base from "../../themes/base/base";
import { HeatLogoForNavigation } from "../../components/icons";

export const StyledHeatLogo = styled(HeatLogoForNavigation)`
    width: 14.8rem;
    height: 4rem;
`; 

export const Container = styled.div`
    display: flex;
    width: 64.5rem;
    min-height: 36.3125rem;
    padding: 1.5rem 3rem;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    border-radius: 0.5rem;
    background-color: ${base.colors.neutral.grey[800]};
    max-height: 58rem;
`;

export const PageLayout = styled.div`
    height: 100%;
    width: 100%;
    background: url('/assets/heat-onboarding-background.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};  
    text-align: center;  
`;