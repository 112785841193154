import styled, { css } from "styled-components";
import Chart from "react-apexcharts";


export const Container = styled.div`
`;

export const Label = styled.label`
  display: flex;
  gap: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.00875rem;
  margin-right: 12px;
`;

export const RadioWrapper = styled.div`
  display: flex;

  .no-lookout{
    visibility: hidden;
  }

  .unchecked{
    font-weight: 400;
  }
`;

export const tooltipStyles = css`
  border-radius: 2px;
  padding: 10px 8px;
  background-color: #53555a;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  span {
    color: #eee;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
  }

  span.value-label{
    margin-right: 8px;
  }

  span.no-icon{
    margin-left: 28px;
  }

  span.hidden{
    display: none;
  }
  
  img {
    vertical-align: middle;
    margin: 0 5px 4px 0;
  }

  img.timeline {
    margin: 0px 8px 2px 4px;
  }
`;

export const CustomAreaChart = styled(Chart)`
  .apexcharts-tooltip {
    border: none !important;
    box-shadow: none !important;
  }

  .custom-tooltip {
    ${tooltipStyles}
  }
`;