import { ReactElement, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { sendContactForm } from "../../../api/support";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import Button from "../../../components/ui/button";
import Input from "../../../components/ui/input";
import { Form, FormRow, LoaderContainer, Message } from "./contact.styles";

import Loader from "../../../components/ui/loader";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

type Props = {

};


type FieldProps = {
    name: string,
    type: string,
    required: boolean,
    pattern?: RegExp,
    onChange?: (event: any) => void,
    [prop: string]: any
}

const Fields: FieldProps[] = [
    {
        name: 'userEmail',
        type: 'email',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        required: true,
    },
    {
        name: 'subject',
        type: 'text',
        required: true
    },
    {
        name: 'body',
        type: 'textarea',
        required: true
    }
];

const Field: React.FC<FieldProps> = (props: FieldProps): ReactElement | null => {
    const intl = useIntl();
    const { name, type } = props;

    switch (type) {
        case 'email':
        case 'text':
            return <Input {...props} autocomplete="false" placeholder={intl.formatMessage({ id: `contact-support-form:${name}` })} />
        case 'textarea':
            return <Input {...props} as="textarea" height="18rem" placeholder={intl.formatMessage({ id: `contact-support-form:${name}` })} />
    }

    return null;
}

const ActionMessage = ({ status }: { status: 'error' | 'success' }) =>
    <Message status={status}>
        <FormattedMessage id={`contact-support-form:${status}`} />
    </Message>

const Contact = (props: Props) => {
    const intl = useIntl();
    const [status, setStatus] = useState<'WAITING' | 'PENDING' | 'RESOLVED' | 'ERROR'>('WAITING');
    const [values, setValues] = useState<{ [prop: string]: string }>({});
    const [errors, setErrors] = useState<{ [prop: string]: string }>({});

    const getFieldError = (field: FieldProps, value: string) => {
        if (field.required && value === '') {
            return intl.formatMessage({ id: 'contact-support-form:field-required' });
        }

        if (field.pattern && !value.match(field.pattern)) {
            return intl.formatMessage({ id: 'contact-support-form:field-wrong-format' });
        }

        return null;
    };

    useEffect(() => {
        const isFinished = status === 'RESOLVED' || status === 'ERROR';
        if (isFinished) {
            const _t = setTimeout(() => {
                setStatus('WAITING');
                clearTimeout(_t);
            }, 6000);
        }
    }, [status]);

    const handleOnChange = useCallback((event: any) => {
        const name = event.target.name;
        const value = event.target.value;

        const field = Fields.filter((field: FieldProps) => name === field.name).pop();

        if (!field) return;

        const error = getFieldError(field, value);

        if (error) {
            setErrors({
                ...errors,
                [name]: error || undefined
            });
        } else {
            const prevErrors = { ...errors };
            if (name in prevErrors) {
                delete prevErrors[name];
            }
            setErrors(prevErrors);
        }

        setValues({
            ...values,
            [name]: value,
        });
    }, [errors, values]);

    const handleOnSubmit = useCallback(async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const errors = Fields.reduce((acc: { [prop: string]: string }, field: FieldProps) => {
            const value = values[field.name] || '';
            const error = getFieldError(field, value);

            if (error !== null) {
                acc[`${field.name}`] = error;
            }

            return acc;
        }, {});

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }

        try {
            setStatus('PENDING');
            const response = await sendContactForm(values);
            const withSuccess = response === true;

            setStatus(withSuccess ? 'RESOLVED' : 'ERROR');

            if (withSuccess) {
                setErrors({});
                setValues({});
            }

        } catch (err) {
            setStatus('ERROR');
        }
        return false;
    }, [values]);

    const isPending = status === 'PENDING';
    const isResolved = status === 'RESOLVED';
    const isError = status === 'ERROR';

    return <Form onSubmit={handleOnSubmit} noValidate autoComplete="off">
        {Fields.map((field) => <FormRow key={`field-${field.name}`}><Field value={values[field.name] || ''} error={errors[field.name]} {...field} onChange={handleOnChange} /></FormRow>)}
        <FormRow align="flex-end">
            <Button variant="orange" type="submit" disabled={isPending}>
                <FormattedMessage id={'support:send-email'} />
                {isPending && <LoaderContainer>
                    <Loader size="small" />
                </LoaderContainer>}
            </Button>
        </FormRow>
        {(isError || isResolved) && <FormRow align="flex-end">
            <ActionMessage status={isError ? 'error' : 'success'} />
        </FormRow>}
    </Form>
}

const ContactSupport = () =>
    <VRIntlProviderComponent localeFn={localeFn} id="contact" fallback={null}>
        <Contact />
    </VRIntlProviderComponent>

export default ContactSupport;