import styled, { css } from "styled-components";
import base from "../../../themes/base/base";
import {
    DropdownMenuStylingProps,
    MenuItemStylingProps
} from "./dropdown-menu.types";

export const DropdownMenuWrapperDiv = styled.div<DropdownMenuStylingProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: ${({ top }) => (top ? `${top}px` : 0)};
    right: ${({ right }) => (right ? `${right}px` : 0)};
    border: 1px solid ${base.colors.neutral.grey[300]};
    border-radius: 2px;
    background-color: ${base.colors.neutral.grey[800]};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    //no width needed, since MenuItem has a nowrap value for white-space
`;

export const PopUp = styled.div<{ width: string | number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: calc(100% + 0.5rem);
    right: 0;
    width: ${({ width }) => width};
    border: 1px solid ${base.colors.neutral.grey[300]};
    border-radius: 2px;
    background-color: ${base.colors.neutral.grey[800]};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 9999;
`;

export const MenuItem = styled.div<MenuItemStylingProps>`
    display: flex;
    width: 100%;
    white-space: nowrap;
    ${base.typography.smallRegular};
    padding: 0.65625rem 1.25rem 0.65625rem 0.75rem;

    ${({ isSelected }) =>
        isSelected &&
        css`
            padding: 0.5rem 1.25rem 0.5rem 0.75rem;
            ${base.typography.smallMedium};
            color: ${base.colors.primary.orange[500]};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${base.colors.neutral.grey[600]};
        color: ${base.colors.primary.orange[50]};
    }

    &:active {
        ${base.typography.smallMedium};
        padding: 0.65625rem 1.25rem 0.65625rem 0.75rem;
        color: ${base.colors.primary.orange[500]};
    }
`;

export const PopupItem = styled.div<{ isSelected: boolean }>`
    padding: 0.65625rem 1.25rem 0.65625rem 0.75rem;

    ${({ isSelected }) =>
        isSelected &&
        css`
            padding: 0.5rem 1.25rem 0.5rem 0.75rem;
            ${base.typography.smallMedium};
            color: ${base.colors.primary.orange[500]};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${base.colors.neutral.grey[600]};
    }
`;
