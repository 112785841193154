export enum Roles {
    Admin = 'Admin',
    Instructor = 'Instructor',
    Trainee = 'Trainee',
    Viewer = 'Viewer',
};

export const RoleOptions = [
    {id: 'Admin', label: 'Admin'},
    {id: 'Instructor', label: 'Instructor'},
    {id: 'Trainee', label: 'Trainee'}
];

export const StatusOptions = [
    {id: 'viewall', label: 'View All', value: 'all'},
    {id: 'active', label: 'Active', value: 'Active'},
    {id: 'pending', label: 'Pending', value: 'Pending'},
    {id: 'deactivated', label: 'Deactivated', value: 'Inactive'}
]