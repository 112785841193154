import styled from "styled-components";
import Text from "../ui/text";
import base from "../../themes/base/base";

export const EmptyStateWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.neutral.grey[800]};
    height: 100%;
`;

export const EmptyStateSecondaryWrapperDiv = styled.div`
    display: flex;
    margin: auto;
`;

export const EmptyStateContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};
    text-align: center;
    margin-top: 1.5rem;

    @media (min-width: 768px) and (max-width: 1919px) {
        ${base.typography.largeMedium};
    }
`;

export const EmptyStateImg = styled.img`
    width: 16rem;
    height: 16rem;

    @media (min-width: 768px) and (max-width: 1919px) {
        width: 12rem;
        height: 12rem;
    }
`;

export const Description = styled(Text)`
    ${base.typography.baseRegular};
    max-width: 28.5rem;

    @media (min-width: 768px) and (max-width: 1919px) {
        ${base.typography.smallRegular};
    }
`;