import { useQuery } from "react-query";
import { useTheme } from "styled-components";

import { getHighlights } from "../../../../api/sessions";
import { TargetIcon } from "../../../../components/icons";
import Loader from "../../../../components/ui/loader";

import { Bar, Card, Chart, Container } from "./highlights.style";

type Month = {
  index: number;
  key: string;
  value: number;
  text: string;
};

type Data = {
  code: string;
  title: string;
  description: string;
  datasets: Month[];
};

type Props = {};

export const Highlights = ({ }: Props) => {
  const theme = useTheme();

  const { data: receivedData, isLoading } = useQuery(
    ["getHighlights"],
    () => getHighlights(),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  if (isLoading) return <Loader />;

  return (
    <Container>
      {receivedData?.map((data: Data, index: number) => {

        const maxMoth = data.datasets.reduce((acc: number, cur: Month) => {
          if (acc < cur.value) acc = cur.value;
          return acc;
        }, 0);

        return (
          <Card.Wrapper key={`${data.code}-${index}`}>
            <Card.TitleContainer>
              <TargetIcon fill={theme.colors.semantic.green[500]} />
              <Card.Title>{data.title}</Card.Title>
            </Card.TitleContainer>
            <Card.Description>{data.description}</Card.Description>

            <Chart.Wrapper>
              {data.datasets.map((month: Month, i: number) => {
                return (
                  <Chart.Container key={`${data.code}-${index}-${i}`}>
                    <Chart.Stat>
                      <Chart.Value>{month.value}</Chart.Value>{" "}
                      <Chart.Description>{data.title}</Chart.Description>
                    </Chart.Stat>
                    <Bar progress={month.value * 100 / maxMoth} color={maxMoth <= month.value ? theme.colors.semantic.green[500] : theme.colors.neutral.grey[700]}>
                      {month.key}
                    </Bar>
                  </Chart.Container>
                )
              })}
            </Chart.Wrapper>
          </Card.Wrapper>
        )
      })}
    </Container>
  );
};
