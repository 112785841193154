import styled from "styled-components";
import base from "../../../themes/base/base";
import Button from "../../ui/button"

export const Container = styled.div`
  background-color: ${base.colors.neutral.grey[900]};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentAndBackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64.6525rem;
  height: 36.3125rem;
  background-image:url("/assets/error-page/ErrorLines.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${base.colors.neutral.grey[800]};
  border-radius: 8px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28.5rem;
`;

export const Title = styled.span`
  font-family: "Roboto";
  ${base.typography.extraLarge2Regular};
  color: ${base.colors.neutral.grey[50]};
  padding-bottom: 0.75rem;
  text-align: center;
`;

export const Description = styled.span`
  font-family: "Roboto";
  ${base.typography.baseRegular};
  color: ${base.colors.neutral.grey[50]};
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 1.5rem;
  width: 100%; //for the buttons to occupy 100% of width
`;

export const StyledRefreshButton = styled(Button)`
  ${base.typography.largeMedium};
  padding: 0.4375rem 1rem;
`;

export const StyledHomepageButton = styled(Button)`
  ${base.typography.largeMedium};
  padding: 0.4375rem 1rem;
`;

export const StyledLogoutButton = styled(Button)`
  ${base.typography.largeMedium};
  color: ${base.colors.primary.orange[500]};
  padding: 0.5rem 1rem;
  border: none;
`;
