import {
    ButtonText,
    OptionsBarWrapperDiv,
    StyledButton,
    StyledMultiSelectDropdown
} from "./options-bar.styles";
import { AddUserIcon24, MultipleFilter } from "../../../../components/icons";
import SearchBar from "../../../../components/ui/search-bar/search-bar";
import AddUserModalContent from "../../../../components/ui/modal-with-context/modal-contents/users-page/add-user/add-user-modal-content";
import { OptionsBarProps } from "./options-bar.types";
import { useModal } from "../../../../context/modal/modal";
import { FormattedMessage } from "react-intl";

const OptionsBar = ({
    searchInputValue,
    onInputChange,
    setShouldUpdateUsersList,
    onRoleChange,
    onStatusChange
}: OptionsBarProps) => {
    const { openModal, setWidth, setHeight } = useModal();

    const handleAddUserButtonClick = () => {
        setWidth(504);
        setHeight(504);
        openModal(
            <AddUserModalContent
                title="users:users-table:modal-contents:add-user:title"
                description="users:users-table:modal-contents:add-user:description"
                setShouldUpdateUsersList={setShouldUpdateUsersList}
            />
        );
    };

    return (
        <OptionsBarWrapperDiv>
            <StyledButton variant="orange" onClick={handleAddUserButtonClick}>
                <AddUserIcon24 fill="#121212" />
                <ButtonText>
                    <FormattedMessage id={"users:options-bar:button:text"} />
                </ButtonText>
            </StyledButton>
            <SearchBar
                disabled={false}
                debounceTimeout={400}
                placeholder="Search..."
                value={searchInputValue}
                onChange={onInputChange}
            />
            <StyledMultiSelectDropdown 
                onRoleChange={(option) => {onRoleChange(option)}} 
                title="Filters" 
                icon={<MultipleFilter />}
                onStatusChange={onStatusChange}
            />
        </OptionsBarWrapperDiv>
    );
};

export default OptionsBar;
