import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import { Container, PageLayout, StyledHeatLogo, TextWrapper } from "../onboarding-styles";
import { WelcomeDesc, WelcomeTitle } from "./survey.styles";
import MVPSurvey from "./mvp-survey";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getWelcomeSurvey } from "../../../api/user";
import Loader from "../../../components/ui/loader";

const localeFn = (target: string) => import(`./../locale/${target.toLowerCase()}.json`);

const OnboardingSurvey = () => {
    const history = useHistory();

    const {
        isFetching: isLoading,
        data: clientSurveyData,
    } = useQuery(["getWelcomeSurvey"],() => getWelcomeSurvey(),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
        }
    );
    //client survey questions exists in sections array
    const isClientSurvey = clientSurveyData?.sections.length > 0;
    if (isLoading) return <Loader />

    const handleOnContinue = () => {
        /* based on isClientSurvey, redirect to client survey*/
        history.push(`/success`);
    }; 

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="privacy-page" fallback={null}>
            <PageLayout>
                <Container>

                    <StyledHeatLogo />

                    <TextWrapper>
                        <WelcomeTitle><FormattedMessage id={'survey:welcome-title'} /></WelcomeTitle>
                        <WelcomeDesc><FormattedMessage id={'survey:welcome-desc'} values={{lineBreak: <br />}}/></WelcomeDesc>
                    </TextWrapper>

                    <MVPSurvey handleOnContinue={handleOnContinue} clientSurvey={isClientSurvey}/>
                    {/* We can add client survey here (if applicable) based on isClientSurvey flag*/}         

                </Container>
            </PageLayout>
       </VRIntlProviderComponent>     
    )
}

export default OnboardingSurvey;