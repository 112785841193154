import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  ResponsiveContainer
} from "recharts";
import { useTheme } from "styled-components";
import { CardLoader } from "../../../components/ui/loader";
import Styled from "../../../components/ui/styled";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import { CustomChartType } from "../../../types/custom-charts";
import { TopStatType } from "../../../types/top-stats";
import { convertMillisecondsToTimespan } from "../../../utils/functions";
import { Card, Container, GraphContainer } from "./top-stats.styles";

const HIDDEN_STATS = ["completed-scenarios"];

const parseValue = (
  value: number | string,
  measureType: string,
  displayType: string,
  unit?: string | null
) => {
  if (displayType === "Text") {
    return value;
  }

  if (displayType === "Time" && measureType === "Milliseconds") {
    return value === 0 ? "0m" : convertMillisecondsToTimespan(Number(value));
  }

  if (displayType === "Numeric" && unit === "m") {
    if (Number(value) > 999) {
      value = (Number(value) / 1000).toFixed(2) + "";
      return value;
    }
  }


  return typeof value === "number" && value !== 0
    ? value.toFixed(2).replace(/[.,]00$/, "")
    : 0;
};

type Props = {
  max?: number;
  data: TopStatType[];
  loading?: boolean;
};

const TopStats = ({ max = -1, data, loading = true }: Props) => {
  const { isFeatureActive } = useFeatureToggles();
  return (
    <>
      {loading && <CardLoader />}
      <Container>
        {data &&
          data
            .filter((stat: TopStatType, index: number) =>
              max === -1 ? true : index < max
            )
            .filter((stat: TopStatType) =>
              !isFeatureActive(Features.CompletedScenarios)
                ? !HIDDEN_STATS.includes(stat.code)
                : true
            )
            .map((stat: TopStatType, index: number) => {
              const limit: number = max > -1 ? max : data.length;
              return (
                <Item
                  key={`card-${index}-${stat.code}`}
                  fluid={index === limit - 1 && index % 2 === 0}
                  {...stat}
                />
              );
            })}
      </Container>
    </>
  );
};

const Item = ({ fluid, ...item }: TopStatType & { fluid: boolean }) => (
  <Card.Inner fluid={fluid}>
    <Card.Container>
      <Card.Title color={item.color}>
        {item.icon && <Icon name={item.icon} color={item.color} />}
        <FormattedMessage
          id={`dashboard:${item.code}`}
          defaultMessage={item.description}
        />
      </Card.Title>
      <Content {...item} />
    </Card.Container>
  </Card.Inner>
);

const Content = ({
  value,
  chart,
  currentMeasurementType,
  displayMeasurementType,
  presentationType = "value",
  unit
}: TopStatType) => {
 
  switch (presentationType) {
    case "chart":
      return (
        (chart && (
          <>
            <Card.Small>
              {chart.value?.description}:{" "}
              {parseValue(
                value,
                currentMeasurementType,
                displayMeasurementType,
                unit
              )}
            </Card.Small>
            <ContentChart {...chart} />
          </>
        )) ||
        null
      );
    case "value":
    default:
      return (
        <Card.Value>
          {parseValue(
            value,
            currentMeasurementType,
            displayMeasurementType,
            unit
          )}
          {unit && (
            <Card.Unit>
              {unit === "m" && Number(value) > 999 ? "km" : unit}
            </Card.Unit>
          )}
        </Card.Value>
      );
  }
};

const Icon = ({ name, color }: { name: string; color?: string }) => {
  const theme = useTheme();
  const Component = React.lazy(
    () => import(`./../../../components/icons/${name}`)
  );
  return (
    <ErrorBoundary fallback={<span></span>}>
      <React.Suspense fallback={<span></span>}>
        <Styled marginRight="0.5rem">
          <Component fill={color || theme.colors.neutral.grey[300]} />
        </Styled>
      </React.Suspense>
    </ErrorBoundary>
  );
};

const Dot = ({
  value,
  ...props
}: {
  cx?: number;
  cy?: number;
  value?: string | number;
  stroke: string;
  fill: string;
}) => (value && <circle r={3} {...props} />) || null;

const ContentChart = ({
  chartType,
  datasets,
  label,
  value
}: CustomChartType) => {
  const theme = useTheme();
  switch (chartType.toLowerCase()) {
    case "line":
      const fixedDataSet = datasets.map((d: any) => ({
        ...d
      }));
      return (
        <GraphContainer>
          <ResponsiveContainer>
            <LineChart data={fixedDataSet}>
              <Line
                type="linear"
                connectNulls={true}
                dataKey={value.code}
                stroke={theme.colors.neutral.grey[700]}
                dot={
                  <Dot
                    stroke={theme.colors.neutral.grey[700]}
                    fill={theme.colors.legacy.gray[300]}
                  />
                }
              />
            </LineChart>
          </ResponsiveContainer>
        </GraphContainer>
      );
    case "bar":
      return (
        <GraphContainer>
          <ResponsiveContainer>
            <BarChart data={datasets}>
              <Bar
                dataKey={value.code}
                fill={theme.colors.neutral.grey[700]}
                rx={"1rem"}
              >
                {datasets.map((entry, index) => (
                  <Cell key={`cell-${index}`} radius={2} width={6} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </GraphContainer>
      );
    default:
      return null;
  }
};

export default TopStats;
