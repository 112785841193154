import { User } from "../../users.types";

export interface UsersTableProps {
    usersList: User[] | null;
    lastUserInRef: (instance: HTMLTableRowElement | null) => void;
    isFetching: boolean;
    isError: boolean;
    setShouldUpdateUsersList: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum ActionMenuOption {
    ChangeRole = "Change Role",
    DeactivateUser = "Deactivate User",
    ReactivateUser = "Reactivate User",
    ResendInvite = "Resend Invite"
}

export enum UserStatus {
    Active = "Active",
    Pending = "Pending",
    Deactivated = "Deactivated"
}

export enum UserStatusAction {
    Activate = "Activate",
    Deactivate = "Deactivate",
    Reactivate = "Reactivate"
}

export enum UserStatusActionNoun {
    Activation = "activation",
    Deactivation = "deactivation"
}

export enum UserStatusPerformedAction {
    Activated = "activated",
    Deactivated = "deactivated"
}
