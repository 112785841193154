import styled from "styled-components";
import base from "../../themes/base/base";
import { desktopLargeMedia } from "../../themes/breakpoints";

export const Wrapper = styled.div`
  position: fixed;
  height: var(--heat-header-height);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: ${base.colors.neutral.grey[800]};
  padding-left: var(--navigation-width);
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;

  ${desktopLargeMedia} {
    padding: 1.5rem;
  }

  & > svg:first-child {
    margin-right: 0.75rem; 
  }
`;

export const LogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #3F3F3F;
  }
`;