import styled from "styled-components";

export const FiltersContainer = styled.div`
    display: flex;
    gap: 0.625rem;
    justify-content: flex-end;
    position: absolute;
    top: 1.75rem;
    
    @media (max-width: 1023px) {
        top: 1.25rem;
    }

    @media (min-width: 1024px) and (max-width: 1919px) {
        top: 1rem;
    }
`;
