import moment from "moment";
import { ProgressFilterValues, ProgressFilters } from "../../enums/overview";

export const useProgressDatePreset = (key: string, month?: number, year?: number) => {
    let value: number = ProgressFilterValues[key];
    let from: Date | null = new Date();
    let selectedYear: number | null = null;

    let momentFrom: moment.Moment | null = null;
    let momentTo: moment.Moment | null = moment(from);
  
    switch (key) {
      case ProgressFilters.Last7Days:
        from.setDate(from.getDate() - Math.abs(value));
        momentFrom = moment(from);
        break;
      case ProgressFilters.Month:
        from.setMonth(month || from.getMonth());
        from.setFullYear(year || from.getFullYear());
        momentFrom = moment(from).startOf('month');
        momentTo = moment(from).endOf('month');
        break;
      case ProgressFilters.Year:
        from.setFullYear(year || from.getFullYear());
        selectedYear = moment(from).year();
        momentTo = null;
        break;
      case ProgressFilters.Lifetime:
        momentFrom = null;
        momentTo = null;
        break;
    }

    return { 
        from: momentFrom ? momentFrom.format("YYYY-MM-DD") : null,
        to: momentTo ? momentTo.format("YYYY-MM-DD") : null,
        type: key,
        year: selectedYear
    };
  };