import styled from "styled-components";
import Text from "../../components/ui/text";

const regularBackground = '/assets/promo-page/background/promo-background2x.png';
const wideBackground = '/assets/promo-page/background/promo-background-wide.png';
const extraLargeBackground = '/assets/promo-page/background/promo-background3840x2160_x2.png';

const wideBackgroundQuery = '@media (max-width: 1365px)';
const regularBackgroundQuery = '@media (min-width: 1366px) and (max-width: 2560px)';
const extraLargeBackgroundQuery = '@media (min-width: 2561px)';

const mobileMediaQuery = '@media (max-width: 1132px)';
const tabletMediaQuery = '@media (min-width: 1133px) and (max-width: 2559px)';
const largeMediaQuery = '@media (min-width: 2560px) and (max-width: 3839px)';
const extraLargeMediaQuery = '@media (min-width: 3840px)';

export const PromoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mobileMediaQuery} {
    gap: 0.5rem;
  }

  ${tabletMediaQuery} {
    gap: 1.5rem;
  }

  ${largeMediaQuery} {
    gap: 1.5rem;
  }

  ${extraLargeMediaQuery} {
    gap: 2rem;
  }

  ${wideBackgroundQuery} {
    width: 100%;
    height: 100%;
    background-image:url(${wideBackground});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  ${regularBackgroundQuery} {
    width: 100%;
    height: 100%;
    background-image:url(${regularBackground});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  ${extraLargeBackgroundQuery} {
    width: 100%;
    height: 100%;
    background-image:url(${extraLargeBackground});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
`;

export const StyledAmpersandText = styled(Text)`
  ${mobileMediaQuery} {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.0075rem;
    text-align: center;
  }

  ${tabletMediaQuery} {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: 0.02rem;
    text-align: center;
  }

  ${largeMediaQuery} {
    font-size: 4rem;
    font-weight: 700; 
    line-height: 4rem;
    letter-spacing: 0.04rem;
    text-align: center;
  }

  ${extraLargeMediaQuery} {
    font-size: 6rem;
    font-weight: 700; 
    line-height: 6rem;
    letter-spacing: 0.6rem;
    text-align: center;
  }
`;

export const StyledMainText = styled(Text)`
  white-space: pre-wrap;
  
  ${mobileMediaQuery} {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    text-align: center;
    width: 10.13rem;
  }

  ${tabletMediaQuery} {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.125rem;
    letter-spacing: 0.025rem;
    text-align: center;
    width: 25.25rem;
  }

  ${largeMediaQuery} {
    font-size: 8rem;
    font-weight: 700; 
    line-height: 9.625rem;
    letter-spacing: 0.08rem;
    text-align: center;
    width: 80.81rem;
  }

  ${extraLargeMediaQuery} {
    font-size: 12rem;
    font-weight: 700; 
    line-height: 14.375rem;
    letter-spacing: 0.12rem;
    text-align: center;
    width: 121.25rem;
  }
`;

export const StyledScoreText = styled(Text)`
  ${mobileMediaQuery} {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 2.5rem;
    letter-spacing: 0.025rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.orange[500]};
  }

  ${tabletMediaQuery} {
    font-size: 8rem;
    font-weight: 300;
    line-height: 8rem;
    letter-spacing: 0.08rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.orange[500]};
  }

  ${largeMediaQuery} {
    font-size: 18rem;
    font-weight: 300; 
    line-height: 18rem;
    letter-spacing: 0.18rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.orange[500]};
  }

  ${extraLargeMediaQuery} {
    font-size: 27rem;
    font-weight: 300; 
    line-height: 27rem;
    letter-spacing: 0.27rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.orange[500]};
  }
`;

export const StyledSecondaryText = styled(Text)`
  ${mobileMediaQuery} {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.0075rem;
    text-align: center;
  }

  ${tabletMediaQuery} {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: 0.02rem;
    text-align: center;
  }

  ${largeMediaQuery} {
    font-size: 6rem;
    font-weight: 700; 
    line-height: 6rem;
    letter-spacing: 0.06rem;
    text-align: center;
  }

  ${extraLargeMediaQuery} {
    font-size: 9rem;
    font-weight: 700; 
    line-height: 9rem;
    letter-spacing: 0.09rem;
    text-align: center;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mobileMediaQuery} {
    gap: 0.75rem;
  }

  ${tabletMediaQuery} {
    gap: 1.5rem;
  }

  ${largeMediaQuery} {
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }

  ${extraLargeMediaQuery} {
    gap: 3rem;
  }
`;

export const StyledVBSLogoImg = styled.img`
  ${mobileMediaQuery} {
    width: 1.875rem;
  }

  ${tabletMediaQuery} {
    width: 5rem;
  }

  ${largeMediaQuery} {
    width: 6.25rem;
  }

  ${extraLargeMediaQuery} {
    width: 9.375rem;
  }
`;

export const StyledHEATLogoImg = styled.img`
  ${mobileMediaQuery} {
    width: 7.223rem;
  }

  ${tabletMediaQuery} {
    width: 19.259rem;
  }

  ${largeMediaQuery} {
    width: 24.09rem;
  }

  ${extraLargeMediaQuery} {
    width: 36.135rem;
  }
`;

export const StyledVRAILogoImg = styled.img`
  ${mobileMediaQuery} {
    width: 2.981rem;
    margin: 0.828rem 0;
  }

  ${tabletMediaQuery} {
    width: 5.961rem;
    margin: 1.5rem 0;
  }

  ${largeMediaQuery} {
    width: 10.625rem;
    margin: 3rem 0;
  }

  ${extraLargeMediaQuery} {
    width: 15.938rem;
    margin: 4.5rem 0;
  }
`;

export const VRAILogoWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
