import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import {
    PopUp,
    PopUpItem,
    SortingFiltersWrapperDiv,
    TextDiv
} from "./sorting-filters.styles";
import {
    SortingFilterEnum,
    SortingFiltersProps
} from "./sorting-filters.types";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
    ChevronDownSquareCorners,
    SortDashesIcon24,
    SortDashesIcon32
} from "../../icons";
import { FiltersList } from "../../../enums/dashboard";
import useMediaQuery from "../../../utils/hooks/use-media-query";

const SortingFilters = ({
    isOpen,
    handleOnClick,
    onSortingChange,
    sortingOption
}: SortingFiltersProps) => {
    const isMobileAndTablet = useMediaQuery("(max-width: 1023px)");

    const sortingOptionText =
        sortingOption === SortingFilterEnum.LeastRecent
            ? "sorting-filters:least-recent"
            : "sorting-filters:most-recent";

    const onClose = useCallback(() => {
        if (!isOpen) return;
        handleOnClick(FiltersList.None);
    }, [isOpen]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: onClose
    });

    const handleToggleList = useCallback(
        (event: any) => {
            event.stopPropagation();
            handleOnClick(isOpen ? null : FiltersList.OrderBy);
        },
        [isOpen, handleOnClick]
    );

    const handleOptionClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const sortingOptionClicked = event.currentTarget.id; //ascending or descending
            event.stopPropagation();
            onSortingChange(sortingOptionClicked);
            handleOnClick(null);
        },
        [onSortingChange]
    );

    return (
        <>
            <SortingFiltersWrapperDiv
                onClick={handleToggleList}
                ref={clickOutsideRef}
            >
                {isMobileAndTablet && <SortDashesIcon32 />}
                {!isMobileAndTablet && (
                    <>
                        <SortDashesIcon24 />
                        <TextDiv>
                            <FormattedMessage id={sortingOptionText} />
                        </TextDiv>
                        <ChevronDownSquareCorners />
                    </>
                )}
            </SortingFiltersWrapperDiv>
            {isOpen && (
                <PopUp width={"174.95px"}>
                    <PopUpItem
                        id="descending"
                        isSelected={
                            sortingOption === SortingFilterEnum.MostRecent
                        }
                        onClick={handleOptionClick}
                    >
                        <FormattedMessage id="sorting-filters:most-recent" />
                    </PopUpItem>
                    <PopUpItem
                        id="ascending"
                        isSelected={
                            sortingOption === SortingFilterEnum.LeastRecent
                        }
                        onClick={handleOptionClick}
                    >
                        <FormattedMessage id="sorting-filters:least-recent" />
                    </PopUpItem>
                </PopUp>
            )}
        </>
    );
};

export default SortingFilters;
