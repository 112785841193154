import {
    ChangeEvent,
    useEffect,
    useRef,
    useState,
    useCallback
} from "react";
import { useIntl } from "react-intl";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import { useQuery } from "react-query";
import { UsersData, User } from "./users.types";
import UsersTable from "./components/users-table/users-table";
import { getUsers } from "../../api/users";
import OptionsBar from "./components/options-bar/options-bar";
import { Option } from "./components/users-filter";

const localeFn = (target: string) =>
    import(`./locale/${target.toLowerCase()}.json`);

const Users = () => {
    const intl = useIntl();
    const pageTitle = intl.formatMessage({ id: "users:page-name" });
    const [skipToken, setSkipToken] = useState<string | null | undefined>(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState<string | null>();
    const [shouldUpdateUsersList, setShouldUpdateUsersList] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<Option[]>();
    const [refreshUserData, setRefreshUserData] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<string>();
    const observer = useRef<IntersectionObserver>();

    const {
        isFetching,
        data: usersData,
        isError,
        refetch
    } = useQuery<UsersData>(
        ["getUsers", skipToken, searchInputValue, selectedRole, selectedStatus],
        () => getUsers(skipToken, searchInputValue, selectedRole, selectedStatus),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true
        }
    );

    const [usersList, setUsersList] = useState<User[] | null>(
        usersData?.items || []
    );

    //this node is the (our last element) element in users-table that receives the ref={lastUserInRef}
    const lastUserInRef = useCallback(
        (node: HTMLTableRowElement | null) => {
            if (isFetching) return;

            //disconnect observer from the last element, if there is any last element
            if (observer.current) {
                observer.current.disconnect();
            }

            //all the visible entries that are available
            observer.current = new IntersectionObserver((entries) => {
                //we're only ever observing one single element -> [0]
                if (entries[0].isIntersecting && hasNextPage) {
                    setSkipToken(usersData?.skipToken);
                }
            });

            //if there's a last element, make sure the observer observes it
            if (node) {
                observer.current.observe(node);
            }
        },
        [isFetching, hasNextPage]
    );

    //when we have a new search, the skipToken must ALWAYS be null
    useEffect(() => {
        setSkipToken(null);
        setUsersList([]);
        refetch();
    }, [shouldUpdateUsersList, searchInputValue, selectedRole, selectedStatus]);

    //initial populating the list | or when usersData changes
    useEffect(() => {
        if (usersData) {
            if(refreshUserData) {
                setUsersList(usersData.items);
                setRefreshUserData(false);
            } else {
                setUsersList((prevUsersList) => {
                    const newUsersList: User[] = prevUsersList ? [...prevUsersList] : [];
                    if(prevUsersList !== null) {
                        usersData.items.forEach(item => {
                            if(!newUsersList.some(user => user.userId === item.userId)) {
                                newUsersList.push(item);
                            }
                        })
                    }
                    return newUsersList;
                });
            }
            setHasNextPage(usersData.hasNextPage);
        }
    }, [usersData]);

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(event.target.value);
    };
  
    return (
        <Page
            title={pageTitle}
            counter={usersList?.length}
            rightContent={
                <OptionsBar
                    searchInputValue={searchInputValue || ""}
                    onInputChange={handleSearchInputChange}
                    setShouldUpdateUsersList={setShouldUpdateUsersList}
                    onRoleChange={(roles) => {
                        setSelectedRole(roles);
                        setRefreshUserData(true);
                    }}
                    onStatusChange={(status) => {
                        setSelectedStatus(status);
                        setRefreshUserData(true);
                    }}
                />
            }
        >
            <UsersTable
                usersList={usersList}
                lastUserInRef={lastUserInRef}
                isFetching={isFetching}
                isError={isError}
                setShouldUpdateUsersList={setShouldUpdateUsersList}
            />
        </Page>
    );
};

const UsersPage = () => {
    return (
        <VRIntlProviderComponent
            localeFn={localeFn}
            id="trainees-page"
            fallback={<PageLoader />}
        >
            <Users />
        </VRIntlProviderComponent>
    );
};

export default UsersPage;
