import { IconProps } from "../../../types/icon";

const DotsVerticalIcon32 = ({
    width = 32,
    height = 32,
    fill = "#F2F2F2",
    ...remainingProps
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        {...remainingProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 11C14.8954 11 14 10.1046 14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9C18 10.1046 17.1046 11 16 11ZM16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16C18 17.1046 17.1046 18 16 18ZM14 23C14 24.1046 14.8954 25 16 25C17.1046 25 18 24.1046 18 23C18 21.8954 17.1046 21 16 21C14.8954 21 14 21.8954 14 23Z"
            fill={fill}
        />
    </svg>
);

export default DotsVerticalIcon32;
