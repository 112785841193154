import React, { useContext, useState } from "react";
import { ReactNode, createContext } from "react";

interface ModalContextType {
    isModalOpen: boolean;
    openModal: (children: ReactNode) => void;
    closeModal: () => void;
    modalContent: ReactNode | null;
    width: number;
    height: number;
    setWidth: (width: number) => void;
    setHeight: (height: number) => void;
    minWidth: number;
    minHeight: number;
    setMinWidth: (width: number) => void;
    setMinHeight: (height: number) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
    children
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [width, setWidth] = useState<number>(1032);
    const [height, setHeight] = useState<number>(581);
    const [minHeight, setMinHeight] = useState<number>(0);
    const [minWidth, setMinWidth] = useState<number>(0);

    const openModal = (content: ReactNode) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    return (
        <ModalContext.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
                modalContent,
                width,
                height,
                setWidth,
                setHeight,
                minWidth,
                minHeight,
                setMinWidth,
                setMinHeight,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalContextType => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("useModal hook must be used within a ModalProvider");
    }

    return context;
};
