import styled from "styled-components";

export const Container = styled.div`
    text-align: left;
    width: 100%;
`;

type WrapperProps = {
    height: string | number,
    type: string,
    focus: boolean,
    error: string | null | undefined,
    opened: boolean,
}

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: ${({ type, height }) => type === 'textarea' ? height : '3.5rem'};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
    border-radius: ${({ opened }) => opened ? '0.3rem 0.3rem 0 0' : '0.3rem'};
    padding: 0 0.7rem;
    box-sizing: border-box;
    padding-top: ${({ type }) => type === 'textarea' ? '0.4rem' : 0};
    position: relative;
    
    cursor: pointer;

    ${({ focus, error, theme }) => focus && !error && `box-shadow: 0 0 0px 2px ${theme.colors.legacy.gray[400]}, 0 0 0px 3px ${theme.colors.primary.orange[500]}`}
    ${({ error, theme }) => error && `box-shadow: 0 0 0px 2px ${theme.colors.legacy.gray[400]}, 0 0 0px 3px ${theme.colors.legacy.pink[400]}`}
`;

export const IconContainer = styled.span`
    position: absolute;
    right: 1rem;
`;

export const Label = styled.span<{
    focus: boolean,
    error: string | null | undefined,
}>`
    margin-top: 0.2rem;
    padding-right: 2rem;
    ${({ focus, error, theme }) => focus && !error && `color:${theme.colors.primary.orange[500]}`}
`;

export const Value = styled.div<{ hasValue: boolean }>`
    font-family: ${({ theme }) => theme.fonts.main};
    font-weight: ${({ theme }) => theme.typography.sMedium.fontWeight};
    font-size: ${({ theme }) => theme.typography.sMedium.fontSize};
    color: ${({ theme, hasValue }) => theme.colors.legacy.gray[hasValue ? 50 : 100]};
    font-weight: ${({ hasValue }) => hasValue ? 700 : 500};
    background-color: transparent;
    border: none;
    outline: none;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 98;
    padding-right: 2rem;
`;

export const Error = styled.span`
    display: inline-block;
    color: ${({ theme }) => theme.colors.legacy.pink[400]};
    margin-top: 0.3rem;
`;

export const List = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    font-size: 0.9rem;
    z-index: 99;
`;

export const Item = styled.li<{ selected: boolean }>`
    background-color: ${({ theme, selected }) => selected ? theme.colors.primary.orange[500] : theme.colors.legacy.gray[400]};
    color: ${({ theme, selected }) => theme.colors.legacy.gray[selected ? 500 : 100]};
    padding: 1rem 1rem;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme, selected }) => selected ? theme.colors.legacy.yellow[300] : theme.colors.legacy.gray[300]};
        color: ${({ theme, selected }) => theme.colors.legacy.gray[selected ? 500 : 100]};
    }


`;