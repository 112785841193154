import useQueryParams from "../../../../utils/use-query-params";
import { getCombinedCharts, getCustomCharts } from "../../../../api/sessions";
import { Score } from ".";
import { useEffect, useState } from "react";

export const usePolling = (poll: number) => {
    const params: any = useQueryParams();
    let userId = params.get("userId");
    let from: string = params.get("from");
    let to: string = params.get("to");
    let sessionId: string = params.get("sessionId");
    let simulationInstanceId: string = params.get("simulationInstanceId");
    let scenarioInstanceId: string = params.get("scenarioInstanceId");

    const [landingScore, setLandingScore] = useState<Score>();
    const [breakdownData, setBreakdownData] = useState<Score[]>();

    useEffect(() => {
        async function getData() {
            const data = await getCustomCharts({ from, to, sessionId, userId, simulationInstanceId, scenarioInstanceId });
            const combinedData = await getCombinedCharts({ from, to, sessionId, userId, simulationInstanceId, scenarioInstanceId });
            setLandingScore(data?.filter((item:any) => item.chartType === "ProgressBar")[0]?.datasets?.[0]);
            setBreakdownData(combinedData?.find((chart: any) => chart.chartType === 'ProgressBar')?.datasets);
        }
        getData();
    }, [poll]);

    return {
        landingScore, breakdownData, simulationInstanceId
    };
}