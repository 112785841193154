import moment from "moment";
import { ProgressFilters } from "../../../../enums/overview";
import { convertMillisecondsToTimespan } from "../../../../utils/functions";

export const transformCategories = (day: any, filter: string, year: number | undefined) => {
    switch(filter){
      case ProgressFilters.Last7Days:
      case ProgressFilters.Month:          
        return moment(day).format("DD/MM/YY");
      case ProgressFilters.Lifetime: 
        return day
      case ProgressFilters.Year: 
        return `${day}/${year}`
    }
};

export const transformData = (data: any, filter: string, year: number | undefined) => {
    const score = data.flatMap((dataSet: any)=> {
      return { 
          x: transformCategories(dataSet.day, filter, year),
          y: dataSet.score, 
          sessions: dataSet.numberOfSessions,
          avgSessionTime: convertMillisecondsToTimespan(dataSet.averageSessionTime) }
      });

    return score;
};


