import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ContextFilterPopUp, Toggle, Preset, PresetsContainer, PresetsList, Link, FilterContainer } from "./benefits.styles";
import { ContextFilterPresetValues } from "../../../../enums/overview";
import { useDetectClickOutside } from "react-detect-click-outside";
import { CalendarIcon24, ChevronDownSquareCorners } from "../../../../components/icons";

const ContextFilter = ({
    selectedPreset,
    onSelect
}: {
    selectedPreset: string;
    onSelect: (selectedPreset: string) => void;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleList = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handlePresetSelection = useCallback((filter: string) => {
        setIsOpen(false);
        onSelect(filter);
    }, [selectedPreset]);

    const onClose = useCallback(() => {
        if (isOpen) {
          setIsOpen(false);
        }
      }, [isOpen]);

    const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });

    return (
        <FilterContainer ref={clickOutsideRef}>
            <Toggle onClick={handleToggleList}>
                <CalendarIcon24/>
                {selectedPreset && (
                    <FormattedMessage id={`overview:${selectedPreset}`} />
                )}
                <ChevronDownSquareCorners />
            </Toggle>
            {isOpen && 
                <ContextFilterPopUp width={"auto"}>
                    <PresetsContainer>
                    <PresetsList>
                        {Object.keys(ContextFilterPresetValues).map((k: string) => (
                        <Preset key={`preset-${k}`}>
                            <Link selected={k === selectedPreset} onClick={() => handlePresetSelection(k)}>
                            <FormattedMessage id={`overview:${k}`} />
                            </Link>
                        </Preset>
                        ))}
                    </PresetsList>
                    </PresetsContainer>
                </ContextFilterPopUp>
            }
        </FilterContainer>
    )
}

export default ContextFilter;