import React from "react";
import { FormattedMessage } from "react-intl";
import { EmptyStateContent, EmptyStateImg, EmptyStateSecondaryWrapperDiv, EmptyStateWrapperDiv, Description, Title } from "./empty-state.styles";
import { EmptyStateProps } from "./empty-state.types";

const EmptyState = ({
    title,
    description
}: EmptyStateProps) => {
    return (
        <EmptyStateWrapperDiv>
            <EmptyStateSecondaryWrapperDiv>
                <EmptyStateContent>
                    <EmptyStateImg
                        src={"assets/empty-state/emptySessionsState.png"}
                    />
                    <Title>
                        <FormattedMessage id={title} />
                    </Title>
                    <Description variant="baseRegular" align="center" marginTop="0.75rem">
                        <FormattedMessage id={description} />
                    </Description>
                </EmptyStateContent>
            </EmptyStateSecondaryWrapperDiv>
        </EmptyStateWrapperDiv>
    );
};

export default EmptyState;
