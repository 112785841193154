import styled from "styled-components";


export const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const Label = styled.div`
    margin-bottom: 0.6rem;
`;

export const Container = styled.div`
    display: inline-block;
    width: 85%;
    position: relative;
    padding-top: 1rem;
    height: 4.1rem;
    float: left;
`;

export const Value = styled.div`
    display: flex;
    justify-content: space-between;
    width: 15%;
    padding-top: 3.5rem;
    box-sizing: border-box;
    padding-left: 0.4rem;  
`;

export const Percentage = styled.span`
    display: inline-block;
    width: 70%;
    background-color: ${({ theme }) => theme.colors.legacy.gray[500]};
    text-align: center;
    margin-right: 0.1rem;
`;

export const Slider = styled.input.attrs({
    type: 'range',
    min: 0,
    max: 100,
})`
    &[type=range] {
        -webkit-appearance: none;
        margin: 18px 0;
        width: 100%;
        outline: none;
    }
    &[type=range]:focus {
        
    }
    &[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: ${({ theme }) => theme.colors.legacy.yellow[300]};
        border-radius: 2px;
    }
    &[type=range]::-webkit-slider-thumb {
        height: 24px;
        width: 24px;
        background: ${({ theme }) => theme.colors.primary.orange[500]};
        cursor: pointer;
        -webkit-appearance: none;
        border-radius: 50%;
        margin-top: -11px;
    }
    &[type=range]:focus::-webkit-slider-runnable-track {
        background: ${({ theme }) => theme.colors.primary.orange[500]};
    }
    &[type=range]::-moz-range-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        background: ${({ theme }) => theme.colors.legacy.yellow[300]};
    }
    &[type=range]::-moz-range-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
    }
    &[type=range]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }
    &[type=range]::-ms-fill-lower {
        background: #2a6495;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    &[type=range]::-ms-fill-upper {
        background: ${({ theme }) => theme.colors.legacy.yellow[300]};
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    }
    &[type=range]::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
    }
    &[type=range]:focus::-ms-fill-lower {
        background: ${({ theme }) => theme.colors.legacy.yellow[300]};
    }
    &[type=range]:focus::-ms-fill-upper {
        background: ${({ theme }) => theme.colors.primary.orange[500]};
    }
`;
export const Divisions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
`;

export const Division = styled.span`
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.legacy.gray[150]};
    transform: translateX(0.2rem);
`;

export const LabelBefore = styled.span`
    position: absolute;
    top: 0;
    left: 0.2rem;
    font-size: 0.75rem;
`;
export const LabelAfter = styled.span`
    position: absolute;
    top: 0;
    right: 0.2rem;
    font-size: 0.75rem;
`;