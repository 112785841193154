import styled from 'styled-components';
import base from "../../../../themes/base/base";

export const Wrapper = styled.div<{ bottomGap: boolean, borderTopRadius: boolean, borderBottomRadius: boolean }>`
  padding: 1.5rem;
  background-color: ${base.colors.neutral.grey[800]};
  justify-content: center;
  margin-bottom: ${({ bottomGap }) => (bottomGap ? "1rem" : 0)};
  border-bottom-left-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
  border-bottom-right-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
  border-top-right-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
  border-top-left-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};

  margin-left: 24px;
`;