import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import { ButtonWrapper, Statement } from "./privacy.styles"
import { Container, PageLayout, StyledHeatLogo, TextWrapper, Title } from "../onboarding-styles";
import { useMsal } from "@azure/msal-react";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../context/user";
import Loader from "../../../components/ui/loader";
import { useQuery } from "react-query";
import { dataPrivacyReview, getDataPrivacy } from "../../../api/onboarding";
import { PrimaryButton, TertiaryButton } from "../../shared/shared.styles";

const localeFn = (target: string) => import(`./../locale/${target.toLowerCase()}.json`);

const PrivacyStatement = () => {
    const history = useHistory();
    const { instance } = useMsal();
    const { state: { registrationCompleted} } = useUser();

    const [loading, setLoading] = useState({
       accept: false,
       decline: false
    });

    const { isFetching: isLoading, data: dataPrivacy } = useQuery(["getDataPrivacy"],
        () => getDataPrivacy(),{ suspense: false, refetchOnMount: false, useErrorBoundary: true }
    );

    const handleLogOut = useCallback(async () => {
        setLoading(prev => ({
            ...prev,
            decline: true
        }));

        await dataPrivacyReview(dataPrivacy?.version, false);

        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }, [instance]);

    const handleOnAccept = async () => {

        setLoading(prev => ({
            ...prev,
            accept: true
        }));

        await dataPrivacyReview(dataPrivacy?.version, true);

        if(!registrationCompleted)
            history.push(`/welcome`);
        else
          history.push(`/overview`);
    }; 

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="privacy-page" fallback={null}>
            <PageLayout>

                <Container>
                    <StyledHeatLogo />
                    
                    {isLoading ? <Loader/> : <>
                        <TextWrapper>
                            <Title><FormattedMessage id={'privacy:title'} /></Title>
                            <Statement>{dataPrivacy?.fileContent}</Statement>
                        </TextWrapper>

                        <ButtonWrapper>
                            <PrimaryButton onClick={handleOnAccept} disabled={loading.accept}>
                                {loading.accept ? <Loader size="medium" color="#666666" fontSize="1.5rem"/> : 
                                    <FormattedMessage id={'privacy:accept'} />
                                }
                            </PrimaryButton>

                            <TertiaryButton onClick={handleLogOut}>
                                {loading.decline ? <Loader size="medium" color="#EE7127" fontSize="1.5rem"/> : 
                                    <FormattedMessage id={'privacy:decline'} />
                                }
                            </TertiaryButton>
                        </ButtonWrapper>
                    </>
                    }
                </Container>

            </PageLayout>
       </VRIntlProviderComponent>     
    )
}

export default PrivacyStatement;