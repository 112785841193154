import styled from "styled-components";

export const DayContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Day = styled.div`
  padding: 0.3rem 2rem 0 0;
  flex: 0.1;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
`;

export const ListContainer = styled.div`
  flex: 0.9;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
`;
