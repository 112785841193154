import { FormattedMessage, useIntl } from "react-intl";

import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Text from "../../components/ui/text";
import DisplayName from "./steps/display-name";

import Button from "../../components/ui/button";
import Loader from "../../components/ui/loader";
import Styled from "../../components/ui/styled";
import Controller from "./controler";
import { useWelcomeSurveyData, WelcomeSurveyProvider } from "./state";
import Done from "./steps/done";
import FormStep from "./steps/form-step";
import { Container, ContainerCenter, LeftColumn, Logo, RightColumn } from "./welcome-screen.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

type Props = {
    onComplete: () => void
}

const Form = ({ onComplete }: Props) => {
    const { state: { status, fields, currentStep, error } } = useWelcomeSurveyData();
    const intl = useIntl();

    if (!fields) return null;

    const isPending = status === 'PENDING';
    const isWaiting = status === 'WAITING';
    const isCompleted = status === 'COMPLETED';
    const isFirstStep = currentStep === 0;

    const title = isFirstStep ? 'Tell us about yourself!' : fields.sections[currentStep - 1].title;

    if (!error && isCompleted) {
        return <Done onComplete={onComplete} />
    }

    return <Container>
        <Logo />
        <LeftColumn>
            <Text variant="extraLarge3" marginBottom="1rem">
                <FormattedMessage id={'welcome-screen:welcome-title'} />
            </Text>
            <Text>
                <FormattedMessage id={'welcome-screen:welcome-description'} />
            </Text>
        </LeftColumn>
        <RightColumn>
            {error && isCompleted &&
                <ContainerCenter>
                    <Styled color="error" textVariant="xsRegular">
                        <FormattedMessage id="welcome-screen:error-message" values={{ br: <br /> }} />
                    </Styled>
                    <Styled marginTop="1rem">
                        <Button variant="orange" onClick={() => window.location.reload()}>
                            <FormattedMessage id="welcome-screen:retry-button" />
                        </Button>
                    </Styled>
                </ContainerCenter>
            }
            {isPending && <ContainerCenter><Loader /></ContainerCenter>}
            {isWaiting && <Controller title={title}>
                <Step />
            </Controller>}
        </RightColumn>
    </Container>
}

const Step = () => {
    const { state: { fields, currentStep } } = useWelcomeSurveyData();

    if (currentStep === 0) {
        return <DisplayName />;
    }

    return <FormStep fields={fields?.sections[currentStep - 1]} />
}

const WelcomeScreen = (props: Props) => {
    return <VRIntlProviderComponent localeFn={localeFn} id="welcome-screen" fallback={null}>
        <WelcomeSurveyProvider>
            <Form {...props} />
        </WelcomeSurveyProvider>
    </VRIntlProviderComponent>
};

export default WelcomeScreen;
