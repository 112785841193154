import { Slider } from '@mui/material';
import { styled } from '@mui/system';

interface CustomSliderProps {
  isdisabled?: string;
}

export const CustomSlider = styled(Slider)<CustomSliderProps>(({ theme, isdisabled }) => {
  const isDisabled = isdisabled?.toLowerCase() === 'true'
  return {
    '& .MuiSlider-thumb': {
      width: '12px',
      height: '12px',
      backgroundColor: '#ee7127',
      display: isDisabled ? 'none' : 'flex',

      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px rgba(238, 113, 39, 0.16)`,
      },
      '&.Mui-active': {
        boxShadow: `0px 0px 0px 14px rgba(238, 113, 39, 0.16)`,
      },
    },
    '& .MuiSlider-track': {
      backgroundColor: '#ee7127',
      border: '1px solid #ee7127',
      display: isDisabled ? 'none' : 'block',
    },
    '& .MuiSlider-rail': {
      backgroundColor: isDisabled ? '#383838' : '#bfbfbf',
    },
    '& .MuiSlider-mark': {
      display: 'none',
    },
    '& .MuiSlider-markLabel': {
      display: 'none',
    }
  }
});
