import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const LoaderContainer = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.legacy.gray[400]};
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.legacy.gray[300]};
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const LoadMore = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 4.5rem;
  background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
  color: ${({ theme }) => theme.colors.neutral.grey[300]};
  font-size: 0.9rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.legacy.gray[250]};
    color: ${({ theme }) => theme.colors.neutral.grey[50]};
  }
`;

export const Search = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.grey[300]};
  background: ${({ theme }) => theme.colors.legacy.gray[300]};

  padding-left: 1rem;
  box-sizing: border-box;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  border-radius: 20px;

  @media only screen and (max-width: 767px) {
    width: 50%;
    padding-right: 1rem;
  }

  & svg path {
    fill: ${({ theme }) => theme.colors.neutral.grey[300]};
  }

  & input[type="text"] {
    background: none;
    border: none;
    height: 100%;
    margin-left: 1rem;
    outline: none;
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
  }

  @media only screen and (max-width: 767px) {
    display: flex;
    & input[type="text"] {
      padding-left: 0.6rem;
      width: calc(100% - 1.2rem);
    }
  }
`;

export const AddTrainee = styled.div`
  @media only screen and (max-width: 767px) {
    width: 50%;

    button {
      width: 100%;
    }
  }
`;

type HeaderProps = {
  justify?: "flex-start" | "center" | "flex-end" | string;
};

export const Header = {
  Container: styled.div<HeaderProps>`
    display: flex;
    justify-content: ${({ justify }) => justify};
    margin-bottom: 2rem;

    @media only screen and (max-width: 1024px) {
      justify-content: flex-start;
      flex-direction: column-reverse;
    }
  `,
  Column: styled.div<HeaderProps>`
    flex: 0.5;
    display: flex;
    justify-content: ${({ justify }) => justify};

    @media only screen and (max-width: 1024px) {
      width: 100%;
      margin-top: 1rem;
    }
  `,
};

export const TabContainer = styled.div`
  display: flex;
`;

export const Tab = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 0.5rem;
  padding: 0 1rem;
  border-radius: 2.5rem;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colors.neutral.grey[300]};
  font-weight: 600;

  &[aria-current="page"] {
    background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
    color: ${({ theme }) => theme.colors.white};
  }
`;
