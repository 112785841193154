import moment from "moment";
import { FormattedMessage } from "react-intl";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ButtonContainer, StyledDatePicker, FilterContainer } from "./index.styles";
import { ProgressFilters } from "../../../../enums/overview";
import { CalendarIcon24 } from "../../../../components/icons";

const FilterGroup = ({
    activeKey,
    onChange,
    date,
    onDateChange,
    onYearChange,
    sessionStart
}: {
    activeKey: string;
    onChange: (filter: string) => void;
    date: moment.Moment | null;
    onDateChange: (val: moment.Moment | null) => void;
    onYearChange: (val: moment.Moment | null) => void;
    sessionStart: {
        startDate: string;
    }
}) => {

    return (
            <FilterContainer>

                {activeKey === ProgressFilters.Month && 
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledDatePicker
                            views={['year', 'month']} 
                            openTo="month"
                            value={date}
                            onMonthChange={(date) => {
                                onDateChange(date) 
                            }}
                            minDate={moment(sessionStart?.startDate)}
                            maxDate={moment()}
                            slots={{
                                openPickerIcon: CalendarIcon24
                            }}
                        />
                    </LocalizationProvider>
                }

                {activeKey === ProgressFilters.Year &&
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledDatePicker
                            views={['year']} 
                            openTo="year"
                            value={date}
                            className="year"
                            onYearChange={(date) => {
                                onYearChange(date)
                            }}
                            minDate={moment(sessionStart?.startDate)}
                            maxDate={moment()}
                            slots={{
                                openPickerIcon: CalendarIcon24
                            }}   
                            />
                    </LocalizationProvider>
                }   

                <ButtonContainer aria-label="Basic button group">
                    {Object.keys(ProgressFilters).map(key => {
                        const filter = ProgressFilters[key];
                        return <li
                            key={filter}
                            className={activeKey === filter ? 'btn-active' : ''}
                            onClick={() => onChange(filter)}
                        >
                            <FormattedMessage id={`overview:${filter}`} />
                        </li>
                    })}
                </ButtonContainer>
                
            </FilterContainer>
    )
}

export default FilterGroup;