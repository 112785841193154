import styled from "styled-components";
import base from "../../../../themes/base/base";
import Button from "../../button";
import Input from "../../input";
import { Wrapper } from "../../input/input.styles";

export const ModalContentWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.span`
    font-family: "Roboto";
    ${base.typography.extraLarge2Regular};
    color: ${base.colors.neutral.grey[50]};
    padding-bottom: 0.75rem;
    text-align: center;
`;

export const Description = styled.span`
    font-family: "Roboto";
    ${base.typography.baseRegular};
    color: ${base.colors.neutral.grey[50]};
    text-align: center;
`;

export const LogoutDescription = styled.span`
    font-family: "Roboto";
    ${base.typography.extraLargeMedium};
    color: ${base.colors.neutral.grey[50]};
    text-align: center;
`;

export const ErrorDescription = styled.span`
    font-family: "Roboto";
    ${base.typography.baseRegular};
    color: ${base.colors.semantic.red[500]};
    text-align: center;
`;

export const Email = styled.span`
    font-family: "Roboto";
    ${base.typography.baseMedium};
`;

export const StyledEmail = styled.a`
    font-family: "Roboto";
    ${base.typography.extraLargeMedium};
    color: ${base.colors.primary.orange[500]};
    text-decoration: underline;
    text-decoration-thickness: from-font;

    &:hover{
        color: ${base.colors.primary.orange[300]};
    }

    &:visited{
        color: ${base.colors.primary.purple[500]};
    }
`;



export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 1.5rem;
    width: 100%; //for the buttons to occupy 100% of width
`;

export const StyledConfirmButton = styled(Button)`
    ${base.typography.largeMedium};
    padding: 0.4375rem 1rem;

    &:hover {
        background-color: ${base.colors.primary.orange[300]};
        border-color: ${base.colors.primary.orange[300]};
    }

    &:active {
        background-color: ${base.colors.primary.orange[500]};
        border-color: ${base.colors.primary.orange[500]};
    }

    &:disabled {
        background-color: ${base.colors.neutral.grey[700]};
        color: ${base.colors.neutral.grey[500]};
        border-color: ${base.colors.neutral.grey[700]};
    }
`;

export const StyledCancelButton = styled(Button)`
    ${base.typography.largeMedium};
    color: ${base.colors.primary.orange[500]};
    padding: 0.5rem 1rem;
    border: none;
`;

export const StyledInput = styled(Input)`
    background-color: ${base.colors.neutral.grey[900]};
    color: green;

    ${Wrapper} {
        background-color: ${base.colors.neutral.grey[900]};
        border-radius: 0.125rem;
        padding: 0.5rem 0.75rem;
        color: green;

        & input {
            ${base.typography.regular};
            color: ${base.colors.neutral.grey[50]};
            margin-top: 0;
            height: 1.5rem;

            &::placeholder {
                color: ${base.colors.neutral.grey[500]};
            }
        }
    }
`;

export const AddUserErrorMessage = styled.span`
    font-family: "Roboto";
    ${base.typography.baseRegular};
    text-align: center;
    color: ${base.colors.semantic.red[500]};
    padding-top: 0.75rem;
`;

export const NotAddedUsersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0.5rem;
`;

export const NotAddedUser = styled.span`
    font-family: "Roboto";
    ${base.typography.baseMedium};
    color: ${base.colors.semantic.red[500]};
`;
