import { NavLink } from "react-router-dom";
import styled, { DefaultTheme } from "styled-components";
import Button from "../ui/button";
import base from "../../themes/base/base";

const itemHeight = "2.5rem";

export const Container = styled.div`
  position: relative;
  background: ${base.colors.neutral.grey[800]};

  @media (max-width: 884px) {
    margin-left: 0;
  }
`;

export const Toggle = styled(Button)`
  ${base.typography.smallRegular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  max-width: 460px;
  border: 0;
  border-radius: 2px;
  padding: 0.5rem 0.75rem;

  &:focus {
    box-shadow: none;
  }

  @media (max-width: 1023px) {
    padding: 0;
  } 
`;

export const PopUp = styled.div<{ width: string | number }>`
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  width: ${({ width }) => width};
  border: 0;
  border-radius: 2px;
  background-color: ${base.colors.neutral.grey[800]};
  z-index: 9999;
`;

export const DateFilterPopUp = styled(PopUp)`
  border: 1px solid #999;


  @media (max-width: 1023px) {
    max-width: 385px;
  }
`;

export const TraineesPopUp = styled(PopUp)`
  z-index: 99999;
  border: 1px solid #999;
`;

export const ListContainer = styled.div`
  max-height: 14rem;
  overflow-x: hidden;
  border-radius: 0 0 0.3rem 0.3rem;

  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.2rem;
  }
`;

export const Search = styled.div`
  height: ${itemHeight};
  display: flex;
  padding-left: 1rem;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.neutral.grey[700]};
  border-radius: 0.3rem 0.3rem 0 0;

  & input {
    background-color: transparent;
    border: none;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.neutral.grey[50]};
    outline: none;
    padding: 0.4rem 1rem;
  }

  path {
    fill: ${({ theme }: { theme: DefaultTheme }) => theme.colors.neutral.grey[300]};
  }
`;

export const AllTrainees = styled.div<{ selected: boolean }>`
  height: ${itemHeight};
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  background-color: ${base.colors.neutral.grey[800]};
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  font-weight: 400;
  box-sizing: border-box;

  ${({ theme, selected }: { theme: DefaultTheme, selected: boolean }) => selected && `
    font-weight: 500;
    color: ${theme.colors.primary.orange[500]};`};

  &:hover {
    background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.neutral.grey[800]};
  }

  path {
    fill: ${({ theme, selected }: { theme: DefaultTheme, selected: boolean }) => selected ? theme.colors.primary.orange[500] : 'inherit'};
  }
`;

export const AllTraineesContainer = styled.div`
  display: flex;
`;

export const AvatarIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.primary.orange[500]};
  height: 24px;
  width: 24px;
  border-radius: 50%;
`;

export const List = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${base.colors.neutral.grey[800]};
  z-index: 99;
`;

type ItemProps = {
  isSearch?: boolean;
  selected?: boolean;
};

export const Item = styled.li<ItemProps>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0.75rem;
  height: ${itemHeight};
  font-size: 1rem;
  box-sizing: border-box;
  
  ${({ theme, isSearch }: { theme: DefaultTheme, isSearch?: boolean }) => isSearch && `background-color:${theme.colors.neutral.grey[700]}`};

  .name {
    font-weight: 400;

    ${({ theme, selected }: { theme: DefaultTheme, selected?: boolean }) => selected && `
      font-weight: 500;
      color: ${theme.colors.primary.orange[500]}`};
  }

  &:hover {
    background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.neutral.grey[800]};
    cursor: pointer;
  }

  path {
    ${({ theme, selected }: { theme: DefaultTheme, selected?: boolean }) => selected && `fill:${theme.colors.primary.orange[500]}`};
  }
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

export const AvatarContainer = styled.div`
  margin-right: 0.5rem;

  & svg path {
    fill: #F2F2F2;
  }
`;

export const Name = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  ${base.typography.smallRegular};

  @media (min-width: 768px) and (max-width: 1919px) {
    ${base.typography.extraSmallRegular};
  }
`;

export const TextSpan = styled.span`
  ${base.typography.smallRegular};

  @media (min-width: 768px) and (max-width: 1919px) {
    ${base.typography.extraSmallRegular};
  }
`;

export const LoaderContainer = styled.li`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.legacy.gray[250]};
  }
`;

export const LoadMore = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.neutral.grey[300]};
  font-size: 0.9rem;
`;
