import styled from "styled-components";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import base from "../../themes/base/base";

export const Main = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding-bottom: 1rem;
`;

export const ButtonScenarioOverview = styled.button`
  font-size: 0.95rem;
  position: absolute;
  top: -2.9rem;
  right: 0;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.legacy.gray[200]};
  padding: 0.5rem 1rem;
  border-radius: 4.25rem;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.legacy.gray[150]};
  }
`;

type SectionHeaderProps = {
  justifyContent?: string;
};

type SectionBodyProps = {
  hasBackground?: boolean;
};

export const Section = {
  Container: styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1rem;
  `,
  Header: styled.div<SectionHeaderProps>`
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.legacy.gray[50]};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
    margin: 0.8rem 0 1.2rem 0;
    font-weight: 500;
  `,
  Body: styled.div<SectionBodyProps>`
    width: 100%;
    box-sizing: border-box;
    ${({ theme, hasBackground }) =>
      hasBackground &&
      `
      background-color:  ${theme.colors.legacy.gray[300]}; 
      padding: 1rem 0;
      `}
  `,
};

export const StyledImg = styled.img`
  vertical-align: middle;
`;

export const StyledImgMargin = styled(StyledImg)`
  margin-left: 8px;
`;

export const StyledImgTimeline = styled(StyledImgMargin)`
  margin-right: 2px;
  margin-bottom: 4px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 10rem;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  place-items: center;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.legacy.gray[500]};

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const TooltipContent = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
    padding: 0.5rem;
`;

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      color: '#EEE',
      fontSize: `1rem`,
      fontWeight: 500,
      lineHeight: `1.375rem`,
      background: '#4C4C4C'
    },
  });

export const Divider =  styled.div`
      background-color: ${base.colors.neutral.grey[900]};        
      width: 1px;
      height: 64px;
  `;

export const Title =  styled.span`
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem; 
      letter-spacing: 0.015rem;
      display: flex;
      gap: 0.4rem;
      align-items: center;
  `;

export const SubTitle = styled.span`
      color: #999;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.01125rem;
      text-align: center;
  `;

export const OverviewButtonContainer = styled.div`
  width: 100%;
`;

export const DummyContainer = styled.div`
  width: 100%;
  border: 1px solid white;
  border-radius: 8px;
  padding: 50px;
  box-sizing: border-box;
`;