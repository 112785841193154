import React, { useMemo, useCallback } from "react";
import {
    BottomWrapper,
    SessionsWrapperDiv,
    HeaderWrapper,
    ViewAllButton,
    LoaderContainer,
    ButtonContentWrapper,
    EmptySessionCard
} from "./sessions.styles";
import {
    FormattedSessions,
    OverviewSession,
    Scenario,
    SessionsProps
} from "./sessions.types";
import Text from "../../../../components/ui/text";
import SessionCard from "../session-card";
import { ChevronRightSquareCorners } from "../../../../components/icons";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSessionsList } from "./useSessionsList";
import Loader from "../../../../components/ui/loader";
import EmptyState from "../../../../components/empty-state";

const Sessions: React.FC<SessionsProps> = ({ userId }) => {
    const history = useHistory();
    const { sessionList, isFetching, sessionsListUrl, isError } =
        useSessionsList(userId);
        
    const mainSessionsScenarios = useMemo(() => {
        const allScenariosArray: FormattedSessions[] = [];

        sessionList?.forEach((session: OverviewSession) => {
            session.sessions.forEach((scenario: Scenario) => {
                allScenariosArray.push({
                    session: {
                        id: session.id,
                        simulationName: session.simulationName,
                        traineeName: session.name,
                        logoUrl: session.logoUrl,
                        userId: session.userId
                    },
                    scenario
                });
            });
        });

        return allScenariosArray.slice(0, 3);
    }, [sessionList]);

    const noSessionsData = useMemo(
        () => mainSessionsScenarios.length === 0 && !isError,
        [mainSessionsScenarios.length, isError]
    );
    const disabledState = isFetching || noSessionsData;

    const handleViewAllClick = useCallback(() => {
        history.push(sessionsListUrl);
    }, [sessionsListUrl, history]);

    const renderScenarioCards = useCallback(
        (scenarios: FormattedSessions[]) => {
            if (scenarios.length > 0 && scenarios.length < 3) {
                return (
                    <>
                        {scenarios.map((scenario) => (
                            <SessionCard
                                key={scenario.scenario.scenarioInstanceId}
                                sessionInfo={scenario.session}
                                scenarioInfo={scenario.scenario}
                            />
                        ))}
                        <EmptySessionCard />
                        {scenarios.length === 1 && <EmptySessionCard />}
                    </>
                );
            }

            if (scenarios.length >= 3) {
                return scenarios.map((scenario) => (
                    <SessionCard
                        key={scenario.scenario.scenarioInstanceId}
                        sessionInfo={scenario.session}
                        scenarioInfo={scenario.scenario}
                    />
                ));
            }
        },
        []
    );

    const renderSessions = () => {
        if (isFetching) {
            return (
                <LoaderContainer>
                    <Loader size="large" />
                </LoaderContainer>
            );
        }

        if (isError) {
            return (
                <EmptyState
                    title="overview:sessions:error-state:title"
                    description="overview:sessions:error-state:description"
                />
            );
        }

        if (noSessionsData) {
            return (
                <EmptyState
                    title="overview:sessions:empty-state:title"
                    description="overview:sessions:empty-state:description"
                />
            );
        }

        return renderScenarioCards(mainSessionsScenarios);
    };

    return (
        <SessionsWrapperDiv>
            <HeaderWrapper>
                <Text variant="extraLargeMedium" marginBottom="0.5rem">
                    <FormattedMessage id="overview:sessions:title" />
                </Text>
                <Text variant="extraSmallRegular" color="neutral.grey.300">
                    <FormattedMessage id="overview:sessions:description" />
                </Text>
            </HeaderWrapper>
            {renderSessions()}
            <BottomWrapper>
                <ViewAllButton
                    disabled={disabledState}
                    onClick={handleViewAllClick}
                >
                    <ButtonContentWrapper>
                        <FormattedMessage id="overview:sessions:view-all-button" />
                        <ChevronRightSquareCorners
                            fill={disabledState ? "#666666" : "#ee7127"}
                        />
                    </ButtonContentWrapper>
                </ViewAllButton>
            </BottomWrapper>
        </SessionsWrapperDiv>
    );
};

export default Sessions;
