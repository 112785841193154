import { ChartDataSet } from "../../../../types/combined-chart";

//To get the time difference in minutes and seconds
export const getTimeDifference = (startTime: Date, endTime: Date, showMilliseconds: boolean = false) => {
  const difference = endTime.getTime() - startTime.getTime();
  const minutes = Math.floor(difference / (1000 * 60));
  const seconds = Math.floor((difference / 1000) % 60);
  const milliseconds = difference % 1000;

  let timeDifference = minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;

  if (showMilliseconds && milliseconds > 0) {
    timeDifference +=  ` ${milliseconds}ms`;
  }

  return timeDifference;
}

/* When airspeed and altitude toggles are turned off, we still need to show the annotation.
 We can create it by showing empty chart, this function will provide the data for it */
export const createEmptyData = (airtimeData: ChartDataSet[]): [Date, any][] => (
    airtimeData.map(dataSet => [
      new Date(dataSet.DateTime),
      null
    ])
  );

//convert the data from API to APEX format  
export const convertDatatoApex = (data : ChartDataSet[]): [Date, number|null][] => (
    data.map(dataSet => [
      new Date(dataSet.DateTime),
      dataSet.Data === null ? null : Math.trunc(dataSet.Data)
    ])
  ); 
  
export const getMinAxis = (axisData: ChartDataSet[]) => {
  if(axisData?.[0]?.DateTime) {
    return new Date(axisData[0].DateTime).getTime();
  }
  return 0;
}

export const getMaxAxis = (axisData: ChartDataSet[]) => {
  if(axisData?.[axisData.length-1]?.DateTime) {
    return new Date(axisData[axisData.length-1].DateTime).getTime();
  }
  return 0; 
}

export const getMaxValue = (axisData: ChartDataSet[]) => {
  const data = axisData?.map((data:ChartDataSet)=> data.Data);
  return Math.max(...data);
}
  


  