import base from "../../../../themes/base/base";

export const series = [{
    name: "progress",
    data: []
}];

export const options: any = {
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false 
      },
      animations: {
        enabled: false,
      },
    },

    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '50%',
        }
    },
  
    dataLabels: {
      enabled: false
    },
  
    legend: {
      show: false // Hide the legend
    },
  
    grid: {
      strokeDashArray: 8,
      borderColor: "#53555A"
    },

    xaxis: {
      crosshairs: {
        show: false // <--- HERE
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      tooltip: {
        enabled: false
      },
      labels:{
        style: {
            colors: base.colors.neutral.grey[50],
            fontSize: '14px',
            fontWeight: 400    
            }
        } 
    },
  
    yaxis: {
        labels: {
            show: false
        } 
    },

    tooltip: {
        enabled: true,
        custom: ({ seriesIndex, dataPointIndex, w }: any) => {
            const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            if(data){
              return '<div class="custom-tooltip">' +
                `<span>${data.x}</span>` +
                 `<span>Avg Score: ${Math.trunc(data.y)}</span>` +
                 `<span>No of Sessions: ${data.sessions}</span>` +
                 `<span>Avg Session Time: ${data.avgSessionTime}</span>` +
                '</div>';
              }
          }
    },

    colors: [base.colors.primary.orange[500]],

  };