import { IconProps } from "../../types/icon";

const Sessions = ({ width = 32, height = 32, fill = "#EE7127" }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
    >
        <g id="Nav Sessions Icon">
            <path
                id="Sessions Icon"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.7771 4.98995C21.1733 4.98995 20.5702 4.98833 19.9674 4.9867C18.4613 4.98264 16.9569 4.97858 15.4484 4.9999C14.8564 5.00985 14.3283 4.26362 14.6564 3.51739C14.8244 3.1393 15.0964 3 15.4324 3H18.0407H28.0497C28.6818 3 29.0818 3.50744 28.9858 4.16412C28.9058 4.68151 28.5858 4.98 28.0977 4.98995H23.1612H21.7771ZM12.0133 10.1309V7.51422V7.50609V4.85688C12.0051 3.76795 11.2778 3.02032 10.191 3.01219C8.40144 2.99594 6.61186 2.99594 4.82227 3.01219C3.73545 3.02844 3 3.79233 3 4.87314V10.0984C3 11.2361 3.76813 11.9919 4.91216 12H10.1338C11.2533 11.9919 12.0133 11.2442 12.0133 10.1309ZM12.0133 24.5509V24.5427V21.8969C12.0051 20.749 11.2608 20 10.1158 20H4.88936C3.76883 20 3 20.7571 3 21.8643V27.1398C3.00818 28.2144 3.74429 28.9796 4.8321 28.9878C6.62331 29.0041 8.41452 29.0041 10.2057 28.9878C11.2772 28.9796 12.0051 28.2307 12.0133 27.1642V24.5509ZM21.7771 21.99C21.1733 21.99 20.5702 21.9883 19.9673 21.9867H19.9672H19.967H19.9669C18.4609 21.9826 16.9567 21.9786 15.4484 21.9999C14.8564 22.0099 14.3283 21.2636 14.6564 20.5174C14.8244 20.1393 15.0964 20 15.4324 20H18.0407H28.0497C28.6818 20 29.0818 20.5074 28.9858 21.1641C28.9058 21.6815 28.5858 21.98 28.0977 21.99H23.1612H21.7771ZM23.4145 23.0101H19.3996V23H15.3847C14.8934 23 14.5556 23.4242 14.5556 24.0101C14.5633 24.5758 14.9011 25 15.3693 25H23.3991C23.545 25 23.6985 24.9495 23.8214 24.8586C24.1208 24.6364 24.2436 24.1717 24.1591 23.7273C24.0747 23.3131 23.7753 23.0101 23.4145 23.0101ZM23.4145 6.0101H19.3996V6H15.3847C14.8934 6 14.5556 6.42424 14.5556 7.0101C14.5633 7.57576 14.9011 8 15.3693 8H23.3991C23.545 8 23.6985 7.9495 23.8214 7.85859C24.1208 7.63636 24.2436 7.17172 24.1591 6.72727C24.0747 6.31313 23.7753 6.0101 23.4145 6.0101Z"
                fill={fill}
            />
        </g>
    </svg>
);

export default Sessions;
