import { IconProps } from "../../types/icon";

const ChevronUpSquareCorners = ({
    fill = "#F2F2F2",
    width = 24,
    height = 24
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M16.9273 15L18 13.9323L12 8L6 13.9383L7.07273 15L12 10.1234L16.9273 15Z"
            fill={fill}
        />
    </svg>
);

export default ChevronUpSquareCorners;
