export const AirspeedBackgroundSvg = `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 28.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_2_00000047770050907475310970000002621149561603587724_"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400.7 400.7"
	 style="enable-background:new 0 0 400.7 400.7;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#161215;}
	.st1{fill:#F2F2F2;}
</style>
<g id="Layer_2-2">
	<g>
		<circle class="st0" cx="200.4" cy="200.4" r="161"/>
		
			<rect x="277.6" y="92.3" transform="matrix(0.6402 -0.7682 0.7682 0.6402 31.6293 256.219)" class="st1" width="23.4" height="4"/>
		
			<rect x="308.4" y="129.2" transform="matrix(0.8664 -0.4994 0.4994 0.8664 -22.7387 177.4085)" class="st1" width="23.4" height="4"/>
		<rect x="325" y="174.4" transform="matrix(0.9845 -0.1753 0.1753 0.9845 -25.7019 61.747)" class="st1" width="23.4" height="4"/>
		
			<rect x="334.7" y="212.7" transform="matrix(0.1755 -0.9845 0.9845 0.1755 56.6929 516.5079)" class="st1" width="4" height="23.4"/>
		
			<rect x="318.2" y="257.7" transform="matrix(0.4994 -0.8664 0.8664 0.4994 -73.1427 412.2932)" class="st1" width="4" height="23.4"/>
		
			<rect x="287.3" y="294.6" transform="matrix(0.7682 -0.6402 0.6402 0.7682 -129.0507 256.2505)" class="st1" width="4" height="23.4"/>
		
			<rect x="245.7" y="318.7" transform="matrix(0.9398 -0.3417 0.3417 0.9398 -97.9734 104.5381)" class="st1" width="4" height="23.4"/>
		<rect x="198.4" y="327.1" class="st1" width="4" height="23.4"/>
		
			<rect x="141.4" y="328.3" transform="matrix(0.3418 -0.9398 0.9398 0.3418 -209.7015 361.2727)" class="st1" width="23.4" height="4"/>
		
			<rect x="99.7" y="304.3" transform="matrix(0.6426 -0.7662 0.7662 0.6426 -194.9023 194.8437)" class="st1" width="23.5" height="4"/>
		
			<rect x="68.8" y="267.6" transform="matrix(0.8664 -0.4994 0.4994 0.8664 -123.8647 76.2155)" class="st1" width="23.4" height="4"/>
		
			<rect x="52.4" y="222.5" transform="matrix(0.9846 -0.1748 0.1748 0.9846 -38.2473 14.6605)" class="st1" width="23.5" height="4"/>
		
			<rect x="62.1" y="164.6" transform="matrix(0.1748 -0.9846 0.9846 0.1748 -120.7514 208.6315)" class="st1" width="4" height="23.5"/>
		
			<rect x="78.6" y="119.4" transform="matrix(0.5026 -0.8645 0.8645 0.5026 -73.2972 134.8771)" class="st1" width="4" height="23.5"/>
		
			<rect x="109.4" y="82.6" transform="matrix(0.7682 -0.6402 0.6402 0.7682 -34.5684 93.2197)" class="st1" width="4" height="23.4"/>
		<rect x="301.2" y="108" transform="matrix(0.7639 -0.6453 0.6453 0.7639 2.6846 225.2625)" class="st1" width="16" height="2"/>
		<rect x="236" y="68.3" transform="matrix(0.3417 -0.9398 0.9398 0.3417 96.9742 279.1391)" class="st1" width="23.4" height="4"/>
		<rect x="263.4" y="76.3" transform="matrix(0.4988 -0.8667 0.8667 0.4988 69.0467 273.972)" class="st1" width="16" height="2"/>
		<rect x="198.4" y="50.2" class="st1" width="4" height="23.4"/>
		
			<rect x="217.1" y="59.5" transform="matrix(0.1758 -0.9844 0.9844 0.1758 125.9978 271.419)" class="st1" width="15.9" height="2"/>
		
			<rect x="325.9" y="150.8" transform="matrix(0.9409 -0.3388 0.3388 0.9409 -31.675 122.1038)" class="st1" width="15.9" height="2"/>
		<rect x="334.5" y="199.4" class="st1" width="16" height="2"/>
		
			<rect x="332.9" y="241" transform="matrix(0.3387 -0.9409 0.9409 0.3387 -13.4089 478.7924)" class="st1" width="2" height="15.9"/>
		
			<rect x="308.3" y="283.7" transform="matrix(0.6451 -0.7641 0.7641 0.6451 -113.1498 339.8658)" class="st1" width="2" height="16"/>
		
			<rect x="270.4" y="315.4" transform="matrix(0.8667 -0.4988 0.4988 0.8667 -125.1158 178.4852)" class="st1" width="2" height="16"/>
		
			<rect x="224.1" y="332.4" transform="matrix(0.9845 -0.1756 0.1756 0.9845 -56.2578 44.8078)" class="st1" width="2" height="15.9"/>
		
			<rect x="167.7" y="339.3" transform="matrix(0.1699 -0.9855 0.9855 0.1699 -189.5104 455.5687)" class="st1" width="15.9" height="2"/>
		<rect x="121.3" y="322.4" transform="matrix(0.499 -0.8666 0.8666 0.499 -215.463 274.0702)" class="st1" width="16" height="2"/>
		<rect x="83.6" y="290.7" transform="matrix(0.764 -0.6453 0.6453 0.764 -166.6338 127.9662)" class="st1" width="16" height="2"/>
		<rect x="58.9" y="248" transform="matrix(0.9408 -0.339 0.339 0.9408 -80.4439 37.3883)" class="st1" width="15.9" height="2"/>
		<rect x="50.2" y="199.4" class="st1" width="16" height="2"/>
		
			<rect x="65.8" y="143.8" transform="matrix(0.3387 -0.9409 0.9409 0.3387 -98.5858 163.1954)" class="st1" width="2" height="15.9"/>
		<rect x="90.6" y="101" transform="matrix(0.6451 -0.7641 0.7641 0.6451 -50.8064 108.6781)" class="st1" width="2" height="16"/>
		<g>
			<path class="st1" d="M176.5,242.6v-10h1.3v5l5-5h1.8l-4.2,4.1l4.4,6H183l-3.6-5.1l-1.6,1.6v3.5h-1.3V242.6z"/>
			<path class="st1" d="M185.9,242.6v-10h1.4l5.3,7.9v-7.9h1.3v10h-1.5l-5.3-7.9v7.9H185.9z"/>
			<path class="st1" d="M195.6,237.7c0-1.7,0.4-3,1.3-3.9s2.1-1.4,3.5-1.4c0.9,0,1.8,0.2,2.5,0.7s1.3,1.1,1.7,1.9
				c0.4,0.8,0.6,1.7,0.6,2.7s-0.2,1.9-0.6,2.7s-1,1.4-1.7,1.8s-1.6,0.6-2.4,0.6c-0.9,0-1.8-0.2-2.5-0.7s-1.3-1.1-1.7-1.9
				S195.6,238.6,195.6,237.7L195.6,237.7z M197,237.7c0,1.2,0.3,2.2,1,2.9s1.5,1,2.4,1s1.8-0.4,2.5-1.1c0.6-0.7,1-1.7,1-3
				c0-0.8-0.1-1.5-0.4-2.1c-0.3-0.6-0.7-1.1-1.2-1.4c-0.5-0.3-1.1-0.5-1.8-0.5c-0.9,0-1.8,0.3-2.4,1
				C197.2,235.1,197,236.2,197,237.7L197,237.7z"/>
			<path class="st1" d="M209.5,242.6v-8.8h-3.3v-1.2h7.9v1.2h-3.3v8.8H209.5L209.5,242.6z"/>
			<path class="st1" d="M215,239.4l1.2-0.1c0.1,0.5,0.2,0.9,0.4,1.2s0.6,0.6,1,0.8c0.5,0.2,1,0.3,1.5,0.3s0.9-0.1,1.3-0.2
				s0.7-0.4,0.9-0.6s0.3-0.6,0.3-0.9s-0.1-0.6-0.3-0.8s-0.5-0.4-0.9-0.6c-0.3-0.1-0.9-0.3-1.8-0.5s-1.6-0.4-1.9-0.6
				c-0.5-0.2-0.8-0.6-1.1-0.9c-0.2-0.4-0.4-0.8-0.4-1.2c0-0.5,0.1-1,0.4-1.4s0.7-0.8,1.3-1s1.2-0.3,1.8-0.3c0.7,0,1.4,0.1,2,0.4
				c0.6,0.2,1,0.6,1.3,1.1s0.5,1,0.5,1.6l-1.3,0.1c-0.1-0.6-0.3-1.1-0.7-1.4c-0.4-0.3-1-0.5-1.8-0.5s-1.4,0.1-1.8,0.4
				c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.1,0.7,0.4,0.9s0.9,0.5,2,0.7s1.8,0.5,2.2,0.6c0.6,0.3,1,0.6,1.3,1s0.4,0.9,0.4,1.4
				s-0.1,1-0.5,1.5c-0.3,0.5-0.7,0.8-1.3,1.1s-1.2,0.4-1.9,0.4c-0.9,0-1.7-0.1-2.3-0.4c-0.6-0.3-1.1-0.7-1.4-1.2S215,240,215,239.4
				L215,239.4z"/>
		</g>
		<g>
			<path class="st1" d="M185.2,242.8h-2.4l-3.5-4.9l-1.4,1.3v3.6h-1.8v-0.2v-10.3h1.8v4.6l4.6-4.6h2.5l-4.5,4.3L185.2,242.8z
				 M183.2,242.2h1.1l-4.2-5.7l3.9-3.8h-1.1l-5.3,5.3v-5.3h-0.8v9.5h0.8v-3.3l1.9-1.9L183.2,242.2z"/>
			<path class="st1" d="M194,242.8h-1.7l-4.9-7.3v7.3h-1.8v-10.5h1.7l4.9,7.3v-7.3h1.8V242.8z M192.5,242.2h1v-9.5h-0.8v8.4
				l-5.6-8.4h-1v9.5h0.8v-8.5L192.5,242.2z"/>
			<path class="st1" d="M200.4,243c-1,0-1.9-0.2-2.7-0.7s-1.4-1.1-1.8-2c-0.4-0.8-0.6-1.7-0.6-2.6c0-1.7,0.5-3.1,1.4-4.1
				s2.2-1.5,3.6-1.5c1,0,1.9,0.2,2.6,0.7c0.8,0.5,1.4,1.1,1.8,2c0.4,0.8,0.6,1.8,0.6,2.8s-0.2,2-0.6,2.8c-0.4,0.8-1.1,1.5-1.8,1.9
				C202.2,242.8,201.2,243,200.4,243z M200.4,232.6c-1.3,0-2.4,0.4-3.3,1.3c-0.8,0.9-1.3,2.1-1.3,3.7c0,0.8,0.2,1.6,0.5,2.4
				c0.4,0.7,0.9,1.3,1.6,1.8c0.7,0.4,1.5,0.6,2.4,0.6c0.8,0,1.6-0.2,2.3-0.6c0.7-0.4,1.2-1,1.6-1.7c0.4-0.8,0.6-1.6,0.6-2.6
				s-0.2-1.8-0.6-2.6c-0.4-0.7-0.9-1.3-1.6-1.7C202.1,232.9,201.2,232.6,200.4,232.6z M200.4,241.9c-1,0-1.9-0.4-2.6-1.1
				c-0.6-0.7-1-1.6-1-2.8l0,0v-0.2c0-1.6,0.4-2.7,1.1-3.4c0.7-0.7,1.6-1,2.6-1c0.7,0,1.4,0.2,1.9,0.5c0.6,0.4,1,0.9,1.3,1.5
				c0.3,0.7,0.4,1.4,0.4,2.2c0,1.4-0.3,2.4-1,3.2C202.2,241.5,201.4,241.9,200.4,241.9z M197.2,237.5v0.2c0,1.1,0.3,2,0.9,2.7
				c0.6,0.6,1.3,1,2.3,1c0.9,0,1.7-0.3,2.3-1c0.6-0.6,0.9-1.6,0.9-2.8c0-0.8-0.1-1.5-0.4-2c-0.3-0.6-0.6-1-1.1-1.3s-1-0.5-1.7-0.5
				c-0.9,0-1.6,0.3-2.2,0.9C197.6,235.2,197.2,236.2,197.2,237.5z"/>
			<path class="st1" d="M211,242.8h-1.6v-0.2h-0.2v-8.6h-3.3v-1.7h8.4v1.7h-3.3L211,242.8L211,242.8z M209.7,242.2h0.8v-8.8h3.3
				v-0.7h-7.4v0.7h3.3V242.2z"/>
			<path class="st1" d="M219.2,243c-1,0-1.7-0.1-2.4-0.4c-0.6-0.3-1.2-0.7-1.5-1.3c-0.4-0.6-0.6-1.2-0.6-1.9h0.2v-0.2l1.5-0.1v0.2
				c0.1,0.5,0.2,0.8,0.4,1.1s0.5,0.5,0.9,0.7s0.9,0.3,1.4,0.3s0.9-0.1,1.3-0.2c0.3-0.1,0.6-0.3,0.8-0.5s0.2-0.5,0.2-0.7
				c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.4-0.4-0.8-0.5c-0.1-0.1-0.6-0.2-1.8-0.5c-0.9-0.2-1.6-0.4-2-0.6c-0.5-0.3-0.9-0.6-1.2-1
				s-0.4-0.9-0.4-1.4c0-0.6,0.2-1.1,0.5-1.6s0.8-0.8,1.4-1.1c0.6-0.2,1.2-0.4,1.9-0.4c0.8,0,1.5,0.1,2.1,0.4
				c0.6,0.3,1.1,0.6,1.4,1.2c0.3,0.5,0.5,1.1,0.5,1.7v0.2l-1.8,0.1v-0.2c-0.1-0.6-0.3-1-0.6-1.3s-0.9-0.4-1.6-0.4
				c-0.7,0-1.3,0.1-1.6,0.4c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.6,0.4,1.9,0.7c1,0.2,1.8,0.5,2.3,0.7
				c0.6,0.3,1.1,0.7,1.4,1.1c0.3,0.4,0.5,1,0.5,1.6s-0.2,1.1-0.5,1.6s-0.8,0.9-1.4,1.2C220.7,242.9,220.1,243,219.2,243z
				 M215.2,239.6c0,0.5,0.2,1,0.5,1.4c0.3,0.5,0.8,0.9,1.3,1.1c0.6,0.3,1.3,0.4,2.2,0.4c0.7,0,1.3-0.1,1.8-0.4
				c0.5-0.2,0.9-0.6,1.2-1s0.4-0.9,0.4-1.4s-0.1-0.9-0.4-1.3c-0.3-0.4-0.7-0.7-1.2-0.9c-0.3-0.1-0.9-0.3-2.2-0.6
				c-1.1-0.2-1.8-0.5-2.1-0.8s-0.5-0.6-0.5-1.1s0.2-0.9,0.6-1.3c0.4-0.3,1.1-0.5,1.9-0.5c0.8,0,1.5,0.2,1.9,0.5s0.6,0.8,0.7,1.4
				l0.8-0.1c0-0.4-0.2-0.8-0.4-1.2c-0.3-0.4-0.7-0.7-1.2-1c-0.5-0.2-1.2-0.3-1.9-0.3c-0.6,0-1.2,0.1-1.7,0.3s-0.9,0.5-1.1,0.9
				s-0.4,0.8-0.4,1.3c0,0.4,0.1,0.8,0.3,1.1s0.5,0.6,1,0.8c0.2,0.1,0.8,0.3,1.9,0.6c0.8,0.2,1.5,0.4,1.8,0.5c0.5,0.2,0.8,0.4,1,0.7
				s0.3,0.6,0.3,1s-0.1,0.7-0.3,1s-0.6,0.5-1,0.7s-0.9,0.2-1.4,0.2c-0.6,0-1.1-0.1-1.6-0.3s-0.9-0.5-1.1-0.9
				c-0.2-0.3-0.3-0.7-0.4-1.1L215.2,239.6z"/>
		</g>
		<g>
			<path class="st1" d="M161.4,165.6l3.8-10h1.4l4.1,10h-1.5l-1.2-3h-4.2l-1.1,3H161.4z M164.2,161.4h3.4l-1.1-2.8
				c-0.3-0.8-0.6-1.5-0.7-2.1c-0.1,0.6-0.3,1.3-0.5,1.9L164.2,161.4L164.2,161.4z"/>
			<path class="st1" d="M172,165.6v-10h1.3v10H172z"/>
			<path class="st1" d="M175.7,165.6v-10h4.4c0.9,0,1.6,0.1,2,0.3c0.5,0.2,0.8,0.5,1.1,0.9c0.3,0.4,0.4,1,0.4,1.5
				c0,0.7-0.2,1.3-0.7,1.8s-1.2,0.8-2.1,0.9c0.4,0.2,0.6,0.3,0.8,0.5c0.4,0.4,0.8,0.8,1.1,1.3l1.7,2.7h-1.7l-1.3-2.1
				c-0.4-0.6-0.7-1.1-1-1.4s-0.5-0.5-0.7-0.7s-0.4-0.2-0.6-0.3c-0.1,0-0.4-0.1-0.7-0.1h-1.5v4.4h-1.2V165.6z M177.1,159.9h2.9
				c0.6,0,1.1-0.1,1.4-0.2c0.3-0.1,0.6-0.3,0.8-0.6c0.2-0.3,0.3-0.6,0.3-0.9c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.9-0.5-1.6-0.5h-3.2
				L177.1,159.9L177.1,159.9z"/>
			<path class="st1" d="M189.2,162.4l1.2-0.1c0.1,0.5,0.2,0.9,0.4,1.2c0.2,0.3,0.6,0.6,1,0.8c0.5,0.2,1,0.3,1.5,0.3s0.9-0.1,1.3-0.2
				c0.4-0.1,0.7-0.4,0.9-0.6c0.2-0.3,0.3-0.6,0.3-0.9s-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-0.9-0.6c-0.3-0.1-0.9-0.3-1.8-0.5
				s-1.6-0.4-1.9-0.6c-0.5-0.2-0.8-0.6-1.1-0.9c-0.2-0.4-0.4-0.8-0.4-1.2c0-0.5,0.1-1,0.4-1.4c0.3-0.4,0.7-0.8,1.3-1
				s1.2-0.3,1.8-0.3c0.7,0,1.4,0.1,2,0.4s1,0.6,1.3,1.1s0.5,1,0.5,1.6l-1.3,0.1c-0.1-0.6-0.3-1.1-0.7-1.4s-1-0.5-1.8-0.5
				s-1.4,0.2-1.8,0.4c-0.4,0.3-0.6,0.7-0.6,1.1c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.9,0.5,2,0.7s1.8,0.5,2.2,0.6c0.6,0.3,1,0.6,1.3,1
				c0.3,0.4,0.4,0.9,0.4,1.4s-0.1,1-0.5,1.5c-0.3,0.5-0.7,0.8-1.3,1.1s-1.2,0.4-1.9,0.4c-0.9,0-1.7-0.1-2.3-0.4s-1.1-0.7-1.4-1.2
				S189.2,162.9,189.2,162.4L189.2,162.4z"/>
			<path class="st1" d="M199,165.6v-10h3.8c0.7,0,1.2,0,1.5,0.1c0.5,0.1,0.9,0.2,1.2,0.5s0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.4
				c0,0.8-0.3,1.6-0.8,2.2c-0.5,0.6-1.5,0.9-2.9,0.9h-2.5v4.1H199V165.6z M200.4,160.2h2.6c0.9,0,1.5-0.2,1.8-0.5s0.5-0.8,0.5-1.3
				c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.5-0.8-0.6c-0.2-0.1-0.6-0.1-1.2-0.1h-2.6V160.2L200.4,160.2z"/>
			<path class="st1" d="M208.4,165.6v-10h7.2v1.2h-5.9v3.1h5.5v1.2h-5.5v3.4h6.1v1.2L208.4,165.6L208.4,165.6z"/>
			<path class="st1" d="M217.8,165.6v-10h7.2v1.2h-5.9v3.1h5.5v1.2h-5.5v3.4h6.1v1.2L217.8,165.6L217.8,165.6z"/>
			<path class="st1" d="M227.1,165.6v-10h3.4c0.8,0,1.4,0.1,1.8,0.1c0.6,0.1,1.1,0.4,1.5,0.7c0.5,0.4,0.9,1,1.2,1.7s0.4,1.5,0.4,2.4
				c0,0.8-0.1,1.4-0.3,2c-0.2,0.6-0.4,1.1-0.7,1.5c-0.3,0.4-0.6,0.7-0.9,0.9c-0.3,0.2-0.7,0.4-1.2,0.5c-0.5,0.1-1,0.2-1.6,0.2
				L227.1,165.6L227.1,165.6z M228.4,164.4h2.1c0.7,0,1.2-0.1,1.6-0.2c0.4-0.1,0.7-0.3,0.9-0.5c0.3-0.3,0.6-0.7,0.7-1.3
				c0.2-0.5,0.3-1.2,0.3-1.9c0-1.1-0.2-1.8-0.5-2.4c-0.3-0.6-0.8-0.9-1.2-1.1c-0.4-0.1-0.9-0.2-1.7-0.2h-2.2V164.4L228.4,164.4z"/>
		</g>
		<g>
			<path class="st1" d="M171.1,165.8h-2.1l-1.2-3h-3.8l-1.1,3h-1.9l4-10.5h1.8L171.1,165.8z M169.4,165.2h1l-3.9-9.5h-0.5l0.2,0.7
				c0.1,0.5,0.4,1.2,0.7,2.1l1.2,3.1h-4v-0.3l1.1-2.9c0.2-0.6,0.4-1.2,0.5-1.9l0.2-0.7h-0.5l-3.7,9.5h0.9l1.1-3h4.5L169.4,165.2z
				 M164.6,161.2h2.7l-0.9-2.4c-0.2-0.5-0.3-0.9-0.4-1.2c-0.1,0.4-0.2,0.7-0.3,1.1L164.6,161.2z"/>
			<path class="st1" d="M173.6,165.8h-1.8v-10.5h1.8V165.8z M172.2,165.2h0.8v-9.5h-0.8V165.2z"/>
			<path class="st1" d="M185,165.8h-2.3l-1.4-2.2c-0.4-0.6-0.7-1-0.9-1.4c-0.3-0.4-0.5-0.5-0.6-0.6c-0.2-0.1-0.3-0.2-0.5-0.2
				c-0.1,0-0.3,0-0.7,0h-1.3v4.4h-1.6v-0.2h-0.2v-10.3h4.7c0.9,0,1.6,0.1,2.1,0.3c0.5,0.2,0.9,0.6,1.2,1.1s0.5,1.1,0.5,1.6
				c0,0.8-0.3,1.4-0.8,2c-0.4,0.4-0.9,0.7-1.6,0.9c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.4,0.8,0.8,1.1,1.4L185,165.8z M183,165.2h1.1
				l-1.5-2.3c-0.3-0.5-0.7-0.9-1.1-1.3c-0.1-0.1-0.4-0.3-0.7-0.5l-0.7-0.4l0.8-0.1c0.9-0.1,1.6-0.4,2-0.9c0.4-0.4,0.6-1,0.6-1.6
				c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.7-1-0.8c-0.4-0.2-1.1-0.3-1.9-0.3H176v9.5h0.8v-4.4h1.8c0.3,0,0.6,0,0.8,0.1
				c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.2,0.5,0.4,0.7,0.7c0.3,0.3,0.6,0.8,1,1.4L183,165.2z M179.9,160.2h-3.1v-3.8h3.4
				c0.8,0,1.4,0.2,1.8,0.5c0.4,0.4,0.6,0.8,0.6,1.4c0,0.4-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7C181.1,160.2,180.6,160.2,179.9,160.2z
				 M177.2,159.7h2.6c0.6,0,1-0.1,1.3-0.2c0.3-0.1,0.5-0.3,0.7-0.5s0.2-0.5,0.2-0.8c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.8-0.4-1.5-0.4
				h-2.9L177.2,159.7L177.2,159.7z"/>
			<path class="st1" d="M193.6,165.9c-1,0-1.7-0.1-2.4-0.4s-1.2-0.7-1.5-1.3c-0.4-0.6-0.6-1.2-0.6-1.9h0.2v-0.2l1.5-0.1v0.2
				c0.1,0.5,0.2,0.8,0.4,1.1c0.2,0.3,0.5,0.5,0.9,0.7s0.9,0.3,1.4,0.3s0.9-0.1,1.2-0.2c0.3-0.1,0.6-0.3,0.7-0.5
				c0.2-0.2,0.2-0.5,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.4-0.4-0.8-0.5c-0.3-0.1-1-0.3-1.7-0.5c-0.9-0.2-1.6-0.4-2-0.6
				c-0.5-0.3-0.9-0.6-1.2-1c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.6,0.2-1.1,0.5-1.6s0.8-0.8,1.4-1.1c0.6-0.2,1.2-0.4,1.9-0.4
				c0.8,0,1.5,0.1,2.1,0.4s1.1,0.6,1.4,1.2c0.3,0.5,0.5,1.1,0.5,1.7v0.2l-1.7,0.1v-0.2c-0.1-0.6-0.3-1-0.6-1.3s-0.9-0.4-1.6-0.4
				c-0.7,0-1.3,0.1-1.6,0.4c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.6,0.4,1.9,0.7c1,0.2,1.8,0.5,2.3,0.7
				c0.6,0.3,1.1,0.7,1.4,1.1c0.3,0.5,0.5,1,0.5,1.6c0,0.6-0.2,1.1-0.5,1.6s-0.8,0.9-1.4,1.2C195,165.9,194.2,165.9,193.6,165.9z
				 M189.6,162.6c0,0.5,0.2,1,0.5,1.4c0.3,0.5,0.8,0.8,1.3,1.1c0.6,0.3,1.3,0.4,2.2,0.4c0.7,0,1.3-0.1,1.8-0.4
				c0.5-0.2,0.9-0.6,1.2-1c0.3-0.4,0.4-0.9,0.4-1.4s-0.1-0.9-0.4-1.3c-0.3-0.4-0.7-0.7-1.2-0.9c-0.3-0.1-0.9-0.3-2.2-0.6
				c-1.1-0.2-1.8-0.5-2.1-0.8s-0.5-0.6-0.5-1.1s0.2-0.9,0.6-1.3c0.4-0.3,1.1-0.5,1.9-0.5c0.8,0,1.5,0.2,1.9,0.5s0.6,0.8,0.7,1.4
				l0.8-0.1c0-0.4-0.2-0.8-0.4-1.2c-0.3-0.4-0.7-0.7-1.2-1c-0.5-0.2-1.2-0.3-1.9-0.3s-1.2,0.1-1.7,0.3c-0.5,0.2-0.9,0.5-1.1,0.9
				c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.4,0.1,0.8,0.3,1.1s0.5,0.6,1,0.8c0.2,0.1,0.8,0.3,1.9,0.6c0.8,0.2,1.5,0.4,1.8,0.5
				c0.5,0.2,0.8,0.4,1,0.7c0.2,0.3,0.3,0.6,0.3,1c0,0.4-0.1,0.7-0.3,1s-0.5,0.5-1,0.7c-0.4,0.2-0.9,0.2-1.4,0.2
				c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.1-0.9c-0.2-0.3-0.3-0.7-0.4-1.1L189.6,162.6z"/>
			<path class="st1" d="M200.6,165.8h-1.6v-0.2h-0.2v-10.3h4c0.7,0,1.2,0,1.6,0.1c0.5,0.1,1,0.3,1.3,0.5c0.4,0.3,0.7,0.6,0.9,1.1
				s0.3,0.9,0.3,1.5c0,0.9-0.3,1.7-0.9,2.3c-0.6,0.6-1.6,1-3.1,1h-2.3V165.8z M199.2,165.2h0.8v-4.1h2.8c1.3,0,2.3-0.3,2.8-0.8
				s0.7-1.2,0.7-2c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.7-0.7-0.9c-0.3-0.2-0.7-0.4-1.1-0.4c-0.3-0.1-0.8-0.1-1.5-0.1h-3.5V165.2z
				 M203,160.6h-2.8v-4.1h2.8c0.6,0,1.1,0,1.3,0.1c0.4,0.1,0.7,0.3,1,0.7c0.2,0.3,0.4,0.8,0.4,1.2c0,0.7-0.2,1.2-0.6,1.5
				C204.5,160.4,203.9,160.6,203,160.6z M200.6,160.1h2.3c0.8,0,1.3-0.1,1.7-0.4c0.3-0.3,0.5-0.6,0.5-1.2c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.3-0.4-0.4-0.7-0.5c-0.1,0-0.5-0.1-1.2-0.1h-2.3V160.1z"/>
			<path class="st1" d="M216.2,165.8h-8v-10.5h7.8v1.7h-5.9v2.6h5.5v1.7h-5.5v2.9h6.2v1.6H216.2z M208.7,165.2h7v-0.7h-6.2v-3.9h5.5
				V160h-5.5v-3.6h5.9v-0.7h-6.8v9.6H208.7z"/>
			<path class="st1" d="M225.5,165.8h-8v-10.5h7.8v1.7h-5.9v2.6h5.5v1.7h-5.5v2.9h6.1L225.5,165.8L225.5,165.8z M218,165.2h7v-0.7
				h-6.1v-3.9h5.5V160h-5.5v-3.6h5.9v-0.7h-6.8L218,165.2L218,165.2z"/>
			<path class="st1" d="M227.2,166.2l-0.9-0.9h0.4v-10h3.7c0.8,0,1.4,0,1.8,0.1c0.6,0.1,1.1,0.4,1.6,0.8c0.6,0.5,1,1.1,1.3,1.8
				c0.3,0.7,0.4,1.5,0.4,2.5c0,0.8-0.1,1.5-0.3,2.1c-0.2,0.6-0.4,1.1-0.7,1.5c-0.3,0.4-0.6,0.7-1,1c-0.4,0.2-0.8,0.4-1.3,0.5
				c-0.5,0.1-1.1,0.2-1.7,0.2h-3.4L227.2,166.2L227.2,166.2z M227.2,165.2h3.4c0.6,0,1.1-0.1,1.6-0.2c0.4-0.1,0.8-0.3,1.1-0.5
				s0.6-0.5,0.8-0.9c0.3-0.4,0.5-0.8,0.6-1.4c0.2-0.6,0.3-1.2,0.3-1.9c0-0.9-0.1-1.6-0.4-2.3c-0.3-0.7-0.6-1.2-1.1-1.6
				c-0.4-0.3-0.8-0.5-1.4-0.7c-0.4-0.1-1-0.1-1.7-0.1h-3.2L227.2,165.2L227.2,165.2z M230.5,164.7h-2.4v-8.2h2.4
				c0.8,0,1.4,0.1,1.8,0.2c0.5,0.2,1,0.6,1.4,1.2c0.4,0.6,0.6,1.4,0.6,2.5c0,0.8-0.1,1.4-0.3,2c-0.2,0.6-0.5,1-0.8,1.4
				c-0.3,0.3-0.6,0.5-1,0.6C231.8,164.6,231.2,164.7,230.5,164.7z M228.6,164.2h1.9c0.6,0,1.1-0.1,1.5-0.2c0.3-0.1,0.6-0.3,0.8-0.5
				c0.3-0.3,0.5-0.7,0.7-1.2s0.2-1.1,0.2-1.8c0-1-0.2-1.8-0.5-2.3s-0.7-0.9-1.1-1c-0.3-0.1-0.9-0.2-1.6-0.2h-1.9L228.6,164.2
				L228.6,164.2z"/>
		</g>
		<g>
			<path class="st1" d="M195.6,92.9c0-1.7,0.2-3,0.5-4.1s0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.2,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4c-0.7,0.6-1.6,0.8-2.6,0.8
				c-1.4,0-2.5-0.5-3.2-1.5C196.1,97.6,195.6,95.7,195.6,92.9L195.6,92.9z M197.4,92.9c0,2.3,0.3,3.9,0.8,4.7c0.5,0.8,1.2,1.2,2,1.2
				s1.5-0.4,2-1.2c0.5-0.8,0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.5-0.8-1.2-1.2-2.1-1.2s-1.4,0.3-1.9,1
				C197.7,88.9,197.4,90.6,197.4,92.9L197.4,92.9z"/>
		</g>
		<g>
			<path class="st1" d="M200.2,100.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.5l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5c0.7-0.6,1.6-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4s0.7,1.3,0.9,2.2
				c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C202.2,100.2,201.2,100.4,200.2,100.4z M195.8,93.6
				c0.1,2.3,0.5,4,1.4,5.1c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3c-0.5-0.3-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4L195.8,93.6L195.8,93.6z M200.2,99.1c-0.9,0-1.6-0.4-2.2-1.3
				c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8
				s-0.3,4-0.9,4.8C201.9,98.7,201.2,99.1,200.2,99.1z M197.7,93.2c0,2.2,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1s1.3-0.3,1.8-1.1
				c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7
				L197.7,93.2z"/>
		</g>
		<g>
			<path class="st1" d="M153.9,298.6l1.8-0.2c0.1,0.9,0.4,1.6,0.9,2c0.5,0.5,1.1,0.7,1.8,0.7c0.8,0,1.6-0.3,2.2-1
				c0.6-0.6,0.9-1.5,0.9-2.5s-0.3-1.8-0.8-2.4c-0.6-0.6-1.3-0.9-2.2-0.9c-0.6,0-1.1,0.1-1.5,0.4c-0.5,0.3-0.8,0.6-1.1,1l-1.7-0.2
				l1.4-7.4h7.1v1.7h-5.7l-0.8,3.9c0.9-0.6,1.8-0.9,2.7-0.9c1.2,0,2.3,0.4,3.2,1.3c0.9,0.9,1.3,2,1.3,3.3s-0.4,2.4-1.1,3.4
				c-0.9,1.2-2.2,1.7-3.8,1.7c-1.3,0-2.4-0.4-3.2-1.1C154.6,300.9,154.2,299.9,153.9,298.6L153.9,298.6z"/>
			<path class="st1" d="M165.1,295.4c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4c-0.7,0.6-1.6,0.9-2.6,0.9
				c-1.4,0-2.5-0.5-3.2-1.5C165.6,300,165.1,298.1,165.1,295.4L165.1,295.4z M166.9,295.4c0,2.3,0.3,3.9,0.8,4.7
				c0.6,0.8,1.2,1.2,2,1.2s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2s-1.4,0.3-1.9,1
				C167.2,291.4,166.9,293,166.9,295.4L166.9,295.4z"/>
			<path class="st1" d="M176.2,295.4c0-1.7,0.2-3.1,0.5-4.1c0.4-1,0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4c-0.7,0.6-1.6,0.9-2.6,0.9
				c-1.4,0-2.5-0.5-3.2-1.5C176.7,300,176.2,298.1,176.2,295.4L176.2,295.4z M178.1,295.4c0,2.3,0.3,3.9,0.8,4.7
				c0.6,0.8,1.2,1.2,2,1.2s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2s-1.4,0.3-1.9,1
				C178.4,291.4,178.1,293,178.1,295.4L178.1,295.4z"/>
		</g>
		<g>
			<path class="st1" d="M158.6,302.9c-1.3,0-2.5-0.4-3.4-1.2c-0.8-0.7-1.3-1.7-1.5-2.9l-1-0.3l3.3-0.3v0.2c0.1,0.8,0.4,1.5,0.9,1.9
				s1,0.6,1.6,0.6c0.8,0,1.4-0.3,2-0.9c0.5-0.6,0.8-1.4,0.8-2.4s-0.2-1.6-0.8-2.2c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.3
				c-0.4,0.2-0.7,0.5-1,0.9l-0.1,0.1l-2.1-0.3l1.5-7.8h7.6v2.2h-5.8l-0.6,3.1c0.8-0.4,1.5-0.6,2.4-0.6c1.3,0,2.4,0.5,3.3,1.4
				c0.9,0.9,1.4,2.1,1.4,3.5s-0.4,2.5-1.2,3.5C161.6,302.2,160.2,302.9,158.6,302.9z M154.2,298.9c0.1,1,0.6,1.9,1.3,2.5
				c0.8,0.7,1.8,1,3,1c1.5,0,2.7-0.5,3.6-1.6c0.7-0.9,1.1-1.9,1.1-3.2c0-1.3-0.4-2.3-1.2-3.2c-0.8-0.8-1.8-1.2-3-1.2
				c-0.9,0-1.7,0.3-2.6,0.9l-0.5,0.4l0.9-4.7h5.7v-1.2h-6.7l-1.3,6.9l1.2,0.2c0.3-0.4,0.6-0.7,1.1-1c0.5-0.3,1-0.4,1.7-0.4
				c1,0,1.8,0.3,2.4,0.9c0.6,0.6,0.9,1.5,0.9,2.5c0,1.1-0.3,2-0.9,2.7c-0.6,0.7-1.4,1-2.3,1c-0.8,0-1.4-0.2-2-0.7
				c-0.5-0.5-0.8-1.1-1-1.9L154.2,298.9z"/>
			<path class="st1" d="M169.8,302.9c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6V295c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5s1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2s0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C171.8,302.6,170.9,302.9,169.8,302.9z M165.4,295.6c0,2.5,0.5,4.3,1.4,5.4
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3c-0.5-0.3-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8c-0.6,0.5-1.1,1.3-1.5,2.3
				c-0.3,1-0.5,2.3-0.5,4L165.4,295.6z M169.8,301.5c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7
				c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8
				C171.4,301.1,170.7,301.5,169.8,301.5z M167.2,295.6c0,2.2,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1s1.3-0.3,1.8-1.1
				c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7
				L167.2,295.6z"/>
			<path class="st1" d="M180.9,302.9c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6V295c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5c0.7-0.6,1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2
				s0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C182.9,302.6,182,302.9,180.9,302.9z M176.5,295.6
				c0,2.5,0.5,4.3,1.4,5.4c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3c-0.5-0.3-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4L176.5,295.6z M180.9,301.5c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8
				v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8
				C182.6,301.1,181.8,301.5,180.9,301.5z M178.2,295.6c0,2.2,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1s1.3-0.3,1.8-1.1
				c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7
				L178.2,295.6z"/>
		</g>
		<g>
			<path class="st1" d="M117.2,246.1l-1.8,0.1c-0.2-0.7-0.4-1.2-0.7-1.5c-0.5-0.5-1.1-0.8-1.8-0.8c-0.6,0-1.1,0.2-1.5,0.5
				c-0.6,0.4-1,1-1.3,1.8s-0.5,1.9-0.5,3.3c0.4-0.6,0.9-1.1,1.6-1.4s1.2-0.5,1.9-0.5c1.2,0,2.2,0.4,3,1.3s1.2,2,1.2,3.4
				c0,0.9-0.2,1.7-0.6,2.5s-0.9,1.4-1.6,1.8c-0.7,0.4-1.4,0.6-2.3,0.6c-1.5,0-2.7-0.5-3.6-1.6s-1.4-2.9-1.4-5.3c0-2.8,0.5-4.8,1.5-6
				c0.9-1.1,2.1-1.6,3.6-1.6c1.1,0,2.1,0.3,2.8,0.9C116.7,244.1,117,245,117.2,246.1L117.2,246.1z M110,252.2c0,0.6,0.1,1.2,0.4,1.7
				c0.3,0.6,0.6,1,1.1,1.3s0.9,0.4,1.5,0.4c0.7,0,1.4-0.3,1.9-0.9c0.5-0.6,0.8-1.4,0.8-2.4s-0.3-1.8-0.8-2.3c-0.5-0.6-1.2-0.9-2-0.9
				s-1.5,0.3-2,0.9C110.3,250.6,110,251.4,110,252.2L110,252.2z"/>
			<path class="st1" d="M119.2,249.9c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3c0.4,0.6,0.7,1.3,0.9,2.1s0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1c-0.3,1-0.9,1.8-1.6,2.4
				c-0.7,0.6-1.6,0.9-2.6,0.9c-1.4,0-2.5-0.5-3.2-1.5C119.7,254.5,119.2,252.6,119.2,249.9L119.2,249.9z M121,249.9
				c0,2.4,0.3,3.9,0.8,4.7c0.6,0.8,1.2,1.2,2,1.2s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2
				s-1.4,0.3-1.9,1C121.3,245.9,121,247.5,121,249.9L121,249.9z"/>
			<path class="st1" d="M130.4,249.9c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3c0.4,0.6,0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1c-0.3,1-0.9,1.8-1.6,2.4
				c-0.7,0.6-1.6,0.9-2.6,0.9c-1.4,0-2.5-0.5-3.2-1.5C130.9,254.5,130.4,252.6,130.4,249.9L130.4,249.9z M132.2,249.9
				c0,2.4,0.3,3.9,0.8,4.7c0.6,0.8,1.2,1.2,2,1.2s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2
				c-0.9,0-1.4,0.3-1.9,1C132.4,245.9,132.2,247.5,132.2,249.9L132.2,249.9z"/>
		</g>
		<g>
			<path class="st1" d="M112.9,257.4c-1.5,0-2.8-0.6-3.8-1.7c-1-1.1-1.4-3-1.4-5.5c0-2.8,0.5-4.9,1.6-6.2c0.9-1.1,2.2-1.7,3.8-1.7
				c1.2,0,2.2,0.3,2.9,1c0.8,0.7,1.2,1.6,1.4,2.8h-0.2v0.3l-2,0.2v-0.2c-0.1-0.6-0.4-1.1-0.6-1.4c-0.4-0.4-0.9-0.7-1.6-0.7
				c-0.5,0-0.9,0.1-1.3,0.4c-0.5,0.4-0.9,0.9-1.2,1.7c-0.2,0.6-0.4,1.4-0.5,2.5c0.3-0.4,0.7-0.7,1.2-0.9c0.6-0.3,1.3-0.5,2-0.5
				c1.2,0,2.3,0.5,3.2,1.4c0.9,0.9,1.3,2.1,1.3,3.5c0,0.9-0.2,1.8-0.6,2.6s-1,1.4-1.7,1.9C114.8,257.1,113.8,257.4,112.9,257.4z
				 M113.2,242.8c-1.4,0-2.6,0.5-3.4,1.5c-1,1.2-1.5,3.2-1.5,5.9c0,2.4,0.4,4.1,1.3,5.2c0.9,1,2,1.5,3.4,1.5c0.8,0,1.5-0.2,2.2-0.6
				c0.6-0.4,1.1-0.9,1.5-1.7c0.4-0.7,0.6-1.5,0.6-2.4c0-1.3-0.4-2.4-1.2-3.2c-0.8-0.8-1.7-1.2-2.8-1.2c-0.6,0-1.2,0.1-1.8,0.4
				c-0.6,0.3-1.1,0.8-1.5,1.4l-0.5,0.7v-0.9c0-1.5,0.2-2.6,0.5-3.4c0.3-0.8,0.8-1.4,1.4-1.9c0.5-0.3,1-0.5,1.6-0.5
				c0.8,0,1.4,0.3,1.9,0.8c0.3,0.3,0.5,0.8,0.7,1.4l1.3-0.1c-0.2-0.9-0.5-1.7-1.2-2.2C115,243.1,114.2,242.8,113.2,242.8z
				 M112.9,256c-0.6,0-1.1-0.2-1.6-0.5s-0.9-0.8-1.2-1.4c-0.3-0.6-0.4-1.2-0.4-1.8v-0.2c0-0.9,0.3-1.6,0.9-2.2
				c0.6-0.6,1.3-0.9,2.2-0.9c0.9,0,1.6,0.3,2.2,0.9c0.6,0.6,0.9,1.4,0.9,2.5s-0.3,2-0.9,2.6C114.4,255.7,113.8,256,112.9,256z
				 M110.3,252.6c0,0.5,0.1,0.9,0.4,1.4c0.2,0.5,0.6,0.9,1,1.2s0.9,0.4,1.3,0.4c0.7,0,1.2-0.3,1.7-0.8c0.5-0.6,0.7-1.3,0.7-2.3
				c0-0.9-0.2-1.6-0.7-2.2c-0.5-0.5-1.1-0.8-1.8-0.8c-0.7,0-1.3,0.3-1.8,0.8s-0.8,1.2-0.8,2.1L110.3,252.6z"/>
			<path class="st1" d="M123.8,257.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.2c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5s1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2c0.2,0.9,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C125.9,257,124.9,257.4,123.8,257.4z M119.4,250.1c0,2.5,0.5,4.3,1.4,5.4
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8s1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8s-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4
				L119.4,250.1z M123.9,256c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1
				s1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8C125.5,255.6,124.8,256,123.9,256z M121.2,250.1
				c0,2.2,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6
				c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L121.2,250.1z"/>
			<path class="st1" d="M135.1,257.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.2c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5s1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2
				c0.2,0.9,0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C137.1,257,136.2,257.4,135.1,257.4z M130.7,250.1
				c0,2.5,0.5,4.3,1.4,5.4c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4L130.7,250.1z M135.1,256c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2
				c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8
				C136.7,255.6,135.9,256,135.1,256z M132.4,250.1c0,2.2,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1s1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5
				c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L132.4,250.1z"/>
		</g>
		<g>
			<path class="st1" d="M92.6,172.5v-1.7h9.3v1.4c-0.9,1-1.8,2.3-2.7,3.9s-1.6,3.3-2.1,5c-0.3,1.2-0.6,2.5-0.7,3.9h-1.8
				c0-1.1,0.2-2.5,0.7-4.1s1-3.1,1.8-4.6c0.8-1.5,1.6-2.7,2.5-3.8L92.6,172.5L92.6,172.5z"/>
			<path class="st1" d="M103.5,177.9c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.6-2.4s1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5s1.1,0.8,1.4,1.3
				s0.7,1.3,0.9,2.1s0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4s-1.6,0.8-2.6,0.8c-1.4,0-2.5-0.5-3.2-1.5
				C104,182.5,103.5,180.6,103.5,177.9L103.5,177.9z M105.3,177.9c0,2.4,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2s1.5-0.4,2-1.2
				s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7s-1.2-1.2-2.1-1.2s-1.4,0.3-1.9,1C105.7,173.9,105.3,175.5,105.3,177.9L105.3,177.9z"/>
			<path class="st1" d="M114.7,177.9c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.6-2.4s1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5s1.1,0.8,1.4,1.3
				s0.7,1.3,0.9,2.1s0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4s-1.6,0.8-2.6,0.8c-1.4,0-2.5-0.5-3.2-1.5
				C115.2,182.5,114.7,180.6,114.7,177.9L114.7,177.9z M116.4,177.9c0,2.4,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2s1.5-0.4,2-1.2
				s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7s-1.2-1.2-2.1-1.2s-1.4,0.3-1.9,1C116.8,173.9,116.4,175.5,116.4,177.9L116.4,177.9z"/>
		</g>
		<g>
			<path class="st1" d="M96.6,185.2h-2.3v-0.3c0-1.1,0.2-2.5,0.7-4.1c0.4-1.6,1-3.2,1.8-4.7c0.7-1.3,1.4-2.4,2.2-3.4h-7.1l0.4-0.4
				v-1.8h9.8v1.7l-0.1,0.1c-0.9,0.9-1.8,2.2-2.7,3.8s-1.6,3.2-2.1,4.9c-0.4,1.2-0.6,2.5-0.7,3.9L96.6,185.2z M94.8,184.7h1.3
				c0.1-1.3,0.3-2.6,0.7-3.8c0.5-1.7,1.2-3.4,2.1-5c0.9-1.6,1.8-2.9,2.7-3.8v-1h-8.8v1.2h7.3l-0.4,0.4c-0.9,1-1.7,2.2-2.5,3.7
				c-0.8,1.5-1.4,3-1.8,4.6C95.1,182.4,94.8,183.6,94.8,184.7z"/>
			<path class="st1" d="M108.2,185.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.6l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5s1.6-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2c0.2,0.8,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C110.2,185.1,109.3,185.4,108.2,185.4z M103.8,178.5c0.1,2.3,0.5,4,1.4,5.1
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8s1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8s-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4
				L103.8,178.5L103.8,178.5z M108.2,184c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7
				c0.5-0.8,1.2-1.1,2.1-1.1s1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8C109.8,183.6,109.2,184,108.2,184z
				 M105.7,178.1c0,2.2,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6
				c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L105.7,178.1z"/>
			<path class="st1" d="M119.3,185.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.6l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5s1.6-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2c0.2,0.8,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C121.3,185.1,120.4,185.4,119.3,185.4z M114.9,178.5c0.1,2.3,0.5,4,1.4,5.1
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8s1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8s-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.4-0.5,4
				L114.9,178.5L114.9,178.5z M119.3,184c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7
				c0.5-0.8,1.2-1.1,2.1-1.1s1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8C120.9,183.6,120.2,184,119.3,184z
				 M116.8,178.1c0,2.2,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6
				c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L116.8,178.1z"/>
		</g>
		<g>
			<path class="st1" d="M128.9,116.2c-0.7-0.3-1.3-0.7-1.6-1.1s-0.5-1.1-0.5-1.8c0-1,0.4-1.9,1.1-2.6s1.7-1.1,3-1.1s2.3,0.4,3,1.1
				s1.1,1.6,1.1,2.7c0,0.7-0.2,1.2-0.5,1.7s-0.9,0.9-1.6,1.1c0.9,0.3,1.6,0.8,2,1.4c0.4,0.6,0.7,1.4,0.7,2.3c0,1.2-0.4,2.2-1.3,3.1
				c-0.9,0.9-2,1.2-3.4,1.2c-1.4,0-2.6-0.4-3.4-1.2s-1.3-1.9-1.3-3.1c0-0.9,0.2-1.7,0.7-2.3S128.1,116.3,128.9,116.2L128.9,116.2z
				 M128.1,119.8c0,0.5,0.1,1,0.4,1.5s0.6,0.8,1.1,1.1s1,0.4,1.5,0.4c0.8,0,1.5-0.3,2.1-0.8c0.6-0.5,0.8-1.2,0.8-2.1
				s-0.3-1.6-0.8-2.1s-1.3-0.8-2.1-0.8c-0.8,0-1.5,0.3-2.1,0.8C128.4,118.2,128.1,118.9,128.1,119.8L128.1,119.8z M128.6,113.2
				c0,0.7,0.2,1.2,0.7,1.7s1,0.6,1.7,0.6s1.2-0.2,1.7-0.6s0.7-0.9,0.7-1.6s-0.2-1.2-0.7-1.6s-1-0.7-1.7-0.7s-1.2,0.2-1.7,0.6
				S128.6,112.5,128.6,113.2L128.6,113.2z"/>
			<path class="st1" d="M137.4,116.8c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.6-2.4s1.6-0.8,2.6-0.8c0.8,0,1.4,0.2,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4s-1.6,0.8-2.6,0.8
				c-1.4,0-2.5-0.5-3.2-1.5C137.9,121.4,137.4,119.5,137.4,116.8L137.4,116.8z M139.2,116.8c0,2.3,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2
				s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7s-1.2-1.2-2.1-1.2c-0.9,0-1.4,0.3-1.9,1C139.4,112.8,139.2,114.4,139.2,116.8
				L139.2,116.8z"/>
			<path class="st1" d="M148.4,116.8c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.6-2.4s1.6-0.8,2.6-0.8c0.8,0,1.4,0.2,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4s-1.6,0.8-2.6,0.8
				c-1.4,0-2.5-0.5-3.2-1.5C148.9,121.4,148.4,119.5,148.4,116.8L148.4,116.8z M150.2,116.8c0,2.3,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2
				s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7s-1.2-1.2-2.1-1.2c-0.9,0-1.4,0.3-1.9,1C150.7,112.8,150.4,114.4,150.2,116.8
				L150.2,116.8z"/>
		</g>
		<g>
			<path class="st1" d="M130.9,124.3c-1.5,0-2.7-0.4-3.6-1.3s-1.4-2-1.4-3.3c0-1,0.3-1.8,0.8-2.5c0.4-0.5,0.9-0.9,1.5-1.2
				c-0.5-0.3-0.9-0.6-1.2-1c-0.4-0.5-0.6-1.2-0.6-1.9c0-1.1,0.4-2.1,1.2-2.8c0.8-0.7,1.9-1.1,3.2-1.1s2.4,0.4,3.2,1.2
				c0.8,0.8,1.2,1.7,1.2,2.8c0,0.7-0.2,1.3-0.6,1.9c-0.3,0.4-0.7,0.7-1.2,1c0.7,0.3,1.2,0.7,1.6,1.3c0.5,0.7,0.7,1.5,0.7,2.4
				c0,1.3-0.5,2.4-1.4,3.3C133.7,123.9,132.4,124.3,130.9,124.3z M130.9,109.8c-1.2,0-2.1,0.3-2.8,1c-0.7,0.7-1,1.5-1,2.4
				c0,0.6,0.2,1.2,0.5,1.6s0.8,0.8,1.5,1.1l0.2,0.1v0.4h-0.2c-0.8,0.2-1.4,0.6-1.9,1.2c-0.4,0.6-0.7,1.3-0.7,2.2
				c0,1.2,0.4,2.2,1.2,2.9c0.8,0.8,1.9,1.2,3.2,1.2c1.4,0,2.4-0.4,3.2-1.2c0.8-0.8,1.2-1.7,1.2-2.9c0-0.8-0.2-1.5-0.6-2.1
				c-0.4-0.6-1-1-1.9-1.3l-0.7-0.2l0.7-0.3c0.7-0.3,1.2-0.6,1.5-1.1c0.3-0.4,0.5-1,0.5-1.6c0-1-0.3-1.8-1.1-2.5
				C133.1,110.2,132.1,109.8,130.9,109.8z M130.9,122.9c-0.6,0-1.1-0.1-1.6-0.4s-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1-0.4-1.6l0,0l0,0
				l0,0c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9s1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.3s-0.3,1.7-0.9,2.2
				C132.7,122.7,131.9,122.9,130.9,122.9z M128.2,119.8c0,0.5,0.1,0.9,0.3,1.3s0.5,0.7,1,1c0.4,0.2,0.9,0.4,1.4,0.4
				c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9s-0.3-1.4-0.8-1.9s-1.2-0.8-1.9-0.8c-0.8,0-1.4,0.2-1.9,0.7
				C128.6,118.3,128.2,119,128.2,119.8z M130.9,115.8c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1.1-0.7-1.8l0,0c0-0.7,0.2-1.2,0.7-1.7
				s1.1-0.7,1.8-0.7c0.7,0,1.3,0.2,1.8,0.7s0.7,1.1,0.7,1.8c0,0.7-0.2,1.3-0.7,1.7C132.2,115.4,131.7,115.8,130.9,115.8z
				 M130.9,111.2c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,0.8-0.6,1.4l0,0c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6
				c0.6,0,1.1-0.2,1.5-0.6c0.4-0.4,0.6-0.8,0.6-1.4s-0.2-1.1-0.6-1.5C132.1,111.3,131.6,111.2,130.9,111.2z"/>
			<path class="st1" d="M142.1,124.3c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.6l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5s1.6-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2
				c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C144.1,124,143.2,124.3,142.1,124.3z M137.7,117.4
				c0.1,2.3,0.5,4,1.4,5.1c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.4-0.5,4L137.7,117.4L137.7,117.4z M142.1,122.9c-0.9,0-1.6-0.4-2.2-1.3
				c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8
				s-0.3,4-0.9,4.8C143.7,122.5,142.9,122.9,142.1,122.9z M139.4,117c0,2.2,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1
				s1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9
				c-0.6,0.8-0.9,2.4-0.9,4.7L139.4,117z"/>
			<path class="st1" d="M153.2,124.3c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.6l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5s1.6-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2
				c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C155.2,124,154.2,124.3,153.2,124.3z M148.8,117.4
				c0.1,2.3,0.5,4,1.4,5.1c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3s-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4L148.8,117.4L148.8,117.4z M153.2,122.9c-0.9,0-1.6-0.4-2.2-1.3
				c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8
				s-0.3,4-0.9,4.8C154.9,122.5,154.1,122.9,153.2,122.9z M150.6,117c0,2.2,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1
				c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9
				c-0.6,0.8-0.9,2.4-0.9,4.7L150.6,117z"/>
		</g>
		<g>
			<path class="st1" d="M220.9,302.4V299h-6.2v-1.6l6.5-9.3h1.4v9.3h1.9v1.6h-1.9v3.4H220.9z M220.9,297.4v-6.5l-4.5,6.5H220.9z"/>
			<path class="st1" d="M226.4,295.4c0-1.7,0.2-3.1,0.5-4.1c0.4-1,0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4c-0.7,0.6-1.6,0.9-2.6,0.9
				c-1.4,0-2.5-0.5-3.2-1.5C226.9,300,226.4,298.1,226.4,295.4L226.4,295.4z M228.2,295.4c0,2.3,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2
				s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.5-0.8-1.2-1.2-2.1-1.2s-1.4,0.3-1.9,1C228.5,291.4,228.2,293,228.2,295.4
				L228.2,295.4z"/>
			<path class="st1" d="M237.5,295.4c0-1.7,0.2-3.1,0.5-4.1c0.4-1,0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4c-0.7,0.6-1.6,0.9-2.6,0.9
				c-1.4,0-2.5-0.5-3.2-1.5C238,300,237.5,298.1,237.5,295.4L237.5,295.4z M239.2,295.4c0,2.3,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2
				s1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.5-0.8-1.2-1.2-2.1-1.2s-1.4,0.3-1.9,1C239.7,291.4,239.4,293,239.2,295.4
				L239.2,295.4z"/>
		</g>
		<g>
			<path class="st1" d="M222.9,302.6h-2.3v-3.4h-6.2v-1.9v-0.1l6.6-9.4h1.8v9.3h1.9v2.1h-1.9L222.9,302.6L222.9,302.6z M221.2,302.1
				h1.3v-3.4h1.9v-1.1h-2v-9.3h-1l-6.4,9.1v1.3h6.2V302.1z M221.2,297.6H216l5.2-7.5V297.6z M216.9,297.1h3.8v-5.4L216.9,297.1z"/>
			<path class="st1" d="M231.1,302.9c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6V295c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5c0.7-0.6,1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2
				s0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C233.1,302.6,232.2,302.9,231.1,302.9z M226.7,295.6
				c0,2.5,0.5,4.3,1.4,5.4c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3c-0.5-0.3-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.4-0.5,4L226.7,295.6z M231.1,301.5c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8
				v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8
				C232.7,301.1,232,301.5,231.1,301.5z M228.5,295.6c0,2.1,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1s1.3-0.3,1.8-1.1
				c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7
				L228.5,295.6z"/>
			<path class="st1" d="M242.2,302.9c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6V295c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5c0.7-0.6,1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2
				s0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C244.2,302.6,243.2,302.9,242.2,302.9z M237.8,295.6
				c0,2.5,0.5,4.3,1.4,5.4c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3c-0.5-0.3-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4L237.8,295.6z M242.2,301.5c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8
				v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8
				C243.9,301.1,243.1,301.5,242.2,301.5z M239.6,295.6c0,2.1,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1s1.3-0.3,1.8-1.1
				c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7
				L239.6,295.6z"/>
		</g>
		<g>
			<path class="st1" d="M261.1,253.2l1.8-0.2c0.2,1,0.6,1.7,1,2.1s1.1,0.7,1.8,0.7c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
				s-0.3-1.5-0.8-2s-1.2-0.8-2-0.8c-0.3,0-0.8,0.1-1.2,0.2l0.2-1.5c0.1,0,0.2,0,0.3,0c0.8,0,1.4-0.2,2-0.6s0.9-1,0.9-1.8
				c0-0.6-0.2-1.2-0.6-1.6c-0.4-0.4-1-0.6-1.7-0.6c-0.7,0-1.2,0.2-1.7,0.6s-0.8,1.1-0.9,1.9l-1.8-0.3c0.2-1.2,0.7-2.1,1.5-2.7
				c0.8-0.6,1.7-1,2.8-1c0.8,0,1.5,0.2,2.2,0.5c0.7,0.3,1.2,0.8,1.5,1.4c0.4,0.6,0.5,1.2,0.5,1.9c0,0.6-0.2,1.2-0.5,1.7
				c-0.3,0.5-0.8,0.9-1.5,1.2c0.9,0.2,1.5,0.6,2,1.2s0.7,1.4,0.7,2.3c0,1.2-0.5,2.3-1.4,3.2c-0.9,0.9-2.1,1.3-3.5,1.3
				c-1.3,0-2.3-0.4-3.1-1.1C261.6,255.2,261.1,254.2,261.1,253.2L261.1,253.2z"/>
			<path class="st1" d="M272.2,249.9c0-1.7,0.2-3.1,0.5-4.1c0.4-1,0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1c-0.3,1-0.9,1.8-1.6,2.4
				c-0.7,0.6-1.6,0.9-2.6,0.9c-1.4,0-2.5-0.5-3.2-1.5C272.6,254.5,272.2,252.6,272.2,249.9L272.2,249.9z M274,249.9
				c0,2.4,0.3,3.9,0.8,4.7c0.6,0.8,1.2,1.2,2,1.2c0.8,0,1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2
				c-0.9,0-1.4,0.3-1.9,1C274.4,245.9,274,247.5,274,249.9L274,249.9z"/>
			<path class="st1" d="M283.4,249.9c0-1.7,0.2-3.1,0.5-4.1c0.4-1,0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				c0.6,0.3,1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1c-0.3,1-0.9,1.8-1.6,2.4
				c-0.7,0.6-1.6,0.9-2.6,0.9c-1.4,0-2.5-0.5-3.2-1.5C283.9,254.5,283.4,252.6,283.4,249.9L283.4,249.9z M285.1,249.9
				c0,2.4,0.3,3.9,0.8,4.7c0.6,0.8,1.2,1.2,2,1.2c0.8,0,1.5-0.4,2-1.2s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2
				c-0.9,0-1.4,0.3-1.9,1C285.5,245.9,285.1,247.5,285.1,249.9L285.1,249.9z"/>
		</g>
		<g>
			<path class="st1" d="M265.6,257.4c-1.3,0-2.4-0.4-3.3-1.2c-0.9-0.8-1.4-1.8-1.5-3.1v-0.5l0.4,0.2l1.9-0.2v0.2
				c0.2,0.9,0.5,1.6,1,2c0.4,0.4,1,0.6,1.6,0.6c0.8,0,1.4-0.3,1.9-0.8s0.8-1.2,0.8-2s-0.2-1.3-0.7-1.8s-1.1-0.7-1.8-0.7
				c-0.3,0-0.7,0.1-1.2,0.2l-0.4,0.1l0.3-2.2h0.3c0.1,0,0.1,0,0.2,0c0.7,0,1.3-0.2,1.9-0.5c0.5-0.3,0.8-0.9,0.8-1.6
				c0-0.6-0.2-1-0.6-1.4s-0.9-0.6-1.5-0.6s-1.1,0.2-1.5,0.6c-0.4,0.4-0.7,1-0.8,1.8v0.3l-2.3-0.4v-0.2c0.2-1.2,0.7-2.2,1.5-2.9
				c0.8-0.7,1.8-1,3-1c0.8,0,1.6,0.2,2.3,0.5c0.7,0.4,1.2,0.8,1.6,1.5c0.4,0.6,0.6,1.3,0.6,2s-0.2,1.3-0.5,1.8c-0.3,0.4-0.6,0.7-1,1
				c0.6,0.2,1.1,0.6,1.5,1.1c0.5,0.6,0.8,1.5,0.8,2.5c0,1.3-0.5,2.4-1.4,3.4C268.4,257,267,257.4,265.6,257.4z M261.4,253.4
				c0.2,1,0.6,1.8,1.3,2.5c0.8,0.7,1.8,1.1,3,1.1c1.3,0,2.4-0.4,3.3-1.2c0.9-0.8,1.3-1.8,1.3-3c0-0.9-0.2-1.6-0.6-2.2
				c-0.4-0.6-1-0.9-1.8-1.1l-0.7-0.2l0.7-0.3c0.6-0.3,1.1-0.7,1.4-1.1c0.3-0.5,0.5-1,0.5-1.6s-0.2-1.2-0.5-1.7
				c-0.3-0.5-0.8-1-1.4-1.3c-0.6-0.3-1.3-0.5-2-0.5c-1.1,0-2,0.3-2.7,0.9c-0.7,0.6-1.1,1.4-1.3,2.3l1.3,0.2c0.2-0.8,0.5-1.4,0.9-1.8
				c0.5-0.5,1.1-0.7,1.9-0.7c0.8,0,1.4,0.2,1.9,0.7s0.7,1.1,0.7,1.8c0,0.9-0.3,1.6-1,2c-0.6,0.4-1.4,0.6-2.2,0.6c0,0,0,0-0.1,0
				l-0.1,0.9c0.3-0.1,0.7-0.1,0.9-0.1c0.9,0,1.6,0.3,2.2,0.9c0.6,0.6,0.9,1.3,0.9,2.2s-0.3,1.7-0.9,2.3c-0.6,0.6-1.4,0.9-2.3,0.9
				c-0.8,0-1.4-0.2-1.9-0.7c-0.5-0.4-0.8-1.1-1.1-2.1L261.4,253.4z"/>
			<path class="st1" d="M276.9,257.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.2c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5c0.7-0.6,1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2
				c0.2,0.9,0.3,2,0.3,3.4c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C278.9,257,278,257.4,276.9,257.4z M272.5,250.1
				c0,2.5,0.5,4.3,1.4,5.4c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.7-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4
				c0-1.4-0.1-2.5-0.3-3.3c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8
				c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4L272.5,250.1z M276.9,256c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2
				c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1s1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8
				C278.5,255.6,277.8,256,276.9,256z M274.2,250.1c0,2.2,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1
				c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7
				L274.2,250.1z"/>
			<path class="st1" d="M288,257.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.2c0-1.6,0.2-2.9,0.5-3.9
				c0.4-1.1,0.9-1.9,1.6-2.5s1.7-0.9,2.8-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2c0.2,0.9,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C290,257,289,257.4,288,257.4z M283.5,250.1c0,2.5,0.5,4.3,1.4,5.4
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.7-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8s-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4
				L283.5,250.1z M288,256c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1
				c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8C289.6,255.6,289,256,288,256z M285.4,250.1
				c0,2.2,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6
				c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L285.4,250.1z"/>
		</g>
		<g>
			<path class="st1" d="M286,183.2v1.7h-9.5c0-0.4,0.1-0.8,0.2-1.2c0.2-0.6,0.6-1.3,1.2-1.9c0.6-0.6,1.3-1.4,2.3-2.2
				c1.6-1.3,2.6-2.3,3.1-3s0.8-1.4,0.8-2.1s-0.2-1.3-0.8-1.8s-1.1-0.7-1.9-0.7c-0.9,0-1.5,0.2-2,0.8s-0.8,1.2-0.8,2.1l-1.8-0.2
				c0.1-1.4,0.6-2.4,1.4-3.1s1.9-1.1,3.2-1.1c1.3,0,2.5,0.4,3.3,1.1c0.8,0.7,1.2,1.7,1.2,2.8c0,0.6-0.1,1.1-0.4,1.7
				s-0.6,1.1-1.2,1.8c-0.6,0.7-1.4,1.4-2.7,2.5c-1.1,0.9-1.7,1.5-2,1.8c-0.3,0.3-0.6,0.6-0.7,0.9L286,183.2L286,183.2z"/>
			<path class="st1" d="M288,177.9c0-1.7,0.2-3.1,0.5-4.1c0.3-1,0.9-1.8,1.6-2.4s1.6-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5
				s1.1,0.8,1.4,1.3s0.7,1.3,0.9,2.1s0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.6,2.4s-1.6,0.8-2.6,0.8c-1.4,0-2.5-0.5-3.2-1.5
				C288.4,182.5,287.9,180.6,288,177.9L288,177.9z M289.8,177.9c0,2.4,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2c0.8,0,1.5-0.4,2-1.2
				s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7s-1.2-1.2-2.1-1.2c-0.9,0-1.4,0.3-1.9,1C290,173.9,289.8,175.5,289.8,177.9L289.8,177.9z"/>
			<path class="st1" d="M299.1,177.9c0-1.7,0.2-3.1,0.5-4.1s0.9-1.8,1.5-2.4s1.5-0.8,2.6-0.8c0.8,0,1.4,0.1,2,0.5s1.1,0.8,1.4,1.3
				s0.7,1.3,0.9,2.1s0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1s-0.9,1.8-1.5,2.4s-1.5,0.8-2.6,0.8c-1.4,0-2.5-0.5-3.2-1.5
				C299.5,182.5,299.1,180.6,299.1,177.9L299.1,177.9z M300.9,177.9c0,2.4,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2s1.5-0.4,2-1.2
				s0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7s-1.2-1.2-2.1-1.2s-1.5,0.3-1.9,1C301.1,173.9,300.9,175.5,300.9,177.9L300.9,177.9z"/>
		</g>
		<g>
			<path class="st1" d="M286.2,185.2h-10V185c0-0.5,0.1-0.9,0.2-1.3c0.2-0.7,0.7-1.3,1.2-2c0.5-0.6,1.3-1.4,2.3-2.2
				c1.5-1.3,2.6-2.3,3.1-3s0.8-1.4,0.8-2s-0.2-1.2-0.7-1.6c-0.5-0.4-1-0.7-1.8-0.7c-0.8,0-1.4,0.2-1.9,0.7s-0.7,1.1-0.7,1.9v0.3
				l-2.3-0.2v-0.2c0.1-1.4,0.6-2.5,1.5-3.2s2-1.1,3.4-1.1s2.6,0.4,3.4,1.2s1.3,1.8,1.3,3c0,0.6-0.1,1.2-0.4,1.8
				c-0.2,0.6-0.7,1.2-1.2,1.8c-0.6,0.6-1.5,1.5-2.7,2.5c-1.1,0.9-1.7,1.5-2,1.8c-0.2,0.2-0.3,0.3-0.5,0.5h6.8v2.2H286.2z
				 M276.9,184.7h9v-1.2h-7.2l0.2-0.4c0.2-0.3,0.5-0.7,0.8-1c0.3-0.3,0.9-0.9,2-1.8c1.2-1,2.1-1.9,2.7-2.5c0.5-0.6,0.9-1.1,1.1-1.7
				c0.2-0.5,0.3-1.1,0.3-1.6c0-1.1-0.4-1.9-1.1-2.6c-0.7-0.7-1.8-1.1-3.1-1.1c-1.3,0-2.3,0.3-3.1,1c-0.7,0.6-1.1,1.5-1.3,2.7
				l1.3,0.1c0.1-0.8,0.3-1.5,0.8-2c0.6-0.6,1.3-0.8,2.2-0.8s1.6,0.3,2.1,0.8s0.8,1.2,0.8,1.9s-0.3,1.5-0.9,2.3
				c-0.6,0.8-1.6,1.8-3.2,3.1c-1,0.8-1.7,1.5-2.3,2.1c-0.5,0.6-0.9,1.2-1.1,1.8C276.9,184.1,276.9,184.4,276.9,184.7z"/>
			<path class="st1" d="M292.5,185.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.3-1.5-6v-0.6l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5s1.6-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2c0.2,0.8,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C294.5,185.1,293.6,185.4,292.5,185.4z M288.1,178.5c0.1,2.3,0.5,4,1.4,5.1
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.6-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8s-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4
				L288.1,178.5L288.1,178.5z M292.5,184c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7
				c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8C294.2,183.6,293.5,184,292.5,184z
				 M290,178.1c0,2.1,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6
				c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L290,178.1z"/>
			<path class="st1" d="M303.6,185.4c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.6l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5s1.6-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.7,1.3,0.9,2.2c0.2,0.8,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C305.8,185.1,304.8,185.4,303.6,185.4z M299.2,178.5c0.1,2.3,0.5,4,1.4,5.1
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8s1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8s-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4
				L299.2,178.5L299.2,178.5z M303.6,184c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7
				c0.5-0.8,1.2-1.1,2.1-1.1s1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8c0,2.4-0.3,4-0.9,4.8C305.4,183.6,304.6,184,303.6,184z
				 M301.1,178.1c0,2.1,0.3,3.6,0.8,4.3s1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6
				c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L301.1,178.1z"/>
		</g>
		<g>
			<path class="st1" d="M249.6,123.8h-1.8v-11.2c-0.4,0.4-1,0.8-1.7,1.2s-1.3,0.7-1.9,0.9v-1.7c1-0.5,1.8-1,2.6-1.7s1.3-1.3,1.6-1.9
				h1.1L249.6,123.8L249.6,123.8z"/>
			<path class="st1" d="M254.1,116.8c0-1.7,0.2-3.1,0.5-4.1c0.4-1,0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.2,2,0.5
				s1.1,0.8,1.4,1.3c0.4,0.6,0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1c-0.3,1-0.9,1.8-1.6,2.4s-1.6,0.8-2.6,0.8
				c-1.4,0-2.5-0.5-3.2-1.5C254.6,121.4,254.1,119.5,254.1,116.8L254.1,116.8z M255.9,116.8c0,2.3,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2
				c0.8,0,1.5-0.4,2-1.2c0.6-0.8,0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2c-0.9,0-1.4,0.3-1.9,1
				C256.1,112.8,255.9,114.4,255.9,116.8L255.9,116.8z"/>
			<path class="st1" d="M265.2,116.8c0-1.7,0.2-3.1,0.5-4.1c0.4-1,0.9-1.8,1.6-2.4c0.7-0.6,1.6-0.8,2.6-0.8c0.8,0,1.4,0.2,2,0.5
				s1.1,0.8,1.4,1.3c0.4,0.6,0.7,1.3,0.9,2.1c0.2,0.8,0.3,2,0.3,3.4c0,1.7-0.2,3-0.5,4.1c-0.3,1-0.9,1.8-1.6,2.4s-1.6,0.8-2.6,0.8
				c-1.4,0-2.5-0.5-3.2-1.5C265.6,121.4,265.2,119.5,265.2,116.8L265.2,116.8z M267,116.8c0,2.3,0.3,3.9,0.8,4.7s1.2,1.2,2,1.2
				c0.8,0,1.5-0.4,2-1.2c0.6-0.8,0.8-2.3,0.8-4.7s-0.3-3.9-0.8-4.7c-0.6-0.8-1.2-1.2-2.1-1.2c-0.9,0-1.4,0.3-1.9,1
				C267.4,112.8,267,114.4,267,116.8L267,116.8z"/>
		</g>
		<g>
			<path class="st1" d="M249.4,124.5v-0.4h-1.8v-10.9c-0.4,0.3-0.8,0.6-1.3,0.9c-0.8,0.4-1.4,0.7-1.9,0.9l-0.3,0.1V113l0.1-0.1
				c1-0.4,1.8-1,2.5-1.6s1.2-1.2,1.5-1.8l0.1-0.1h1.5v14.4h0.4L249.4,124.5z M248.1,123.7h1.2v-13.9h-0.7c-0.3,0.6-0.8,1.2-1.5,1.8
				c-0.7,0.6-1.6,1.2-2.5,1.7v1.2c0.4-0.2,0.9-0.4,1.5-0.8c0.7-0.4,1.2-0.8,1.6-1.2l0.4-0.4V123.7z"/>
			<path class="st1" d="M258.8,124.3c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.2-1.5-6v-0.6l0,0c0-1.5,0.2-2.6,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5c0.7-0.6,1.7-0.9,2.8-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4s0.7,1.3,0.9,2.2c0.2,0.8,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C260.8,124,259.9,124.3,258.8,124.3z M254.4,117.4c0.1,2.3,0.5,4,1.4,5.1
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.7-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1c-0.4-0.5-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8c-0.6,0.5-1.1,1.3-1.5,2.3
				c-0.3,1-0.5,2.3-0.5,4L254.4,117.4L254.4,117.4z M258.8,122.9c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2
				c0-2.3,0.3-3.8,1-4.7c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8s-0.3,4-0.9,4.8
				C260.5,122.5,259.6,122.9,258.8,122.9z M256.1,117c0,2.1,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1
				c0.5-0.7,0.8-2.3,0.8-4.5c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7
				L256.1,117z"/>
			<path class="st1" d="M269.9,124.3c-1.4,0-2.6-0.5-3.4-1.6c-1-1.2-1.5-3.3-1.5-6v-0.6l0,0c0-1.5,0.2-2.7,0.5-3.6
				c0.4-1.1,0.9-1.9,1.6-2.5c0.7-0.6,1.7-0.9,2.7-0.9c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4s0.7,1.3,0.9,2.2c0.2,0.8,0.3,2,0.3,3.4
				c0,1.7-0.2,3.1-0.5,4.2c-0.4,1.1-0.9,1.9-1.6,2.5C271.9,124,271,124.3,269.9,124.3z M265.5,117.3c0.1,2.3,0.5,4,1.4,5.1
				c0.8,0.9,1.8,1.4,3.1,1.4c1,0,1.8-0.3,2.4-0.8c0.7-0.5,1.1-1.3,1.5-2.3c0.3-1,0.5-2.3,0.5-4c0-1.4-0.1-2.5-0.3-3.3
				c-0.2-0.8-0.5-1.5-0.9-2.1s-0.8-1-1.3-1.3s-1.2-0.4-1.9-0.4c-1,0-1.8,0.3-2.4,0.8c-0.6,0.5-1.1,1.3-1.5,2.3c-0.3,1-0.5,2.3-0.5,4
				L265.5,117.3L265.5,117.3z M269.9,122.9c-0.9,0-1.6-0.4-2.2-1.3c-0.6-0.8-0.9-2.4-0.9-4.8v-0.2c0-2.3,0.3-3.8,1-4.7
				c0.5-0.8,1.2-1.1,2.1-1.1c0.9,0,1.7,0.4,2.3,1.3c0.6,0.8,0.9,2.4,0.9,4.8s-0.3,4-0.9,4.8C271.5,122.5,270.8,122.9,269.9,122.9z
				 M267.2,117c0,2.1,0.3,3.6,0.8,4.3c0.5,0.7,1.1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.8-1.1c0.5-0.7,0.8-2.3,0.8-4.5
				c0-2.3-0.3-3.8-0.8-4.6c-0.5-0.7-1.1-1.1-1.9-1.1c-0.7,0-1.3,0.3-1.7,0.9c-0.6,0.8-0.9,2.4-0.9,4.7L267.2,117z"/>
		</g>
	</g>
</g>
</svg>
`