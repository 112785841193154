import { useIntl } from "react-intl";
import Styled from "../../../../components/ui/styled";
import Text from "../../../../components/ui/text";
import { Bullet, Info, Item, List } from "./pie-legend.styles";

type ItemProps = {
    name: string,
    value: string | number,
    color: string,
    info: any
}

type Props = {
    data: ItemProps[]
}

const PieLegend = ({ data }: Props) => {
    const intl = useIntl();

    return <List>
        {data.map(({ name, value, color, info }: any, index) => {

            let suffix = '';

            if (info) {
                if (info.currentMeasurementType === "Milliseconds" && info.displayMeasurementType === "Minutes") {
                    value = Math.ceil((value / 1000) / 60);
                }

                if (info.displayMeasurementType === "Minutes") {
                    suffix = ` ${intl.formatMessage({ id: 'dashboard:minutes', 'defaultMessage': 'Minutes' })}`;
                }
            }

            return <Item key={name+index}>
                <Bullet color={color} />
                <Info>
                    <Styled marginBottom="0.3rem">
                        <Text variant="medium" fontWeight={700}>{name}</Text>
                    </Styled>
                    <Text>{value}{suffix}</Text>
                </Info>
            </Item>
        }
        )}
    </List>
}

export default PieLegend;