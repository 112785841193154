import { ContextFilterPresetValues, ContextFilterPresets } from "../../../../enums/overview";
import moment from "moment";

export enum BenefitsTitle {
    CO2_REDUCED = "co2 reduced",
    RAILCARS_OF_COAL_SAVED = "tonnes of coal saved"
}

export enum QuantityUnit {
    THOUSAND = 'k',
    MILLION = 'm',
    BILLION = 'b',
    TRILLION = 't',
}

const ONE = 1;
const ONE_THOUSAND = 1000;

export const convertBenefitsValues = (result: number, unit: string = '', title: string = '') => {
    let currencyUnit = '';
    let finalResult = 0;
    let formattedValue = '';

    if (title.toLocaleLowerCase() === BenefitsTitle.RAILCARS_OF_COAL_SAVED) {
        result = result * 100;
        result = Math.round(result);
    }

    if (result < 1000) {
        const roundedResult = Math.round(result);

        if (roundedResult === ONE_THOUSAND) {
            return `${unit}${ONE}${QuantityUnit.THOUSAND}`
        } else {
            return `${unit}${roundedResult}`; //removes decimals
        }

    } else if (result < 1000000) {
        finalResult = Math.floor(result / 10) / 100;
        currencyUnit = QuantityUnit.THOUSAND;
    } else if (result < 1000000000) {
        finalResult = Math.floor(result / 10000) / 100;
        currencyUnit = QuantityUnit.MILLION;
    } else if (result < 1000000000000) {
        finalResult = Math.floor(result / 10000000) / 100;
        currencyUnit = QuantityUnit.BILLION;
    } else {
        finalResult = Math.floor(result / 10000000000) / 100;
        currencyUnit = QuantityUnit.TRILLION;
    }

    if (finalResult % 1 === 0) {
        // If result is integer -> no decimals
        formattedValue = finalResult.toString();
    } else {
        // If it has decimals -> decimal places
        formattedValue = finalResult.toFixed(2);
    }

    return `${unit}${formattedValue}${currencyUnit}`;
};

//HOW VALUES UNDER 1000 ARE CONVERTED with convertBenefitsValues function
//0.233579792       -> 0
//0.523357979       -> 1

//1.233579792       -> 1
//1.7177403648      -> 2

//10.233579792      -> 10
//10.7177403648     -> 11

//998.7177403648    -> 999

//999.233579792     -> 999
//999.7177403648    -> 1k

//1000.7177403648   -> 1k
//1010.7177403648   -> 1.01k

//HOW NUMBER OF CARS OF COAL ARE CONVERTED TO TONNES
//9.5 cars          -> 950
//9.99              -> 999
//9.995             -> 999
//9.999             -> 1k
//10 cars           -> 1k
//10.1 cars         -> 1.01k
//10.2 cars         -> 1.02k
//10.3 cars         -> 1.03k
//10.9 cars         -> 1.09k

export const applyDatePreset = (key: string): { from: string | null; to: string | null; type: string | null } => {
    let value: number = ContextFilterPresetValues[key];
    let from: Date | null = new Date();
    let to: Date | null = new Date();
    let type: string | null = null;

    switch (key) {
        case ContextFilterPresets.Last7Days:
        case ContextFilterPresets.Last14Days:
        case ContextFilterPresets.Last30Days:
        case ContextFilterPresets.Last12Months:
            from.setDate(from.getDate() - Math.abs(value));
            break;
        case ContextFilterPresets.Lifetime:
            from = null;
            to = null;
            type = "lifetime";
            break;
    }

    return {
        from: from ? moment(from).format("YYYY-MM-DD") : null,
        to: to ? moment(to).format("YYYY-MM-DD") : null,
        type
    };
};