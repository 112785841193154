import styled from "styled-components";
import base from "../../../../themes/base/base";

export const Section = {
    Container: styled.div`
      display: flex;
      border-radius: 8px;
      background: ${base.colors.neutral.grey[800]};
      flex-direction: column;
      padding: 1.5rem;
      gap: 1rem;
      flex: 5;
      margin-top: 1rem;
      svg {
        pointer-events: all;
      }
    `
  };

  export const TabWrapper = styled.div`
    color: #999;
    padding-top: 0.5rem;
    width: 100%;

    button{
        border-bottom: 2px solid;
        font-size: 16px;
        letter-spacing: 0.16px;
        padding: 16px 32px 8px 32px;
    }
    .MuiTab-root.Mui-selected {
        color: #EE7127;
    }
    .MuiTabs-indicator {
        background-color: #EE7127;
    }
  `