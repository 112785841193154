//To get the time difference in minutes and seconds
export const getTimeDifference = (
    startTime: Date,
    endTime: Date,
    showMilliseconds: boolean = false
) => {
    const difference = endTime.getTime() - startTime.getTime();
    const minutes = Math.floor(difference / (1000 * 60));
    const seconds = Math.floor((difference / 1000) % 60);
    const milliseconds = difference % 1000;

    let timeDifference =
        minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;

    if (showMilliseconds && milliseconds > 0) {
        timeDifference += ` ${milliseconds}ms`;
    }

    return timeDifference;
};
