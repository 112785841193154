import React from "react";
import { CustomTooltip, StyledImg, StyledUl, TooltipContent } from "./tooltip.styles";
import TooltipSVG24 from "../../../components/icons/tooltip-24.svg";
import TooltipSVG18 from "../../../components/icons/tooltip-18.svg";
import { TooltipProps } from "./tooltip.types";
import { FormattedMessage } from "react-intl";

const Tooltip = ({ text, textArray }: TooltipProps) => {
    return (
        <CustomTooltip
            // open={true}
            title={
                <TooltipContent>
                    <StyledImg src={TooltipSVG18} />
                    {text && <FormattedMessage id={text} />}
                    {textArray && (
                        <StyledUl>
                            {textArray.map((string) => {
                                return (
                                    <li key={string}>
                                        <FormattedMessage id={string} />
                                    </li>
                                );
                            })}
                        </StyledUl>
                    )}
                </TooltipContent>
            }
            arrow
        >
            <StyledImg alt="tooltip" src={TooltipSVG24} />
        </CustomTooltip>
    );
};

export default Tooltip;
