import React from "react";
import { SessionCardProps } from "./session-card.types";
import {
    ArrowWrapper,
    LogoWrapper,
    InfoWrapper,
    SessionCardWrapperDiv,
    StyledImage,
    UserInfoWrapper,
    DateTimeAndDurationWrapper,
    DateWrapper,
    TimeWrapper,
    DurationWrapper,
    ContentWrapper,
    TitleWrapper,
    SecondaryWrapperDiv,
    TruncatedStyledText
} from "./session-card.styles";
import Users from "../../../../components/icons/users";
import Time from "../../../../components/icons/time";
import Text from "../../../../components/ui/text";
import { getTimeDifference } from "../sessions/utils";
import { useHistory } from "react-router-dom";
import { CalendarIcon24, CardArrowIcon } from "../../../../components/icons";

const SessionCard = ({ sessionInfo, scenarioInfo }: SessionCardProps) => {
    const history = useHistory();

    const { id, traineeName, simulationName, logoUrl, userId } = sessionInfo;
    const { simulationId, scenarioName, scenarioInstanceId, from, to } =
        scenarioInfo;

    const duration = (from: any, to: any) => {
        return getTimeDifference(new Date(from), new Date(to));
    };

    const formattedDateAndTime = new Date(scenarioInfo.from).toLocaleString();
    const [date, time] = formattedDateAndTime.split(",");

    const simulation = simulationName ? simulationName : "Simulation Name";
    const scenario = scenarioName ? scenarioName : "Scenario Name";

    const handleCardClick = () => {
        history.push(
            `/sessions/session-analysis?traineeName=${traineeName}&simulationName=${simulationName}&userId=${userId}&from=${from}&to=${to}&scenarioId=${simulationId}&simulationInstanceId=${id}&scenarioInstanceId=${scenarioInstanceId}&name=${scenarioName}&logoUrl=${logoUrl}`
        );
    };

    return (
        <SessionCardWrapperDiv onClick={handleCardClick}>
            <SecondaryWrapperDiv>
                <ContentWrapper>
                    <LogoWrapper>
                        <StyledImage width={64} src={logoUrl} />
                    </LogoWrapper>

                    <InfoWrapper>
                        <TitleWrapper>
                            <Text variant="extraLargeMedium">{simulation}</Text>
                            <Text variant="extraLargeMedium">{"\u2022"}</Text>
                            <Text variant="extraLargeMedium">{scenario}</Text>
                        </TitleWrapper>

                        <UserInfoWrapper>
                            <Users fill="#999999" />
                            <TruncatedStyledText
                                variant="baseRegular"
                                color="neutral.grey[300]"
                            >
                                {`${traineeName}`}
                            </TruncatedStyledText>
                        </UserInfoWrapper>

                        <DateTimeAndDurationWrapper>
                            <DateWrapper>
                                <CalendarIcon24 fill="#999999" />
                                <Text
                                    variant="baseRegular"
                                    color="neutral.grey[300]"
                                >
                                    {date}
                                </Text>
                            </DateWrapper>
                        </DateTimeAndDurationWrapper>

                        <DateTimeAndDurationWrapper>
                            <TimeWrapper>
                                <Time fill="#999999" />
                                <Text
                                    variant="baseRegular"
                                    color="neutral.grey[300]"
                                >
                                    {time}
                                </Text>
                            </TimeWrapper>
                            <DurationWrapper>
                                <Text
                                    variant="baseRegular"
                                    color="neutral.grey[300]"
                                >
                                    {"\u2022"}
                                </Text>
                                <Text
                                    variant="baseRegular"
                                    color="neutral.grey[300]"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {duration(from, to)}
                                </Text>
                            </DurationWrapper>
                        </DateTimeAndDurationWrapper>
                    </InfoWrapper>
                </ContentWrapper>

                <ArrowWrapper>
                    <CardArrowIcon />
                </ArrowWrapper>
            </SecondaryWrapperDiv>
        </SessionCardWrapperDiv>
    );
};

export default SessionCard;
