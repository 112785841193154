import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

type ButtonProps = {
    open: boolean
}

export const Button = styled.a<ButtonProps>`
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    background-color:${({ open, theme }) => open ? theme.colors.legacy.gray[250] : 'transparent'};

    &:hover {
        background-color:${({ theme }) => theme.colors.legacy.gray[250]};
    }
`;

export const List = styled.ul`
    margin: 0;
    padding: 0;
    position: absolute;
    width: 10rem;
    background-color: ${({ theme }) => theme.colors.legacy.gray[500]};
    border: 1px solid ${({ theme }) => theme.colors.neutral.grey[300]};
    box-shadow: 0 0 8px 2px ${({ theme }) => theme.colors.legacy.gray[250]};
    right: 0;
    top: 2.2rem;
    border-radius: 0.3rem;
    z-index: 99;
`;

export const Item = styled.li`
    cursor: pointer;
    list-style: none;
    font-weight: 500;
    height: 2rem;
    padding: 0 0.6rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:first-of-type {
        border-radius: 0.3rem 0.3rem 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 0.3rem 0.3rem;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.danger};
    }
`;