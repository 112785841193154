import { FormattedMessage } from "react-intl";
import Button from "../../../components/ui/button";
import Styled from "../../../components/ui/styled";
import Text from "../../../components/ui/text";
import { Container, LeftColumn, RightColumn, Wrapper } from "./done.styles";


type Props = {
    onComplete?: () => void | undefined
}

const Done = ({ onComplete }: Props) => {
    return <Container>
        <LeftColumn></LeftColumn>
        <RightColumn>
            <Wrapper>
                <Text variant="extraLarge3" marginBottom="1rem">
                    <FormattedMessage id={'welcome-screen:done-title'} />
                </Text>
                <Text variant="medium" marginBottom="1rem" color="gray[100]">
                    <FormattedMessage id={'welcome-screen:done-subtitle'}/>
                </Text>
                <Text>
                    <FormattedMessage id={'welcome-screen:done-description'} />
                </Text>
                <Styled 
                    marginTop="5rem" 
                    display="flex"
                    justifyContent="flex-end"
                    width="100%">
                    <Button onClick={onComplete} variant="orange">
                        <FormattedMessage id={'welcome-screen:finish'} />
                    </Button>
                </Styled>
            </Wrapper>
        </RightColumn>
    </Container>
}

export default Done;