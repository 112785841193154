import { useCallback, useRef } from "react";
import { useSettings } from "../../../context/settings";
import { SET_DIRTY } from "../../../context/settings/reducer";
import { useUser } from "../../../context/user";
import { SET_PHOTO } from "../../../context/user/reducer";
import { EditIcon } from "../../icons";
import { Container, EditContainer, FilePicker, Initials } from "./avatar.styles";

interface Sizes {
    [key: string]: [string, string, number, number, number];
}

export const AvatarSizes: Sizes = {
    small: ['1.5rem', '0.8rem', 10, 10, 2],
    medium: ['2.25rem', '1rem', 16, 16, 4],
    large: ['3rem', '2rem', 30, 30, 6],
    extraLarge: ['7.5rem', '3.5rem', 46, 46, 10],
};

type Props = {
    name?: string,
    photo?: any,
    editable: boolean,
    size?: string,
    showInitials?: boolean,
    onClick?: () => void,
}

const Avatar = ({
    name,
    photo,
    editable = false,
    size = 'medium',
    showInitials = true,
    onClick,
    ...remainingProps
}: Props) => {
    const pickerRef = useRef<HTMLInputElement>(null);
    const { state: user, dispatch: userDispatch } = useUser();
    const { dispatch: settingsDispatch } = useSettings();

    const displayName = name || user.name;
    const displayPhoto = photo || user.photo;

    let split = (displayName && displayName.split(' ')) || [];
    let initials = split.length > 1
        ? split.map((s: string, i: number) => i === 0 || i === split.length - 1 ? s.charAt(0) : null).join('')
        : split.join('').charAt(0);

    const shouldShowInitials = showInitials && initials && (!displayPhoto);

    const handleOnClick = useCallback(() => {
        onClick && onClick();
        if (pickerRef.current) {
            pickerRef.current.click();
        }
    }, [onClick, pickerRef]);

    const handleOnSelectImage = useCallback((e: any) => {
        if (e.target.files[0].type.match(/^image\//)) {
            const source: any = URL.createObjectURL(e.target.files[0]);
            userDispatch({ type: SET_PHOTO, payload: source });
            settingsDispatch({ type: SET_DIRTY, payload: true });
        }
    }, []);

    return <Container
        photo={photo || user.photo}
        size={size}
        onClick={handleOnClick}
        {...remainingProps}
        >
        {shouldShowInitials &&
            <Initials>
                {initials.toUpperCase()}
            </Initials>}
        {editable && <EditContainer className="edit">
            <EditIcon
                width={AvatarSizes[size][2]}
                height={AvatarSizes[size][3]} />
            <FilePicker
                ref={pickerRef}
                onChangeCapture={handleOnSelectImage} />
        </EditContainer>}
    </Container>;
}

export default Avatar;
