import { createGlobalStyle } from "styled-components";
import globalVariables from "./global-variables";

export default createGlobalStyle`
  ${globalVariables};

  .react-calendar {

      abbr {
        text-decoration: none;
      }

      button {
        margin: 0;
        border: 0;
        outline: none;
        cursor: pointer;
      }

      &__navigation {

        height: 2rem;
        display: flex;
        margin-bottom: 1rem;
        button {
          background-color: transparent;
          color: ${({ theme }) => theme.colors.neutral.grey[50]};
        }
      }

      &__month-view__weekdays {
        height: 3rem;
      }

      &__month-view__weekdays__weekday {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }

  .react-calendar__month-view {
    width: 17.5rem;

    @media (max-width: 1023px) {
      width: auto;
    }
  }

  .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
    color: ${({ theme }) => theme.colors.legacy.gray[500]};
  }
  .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year:after {
    z-index: -1;
  }

  .react-calendar__tile {
    background-color: transparent;
    border: none;
    height: 2.5rem;
    padding: 0;
    margin:0 0.1rem;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.neutral.grey[50]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
    }

    
    &.react-calendar__month-view__days__day--neighboringMonth {
      color: ${({ theme }) => theme.colors.neutral.grey[300]};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.neutral.grey[700]};
      pointer-events: none;
    }

    &--active, &--hasActive {
      background-color: ${({ theme }) => theme.colors.primary.orange[500]};
      color: ${({ theme }) => theme.colors.legacy.gray[500]};
      font-weight: 700;
     
    } 

    &--hasActive.react-calendar__month-view__days__day--neighboringMonth {
      color: ${({ theme }) => theme.colors.white};
    }

    &--active:hover, &--hasActive:hover {
      background-color: ${({ theme }) => theme.colors.legacy.yellow[300]};
    }

    &--hover {
      background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
      border-radius: 0;
      color: ${({ theme }) => theme.colors.neutral.grey[50]};
      font-weight: 500;
    }
    &--hover:hover {
      background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
    }

    &--rangeStart {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    
    }

    &--rangeEnd {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    
    }
  }

  .react-calendar__tile--active.react-calendar__month-view__days__day--neighboringMonth {
    color: ${({ theme }) => theme.colors.legacy.gray[500]};
  }

  .react-calendar__tile--now {
    position: relative;
    color: ${({ theme }) => theme.colors.primary.orange[500]};

    abbr {
      position: relative;
      z-index: 19;
    }
    &:after {
      content: '';
      position: absolute;
      inset: 6px;
      background-color: ${({ theme }) => theme.colors.legacy.yellow[800]};
    }
  }

  .react-calendar__tile--active.react-calendar__tile--now,
  .react-calendar__tile--hasActive.react-calendar__tile--now {
    &:after {
      background-color: ${({ theme }) => theme.colors.legacy.gray[400]};
    }
  }

  .react-calendar__navigation__label__labelText {
    font-size: 0.95rem;
  }

  g, path {
    &:focus{
      outline: none !important;
    }
  }

  @media (max-width:767px) {
    .recharts-legend-wrapper {
      position: initial;
    }

    .top-completed-scenarios-chart {
      .recharts-layer.recharts-cartesian-axis-tick text[type="category"]{
        transform: translateY(-15px) translateX(-8px);
      }
    }
    
    .area-chart {
      .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis .recharts-cartesian-axis-ticks g:first-of-type {
        transform: translateX(15px);
      }

      .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis .recharts-cartesian-axis-ticks g:last-of-type {
        transform: translateX(-15px);
      }
    }
  }

  .recharts-tooltip-wrapper {
    outline: none;
  }

  .custom-chart .recharts-yAxis .recharts-cartesian-axis-tick-line {
    transform: translateX(-15px);
  }

  .custom-chart .recharts-yAxis .recharts-label {
    text-anchor: middle;
  }
  

`;
