import styled from "styled-components";

type WrapperPros = {
    opacity: number
}

export const Wrapper = styled.div<WrapperPros>`
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,${({ opacity }) => opacity});
`;

type ContainerProps = {
    width: number | string,
    height: number | string,
    padding: number | string,
    hasHeader: boolean,
    transparent: boolean,
}

export const Container = styled.div<ContainerProps>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    background-color: ${({ theme, transparent }) => transparent && 'transparent' || theme.colors.legacy.gray[500]};
    padding: ${({ padding }) => padding};
    box-sizing: border-box;
    position: relative;
    padding-top: ${({ hasHeader }) => hasHeader && '4.5rem' || 0};
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        width: calc(100vw - 2rem);
        height: calc(100vh - 2rem);
    }
`;

export const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0.7rem;
    top: 0.7rem;
    cursor: pointer;
`;