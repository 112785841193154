import client from "../../client";
import latestActivity from "../../static/latest-activity.json";
import customTopStats from "../../static/session-overview-custom-top-stats.json";
import topStats from "../../static/session-overview-top-stats.json";
import highlights from "../../static/highlights.json";

export type ActionsPerformedEntry = {
  eventName: string;
  userId: string;
  simulationInstanceId: string;
  capturedAt: string;
};

export type ActionsPerformedResponse = {
  datasets: {
    actions: {
      [key: string]: ActionsPerformedEntry[];
    };
  }[];
};

export type ActionsPerformedRequestQueryString = {
  userId: string;
  date: string;
  [key: string]: any;
};

export const getActionsPerformed = async ({
  simulationInstanceId,
  scenarioInstanceId,
  userId,
  day,
}: {
  simulationInstanceId: string;
  scenarioInstanceId: string;
  userId: string;
  day: string;
}): Promise<ActionsPerformedResponse> => {
  const { data } = await client.get(
    `/v1/session/${simulationInstanceId}/actions-performed?day=${day}&userId=${userId}&scenarioInstanceId=${scenarioInstanceId}`
  );
  return data;
};

export type SessionListRequestQueryString = {
  userId: string;
  date: string;
};

export const getSessionList = async ({ userId, date }: SessionListRequestQueryString) => {
  const { data } = await client.get(`/v1/session?userId=${userId}&date=${date}`);
  return data;
};

export const getCustomCharts = async ({
  simulationInstanceId,
  scenarioInstanceId,
  from,
  to,
  userId,
}: {
  sessionId: string;
  simulationInstanceId?: string;
  scenarioInstanceId?: string;
  from: string;
  to: string;
  userId: string;
}) => {
  const { data } = await client.get(
    `/v1/session/${simulationInstanceId}/custom-charts?scenarioInstanceId=${scenarioInstanceId}&from=${from}&to=${to}&userId=${userId}`
  );
  return data;
};

export const getCombinedCharts = async ({
  simulationInstanceId,
  scenarioInstanceId,
  from,
  to,
  userId,
}: {
  sessionId: string;
  simulationInstanceId?: string;
  scenarioInstanceId?: string;
  from: string;
  to: string;
  userId: string;
}) => {
  const { data } = await client.get(
    `/v1/session/${simulationInstanceId}/combined-custom-charts?scenarioInstanceId=${scenarioInstanceId}&from=${from}&to=${to}&userId=${userId}`
  );
  return data;
};

export const getLatestActivity = () => {
  return latestActivity;
};

export const getHighlights = () => {
  return highlights;
};

export const getScore = async ({
  userId,
  scenarioInstanceId,
  scoreId,
  simulationInstanceId,
}: {
  userId: string;
  scenarioInstanceId: string;
  scoreId: string; // scenarioId
  simulationInstanceId: string;
}) => {
  const { data } = await client.get(
    `/v1/score?userId=${userId}&scenarioInstanceId=${scenarioInstanceId}&scoreId=${scoreId}&simulationInstanceId=${simulationInstanceId}`
  );

  return data;
};

export const getSessionOverviewTopStats = async ({ name }: { name: string }) => {
  // TODO: Integration with BE
  /*
  const { data: shared } = await client.get(
    `/v1/session/top-stats?name=${name}`
  );
  const { data: custom } = await client.get(
    `/v1/session/custom-top-stats?name=${name}`
  );*/

  return [...customTopStats, ...topStats];
};

export const getSessionDetailTopStats = async (props: { [prop: string]: string | number | null }) => {
  const query = Object.keys(props)
    .filter((k) => k !== "simulationId")
    .map((k) => `${k}=${props[k]}`)
    .join("&");

  const { data } = await client.get(`/v1/session/${props.simulationId}/custom-top-stats?${query}`);

  return data;
};

export const getCombinedCustomTopStats = async ({
  simulationInstanceId,
  scenarioInstanceId,
  from,
  to,
  userId,
}: {
  simulationInstanceId?: string;
  scenarioInstanceId?: string;
  from: string;
  to: string;
  userId: string;
}) => {
  const { data } = await client.get(
    `/v1/session/${simulationInstanceId}/combined-custom-top-stats?scenarioInstanceId=${scenarioInstanceId}&from=${from}&to=${to}&userId=${userId}`
  );
  return data;
};

export const triggerAnalytics = async ({
  simulationInstanceId,
}: {
  simulationInstanceId?: string;
}) => {
  const { data } = await client.post(`v1/analytics/${simulationInstanceId}`);
  return data;
};