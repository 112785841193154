import styled, { css } from "styled-components";
import base from "../../../../themes/base/base";
import Avatar from "../../../../components/ui/avatar";

const sharedTableCellContentStyles = css`
    @media (min-width: 1024px) and (max-width: 1279px) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 8.5rem;
    }
    @media (min-width: 1280px) and (max-width: 1439px) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 24.375rem;
    }

    @media (min-width: 1440px) and (max-width: 1919px) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 28.75rem;
    }

    @media (min-width: 1920px) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 46.875rem;
    }
`;

export const DefaultTableCellContent = styled.div`
    color: ${base.colors.neutral.grey[300]};
    ${base.typography.baseRegular};
`;

export const DotsIconWrapper = styled.div<{ isOpen: boolean }>`
    display: flex;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid transparent;
    position: relative;
    width: 100%;

    ${({ isOpen }) =>
        isOpen
            ? css`
                  border: 1px solid ${base.colors.primary.orange[300]};
                  background-color: ${base.colors.neutral.grey[500]};
              `
            : css`
                  &:hover {
                      background-color: ${base.colors.neutral.grey[500]};
                  }

                  &:active {
                      border: 1px solid ${base.colors.primary.orange[300]};
                      background-color: ${base.colors.neutral.grey[500]};
                  }
              `}
`;

export const EmailTableCellContent = styled.div`
    color: ${base.colors.neutral.grey[300]};
    ${base.typography.smallRegular};
    ${sharedTableCellContentStyles};
`;

export const NameAndEmailWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NameTableCell = styled.div`
    display: flex;
    gap: 1rem;
`;

export const NameTableCellContent = styled.div`
    color: ${base.colors.neutral.grey[50]};
    ${base.typography.baseRegular};
    ${sharedTableCellContentStyles};
`;

export const StyledAvatar = styled(Avatar)`
    cursor: default;

    &:hover {
        box-shadow: none;
    }
`;
