import styled from "styled-components";
import base from "../../../../themes/base/base";

export const Wrapper = styled.div<{ bottomGap: boolean, borderTopRadius: boolean, borderBottomRadius: boolean }>`
  flex: 1;
  display: flex;
  background-color: ${base.colors.neutral.grey[800]};
  justify-content: center;
  margin-bottom: ${({ bottomGap }) => (bottomGap ? "1rem" : 0)};
  border-bottom-left-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
  border-bottom-right-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
  border-top-right-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
  border-top-left-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
`;

export const Main = styled.div`
  width: 100%;
`;

export const InstrumentsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
`;

export const InstrumentsInnerContainer = styled.div`
  display: flex;
  width: 50rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`