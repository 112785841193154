import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../providers/intl-provider";
import {
    Actions,
    ContentAndBackgroundContainer,
    Container,
    Description,
    StyledLogoutButton,
    Title,
    ContentWrapper,
    StyledRefreshButton,
    StyledHomepageButton
} from "./generic-error";
import { Pages } from "../../../enums/pages";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const GenericError = () => {

    const { instance } = useMsal();

    const handleLogOut = useCallback(() => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }, [instance]);

    return <>
    <VRIntlProviderComponent 
        localeFn={localeFn}
        id="generic-error"
        fallback={null}
    >
        <Container>
            <ContentAndBackgroundContainer>
                <ContentWrapper>
                    <Title>
                        <FormattedMessage id="generic-error:message"/>
                    </Title>
                    <Description>
                        <FormattedMessage id="generic-error:description"/>
                    </Description>
                    <Actions>
                        <StyledRefreshButton
                            variant="orange"
                            onClick={() => window.location.reload()}
                        >
                            <FormattedMessage id="generic-error:refresh"/>
                        </StyledRefreshButton>
                        <StyledHomepageButton onClick={() => window.location.href = Pages.Overview}>
                            <FormattedMessage id="generic-error:go-to-overview"/>
                        </StyledHomepageButton>
                        <StyledLogoutButton onClick={handleLogOut}>
                            <FormattedMessage id="generic-error:log-out"/>
                        </StyledLogoutButton>
                    </Actions>
                </ContentWrapper>
            </ContentAndBackgroundContainer>
        </Container>
    </VRIntlProviderComponent>
    </>
};

export default GenericError;