import { useCallback, useState } from "react";
import Text from "../text";
import { Area, Container, Error, Field, Label, Wrapper } from "./input.styles";


type Props = {
    label?: string,
    type?: string,
    name: string,
    value?: string | number,
    error?: string | null | undefined,
    height?: string | number,
    as?: string,
    onChange?: (e: any) => void,
    onFocus?: (e: any) => void,
    onBlur?: (e: any) => void,
    placeholder?: string,
    [prop: string]: any,
}

const NativeComponent = ({ type, ...props }: { type: string, [prop: string]: any }) => {
    switch (type) {
        case 'textarea':
            return <Area {...props} />
        default:
            return <Field type={type} {...props} />
    }
}

const Input = ({ label, type, name, value, error, height = "3.5rem", as = "input", ...props }: Props) => {

    const [isFocus, setIsFocus] = useState(false);

    const handleOnFocus = useCallback((e: any) => {
        setIsFocus(true);
        props.onFocus && props.onFocus(e);
    }, []);
    const handleOnBlur = useCallback((e: any) => {
        setIsFocus(false);
        props.onBlur && props.onBlur(e);
    }, []);

    return <Container {...props}>
        <Wrapper focus={isFocus} error={error} type={as} height={height}>
            {label && <Label focus={isFocus} error={error}>
                <Text variant="xsMedium">{label}</Text>
            </Label>}
            <NativeComponent
                type={type || 'text'}
                name={name}
                value={value || ''}
                {...props}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur} />
        </Wrapper>
        {error && <Error><Text variant="xsRegular">{error}</Text></Error>}
    </Container>
}


export const TextArea = ({ as, ...props }: Props) => <Area as="textarea" {...props} />;

export default Input;
