import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  place-items: center;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.legacy.gray[500]};

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

type ChartWrapperProps = {
  flex?: number;
};

export const ChartWrapper = styled.div<ChartWrapperProps>`
  flex: ${({ flex }) => flex || 1};
  display: flex;
  height: 22rem;
  padding: 3rem 0 4rem 0;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
  position: relative;
  flex-direction: column;

  .recharts-responsive-container {
    @media only screen and (max-width: 1024px) {
      height: 18rem !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex: 1;
    width: 100%;
    padding: 2rem 0;
    flex-direction: column;
  }
`;

type LegendItemProps = {
  color: string;
};

export const LegendItem = {
  List: styled.ul`
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `,

  Item: styled.li`
    display: flex;
    align-items: center;

    &:last-child {
      margin-top: 0.25rem;
    }
  `,

  Circle: styled.div<LegendItemProps>`
    width: 1rem;
    height: 1rem;
    background: ${({ color }) => color};
    border-radius: 50%;
  `,

  Text: styled.span<LegendItemProps>`
    margin-left: 0.625rem;
    font-size: 0.85rem;
    color: ${({ color }) => color};
  `,
};
