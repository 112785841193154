import moment from "moment";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomCheckedIcon, CustomIcon, Error, ErrorBanner, FieldWrapper, FieldsWrapper, FormInput, FormWrapper, HiddenInput, Label, RadioLabel, StyledRadioGroup, SurveyTitle } from "./survey.styles";
import { FormControlLabel, Radio } from "@mui/material";
import { Controller, useForm } from "react-hook-form"
import { completeRegistration } from "../../../api/user";
import Loader from "../../../components/ui/loader";
import { PrimaryButton } from "../../shared/shared.styles";

type FormInputs = {
    firstName: string
    lastName: string
    birthDate: string
    gender: string
  }

const MVPSurvey = ({
    handleOnContinue,
    clientSurvey
}: {
    handleOnContinue: () => void;
    clientSurvey: boolean
}) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isValid },
        control,
    } = useForm<FormInputs>({
        shouldUseNativeValidation: false,
    });

    const onSubmit = async () => {
        const values = getValues();
        
        if(isValid) {
            setIsLoading(true);
            await completeRegistration({...values, birthDate: moment(values.birthDate, "DD/MM/YYYY")});
            handleOnContinue();
        }
    }

    const CustomRadio = <Radio checkedIcon={<CustomCheckedIcon />} icon={<CustomIcon className="radio-icon" />} />;

    return (
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                {Object.keys(errors).length > 0 && <ErrorBanner>
                    <FormattedMessage id={'survey:error-banner'} />
                </ErrorBanner>}

                <SurveyTitle><FormattedMessage id={'survey:title'}/></SurveyTitle>

                <FieldsWrapper>
                    <FieldWrapper>
                        <Label><FormattedMessage id={'survey:first-name'} /></Label>
                        <FormInput
                            className={errors?.firstName ? "error": ""}
                            {...register("firstName", { required: intl.formatMessage({id: "survey:required"}) } )}
                            placeholder={intl.formatMessage({ id: "survey:first-name" })}
                        />
                        {errors?.firstName && <Error>{errors.firstName.message}</Error>}
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label><FormattedMessage id={'survey:surname'} /></Label>
                        <FormInput
                            className={errors?.lastName ? "error": ""}
                            {...register("lastName", { required: intl.formatMessage({id: "survey:required"}) })}
                            placeholder={intl.formatMessage({ id: "survey:surname" })}
                        />
                        {errors?.lastName && <Error>{errors.lastName.message}</Error>}
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label><FormattedMessage id={'survey:gender'} /></Label>
                        
                        <Controller
                            control={control}
                            name="gender"
                            rules={{ required: intl.formatMessage({id: "survey:required"}) }}
                            defaultValue=""
                            render = {({ field: { onChange, onBlur, value } }) => (
                                <StyledRadioGroup
                                    className={errors?.gender ? "error": ""}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                >
                                    <FormControlLabel value="M" control={CustomRadio} label={intl.formatMessage({id: "survey:male"})} />
                                    <FormControlLabel value="F" control={CustomRadio} label={intl.formatMessage({id: "survey:female"})} />
                                    <FormControlLabel value="NA" control={CustomRadio} label={intl.formatMessage({id: "survey:prefer-not"})} />
                                </StyledRadioGroup>
                            )}
                        />        
                        {errors?.gender && <Error>{errors.gender.message}</Error>}
                    </FieldWrapper>

                    <FieldWrapper>
                        <Label><FormattedMessage id={'survey:dob'} /></Label>
                        <FormInput
                            className={errors?.birthDate ? "error": ""}
                            {...register("birthDate", { 
                                required: intl.formatMessage({id: "survey:required"}),
                                validate: (value) => moment(value, 'DD/MM/YYYY', true).isValid() || 'Please enter your date of birth using the DD/MM/YYYY format.'
                            })}
                            placeholder={intl.formatMessage({ id: "survey:date-format" })}
                        />
                        {errors?.birthDate && <Error>{errors.birthDate.message}</Error>}
                    </FieldWrapper>
                </FieldsWrapper>

                <PrimaryButton disabled={isLoading}>
                    {isLoading? <Loader size="medium" color="#666666" fontSize="1.5rem"/> : 
                        <FormattedMessage id={'survey:continue'} />
                    }
                </PrimaryButton>
                {/* We can show (back and continue) buttons when there is client survey; based on clientSurvey flag */}  
            </FormWrapper>   
    )
}

export default MVPSurvey;