import styled, { css } from "styled-components";
import base from "../../themes/base/base";

export const SessionItemContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 1.125rem 1.5rem;
    width: 83%;
    align-self: flex-end;
    border-radius: 0.5rem;
    background: ${base.colors.neutral.grey[800]};
    box-sizing: border-box;
    cursor: pointer;
    transition: all 300ms;
    margin-bottom: 1.5rem;

    @media (min-width: 360px) and (max-width: 1919px) {
        ${base.typography.largeMedium};
        padding: 0.75rem 1rem;
        width: 100%;
        align-self: auto;
        margin-bottom: 1rem;
    }

    & .chevron-container svg path {
        transition: all 300ms;
    }

    &:hover {
        background: ${base.colors.neutral.grey[600]};

        .chevron-container svg path {
            fill: #f2f2f2;
        }
    }
`;

export const SessionIconContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
`;

export const StyledImg = styled.img`
    width: 4rem;
`;

export const SessionInfoContainer = styled.div`
    width: 100%;
    height: 100%;
    margin-left: 1rem;
`;

export const SessionTitle = styled.div`
    ${base.typography.extraLargeMedium};
    margin-bottom: 0.5rem;
    color: ${base.colors.neutral.grey[50]};

    @media (min-width: 360px) and (max-width: 1919px) {
        ${base.typography.largeMedium};
    }

    .bullet-point {
        margin: 0 0.5rem;
    }
`;

export const SessionDetails = styled.div`
    ${base.typography.baseRegular};
    display: flex;
    color: ${base.colors.neutral.grey[300]};;
    gap: 0.5rem;

    @media (min-width: 360px) and (max-width: 1919px) {
        ${base.typography.smallRegular};
        flex-direction: column;
        gap: 0.25rem;
    }
`;

export const DateTimeAndDurationContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const sharedDateTimeAndDurationStyles = css`
    gap: 0.5rem;
    display: flex;
    align-items: center;
`;

export const DateAndTimeDiv = styled.div`
    ${sharedDateTimeAndDurationStyles};
`;

export const DurationDiv = styled.div`
    ${sharedDateTimeAndDurationStyles};
`;

const sharedStyles = css`
    gap: 0.25rem;
    display: flex;
    align-items: center;
`;

export const TraineeNameDiv = styled.div`
    ${sharedStyles};
`;

export const DateDiv = styled.div`
    ${sharedStyles};
`;

export const TimeDiv = styled.div`
    ${sharedStyles};
`;

export const DetailItem = styled.div`
    ${sharedStyles};
`;

export const DetailItemText = styled.span`
`;

export const BulletPoint = styled.span`
    font-size: 1.25rem;
    padding-top: 0.0625rem;
`;

export const CardArrowContainer = styled.div`
    margin-left: 1.5rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

