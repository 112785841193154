import styled from "styled-components";
import base from "../../../../themes/base/base";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const ButtonContainer = styled.ul`
    display: flex;
    margin: 0;

    li{
        ${base.typography.xsMedium}
        color: ${base.colors.neutral.grey[50]};
        border: 1px solid ${base.colors.neutral.grey[300]};
        background: ${base.colors.neutral.grey[900]};
        list-style-type: none;
        padding: 6px 12px;

        &:not(:first-child){
            border-left: 0;
        }

        &:focus{
            border: 1px solid ${base.colors.primary.orange[500]} 
        }

        &.btn-active,
        &:hover {
            background-color: ${base.colors.neutral.grey[600]};
            cursor: pointer;
        }
    }

`;

export const FilterContainer = styled.div`
    display: flex;
`;

export const StyledDatePicker = styled(DesktopDatePicker)`
    &.MuiFormControl-root.MuiTextField-root{
        margin-right: 10px;
        cursor: pointer;
    }    

    .MuiInputBase-root fieldset{
        display: none;
    }

    .MuiInputBase-root{
        display: flex;
        justify-content: center;
        width: 32px;
        height: 30px;
        padding: 4px;
        border: 1px solid ${base.colors.neutral.grey[300]};
        border-radius: 0;

        &.Mui-focused{
            border: 1px solid ${base.colors.primary.orange[500]};
        }
    
        &:hover{
            background: ${base.colors.neutral.grey[600]};
        }

        .MuiOutlinedInput-input{
          display: none;
        }

        .MuiInputAdornment-root{
            margin-left: 0;
            button{
                padding: 0;
                margin-right: 0;
                color: ${base.colors.neutral.grey[50]};
            }
        }
    }
`;



