import styled from "styled-components";
import base from "../../../themes/base/base";
import { mobileMedia } from "../../../themes/breakpoints";

export const Main = styled.main`
  margin-top: var(--heat-header-height);
  box-sizing: border-box;
  padding: 0 1.5rem 0 calc(var(--navigation-width) + 1.5rem);
  min-height: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: 767px) {
    padding: calc(9.5rem) 1rem 0 1rem;
    padding-top: 433px; //to review when we have mobile designs
  }
`;

export const Header = styled.header`
  position: sticky;
  top: var(--heat-header-height);
  z-index: 3;
  background: ${base.colors.neutral.grey[900]};
  padding: 1.5rem 0;

  @media (min-width: 768px) and (max-width: 1919px) {
    padding: 1rem 0;
  }
`;

export const Back = styled.div`
  color: ${({ theme }) => theme.colors.neutral.grey[300]};
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  path {
    fill: ${({ theme }) => theme.colors.neutral.grey[300]};
    stroke: ${({ theme }) => theme.colors.neutral.grey[300]};
  }
`;

export const TitleText = styled.div`
  display: flex;
  align-items: center;
  ${base.typography.extraLarge2Bold};

  @media (min-width: 768px) and (max-width: 1919px) {
    ${base.typography.extraLargeMedium};
    height: 2.5rem;
  }

  ${mobileMedia} {
    ${base.typography.largeMedium};
  }
`;

export const Counter = styled.span`
  ${base.typography.baseRegular};
  color: ${base.colors.neutral.grey[300]};
  padding-left: 0.5rem;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .details-title{
    color: #F2F2F2;
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: 0.24px;
  }

  .details {
    color: #999;
    letter-spacing: 0.01125rem;
    font-weight: 400;
  }
`;

export const TitleCard = styled.div<{ isMobile: boolean }>`
    display:flex;
    width: 100%;
    gap: 1rem;
    align-items: center;  
`;

export const TitleDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PageTitle = styled.h3`
  color: #F2F2F2;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.02rem;
  margin: 0 0 1.5rem;
`;

type ImgStylingProps = {
  isRound: boolean;
  isMobile: boolean;
  isLoading: boolean;
};

export const TitleImg = styled.img<ImgStylingProps>`
  width: ${(props) => (props.isMobile ? "56px" : "64px")};
  height: ${(props) => (props.isMobile ? "56px" : "64px")};
  border-radius: ${(props) => (props.isRound ? "50%" : "0")};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftCol = styled.div`
  flex: 0.6;
  margin-bottom: 1rem;

  @media (min-width: 768px) and (max-width: 1279px) {
    flex: 0.4;
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const RightCol = styled.div`
  flex: 0.4;
  justify-content: flex-end;
  display: flex;

  @media (min-width: 768px) and (max-width: 1279px) {
    flex: 0.6;
  }

  @media (max-width: 767px) {
    flex: 1;
  }
`;

export const Content = styled.div``;

export const BreadcrumbContainer = styled.div`
  display: flex;
  padding-bottom: 1rem;
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #ee7127;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
`;

type BreadcrumbLinkProps = {
  path?: string;
};
export const BreadcrumbLink = styled.span<BreadcrumbLinkProps>`
  cursor: ${({ path }) => (path ? "pointer" : "default")};
  ${({ theme, path }) => !path && `color: ${theme.colors.neutral.grey[50]}`};

  &::after {
    content: " / ";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
`;

export const PageLoaderContainer = styled.div`
  padding-left: 16.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: 767px) {
    padding-left: 0;
  }
  
`;
