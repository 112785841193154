import { Section, TabWrapper } from "./timeline-cockpit-tab-styles";
import { FormattedMessage } from "react-intl";
import {
  CustomTooltip,
  StyledImg,
  Title,
  TooltipContent,
  LoaderContainer,
  Container
} from "../../session-detail.styles";
import TooltipSVG from "../../../../components/icons/tooltip.svg";
import { useEffect, useMemo } from "react";
import Loader from "../../../../components/ui/loader";
import Message from "../../../../components/ui/message";
import { ChartData, CombinedChartDataSet, LookoutData, TimelineChartData } from "../../../../types/combined-chart";
import TimelineChart from "../timeline-chart";
import CockpitTimeline from "../cockpit-timeline";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { SessionDetails } from '../../../../constants/constants'
import { usePolling } from "./usePolling";

const TimelineCockPitTab = () => {
  const intl = useIntl();
  const [poll, setPoll] = useState(0);
  const [selectedTab, setSelectedTab] = useState(SessionDetails.CHART);
  const { timelineData } = usePolling(poll);

  const hasIdasCode = useMemo(() => timelineData?.some((dataSet: any) => dataSet.code?.includes("idas")), [timelineData]);
  
  const NoData = () => <Message>
    <FormattedMessage id="no-data" />
  </Message>;

  const timelineDataSets: TimelineChartData[] = useMemo(() => timelineData?.filter((dataSet:any) => dataSet.chartType === "Timeline"), [timelineData]);
  const timelineChartData: ChartData[] = timelineDataSets?.flatMap((chart:any) => chart.datasets);
  const cockpitChartData: CombinedChartDataSet = useMemo(() => timelineData?.find((chart: ChartData) => chart.chartType === 'Instrument')?.datasets, [timelineData]);

  const areaChartData: ChartData[] = timelineChartData?.filter((chart:any) => chart.type === "area");
  const LookoutData: any = timelineChartData?.filter((chart:any) => chart.type === "rangeBar");

  useEffect(() => {
    if(LookoutData?.length === 0 && poll < 30) { //maximum polling time as 5 minutes
        setTimeout(() => {
            setPoll(poll+1);
        }, 10000);
    }
  }, [LookoutData]);

  if (!hasIdasCode) return null;

  if(timelineData === undefined) {
    return <LoaderContainer><Loader /></LoaderContainer>;;
  }

  if (!timelineData) return <Container><NoData /></Container>;
  if (timelineChartData.length === 0) return null; //when we dont have data, we will not show the component

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const renderComponent = (tabLabel:string) => {
    switch (tabLabel) {
      case SessionDetails.CHART:
        return <TimelineChart LookoutData={LookoutData} areaChartData={areaChartData}/>;
      case SessionDetails.COCKPIT:
        return <CockpitTimeline chartData={cockpitChartData} />;
      default:
        return null;
    }
  };

  return (
    <>
    <TabWrapper>
        <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs" centered textColor="inherit">
            <Tab label={intl.formatMessage({id: "session-detail:chart"})} value={SessionDetails.CHART} />
            <Tab label={intl.formatMessage({id: "session-detail:cockpit"})} value={SessionDetails.COCKPIT} />
        </Tabs> 
    </TabWrapper>

    <Section.Container>
      <Title>
        <FormattedMessage id={"session-detail:timeline"} />
        <CustomTooltip
          title={
            <TooltipContent>
              <StyledImg src={TooltipSVG} width="14px" />
              {selectedTab === SessionDetails.CHART ? 
                <FormattedMessage id={"session-detail:timeline-chart-tooltip"} /> :
                <FormattedMessage id={"session-detail:timeline-cockpit-tooltip"} />
              }
            </TooltipContent>
          }
          arrow
        >
          <StyledImg alt="tooltip" src={TooltipSVG} width="18px" />
        </CustomTooltip>
      </Title>

      {renderComponent(selectedTab)}  
      
    </Section.Container>
    </>
  );
};

export default TimelineCockPitTab;
