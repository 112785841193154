import { FormattedMessage } from "react-intl";
import { useModal } from "../../../../../context/modal/modal";
import {
    ButtonsWrapper,
    LogoutDescription,
    ModalContentWrapperDiv,
    StyledConfirmButton,
    StyledEmail
} from "../modal-contents.styles";

interface Props {
    title: string;
    description?: string;
}

const DeleteAccountAdminModal = ({
    title
}: Props) => {
    const { closeModal } = useModal();

    const handleOnCancel = () => {
        closeModal();
    };

    return (
        <ModalContentWrapperDiv>
            <LogoutDescription>
                <FormattedMessage id={title} values={{lineBreak: <br />}}/>
            </LogoutDescription>
            <StyledEmail href="mailto:support@vrai.ie">
                <FormattedMessage id="account-delete:modal-contents:admin:email"/>
            </StyledEmail>

            <ButtonsWrapper>
                <StyledConfirmButton
                    variant="orange"
                    onClick={handleOnCancel}
                >
                    <FormattedMessage id="account-delete:modal-contents:close" />
                </StyledConfirmButton>
            </ButtonsWrapper>

        </ModalContentWrapperDiv>
    );
};

export default DeleteAccountAdminModal;
