import { useState, useEffect } from "react";

type ProgressProviderProps = {
    valueStart: number,
    valueEnd: number,
    children: (val: number) => JSX.Element
}

export const ProgressProvider = ({ valueStart, valueEnd, children } : ProgressProviderProps) => {
    const [value, setValue] = useState(valueStart);
    useEffect(() => {
      setValue(valueEnd);
    }, [valueEnd]);
  
    return children(value);
  };