import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex: 1;
  margin: 0 -1rem;
`;

type ColumnProps = {
  empty: boolean;
};

export const Column = styled.div<ColumnProps>`
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const Description = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0.3rem 0 1rem 0;
`;

export const Wrapper = styled.div<{ bottomGap: boolean, borderTopRadius: boolean, borderBottomRadius: boolean }>`
  flex: 1;
  display: flex;
  background-color: green;
  background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
  justify-content: center;
  margin-bottom: ${({ bottomGap }) => (bottomGap ? "1rem" : 0)};
  border-bottom-left-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
  border-bottom-right-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
  border-top-right-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
  border-top-left-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
`;

export const ChartsContainer = styled.div`
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const Header = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0.3rem 0 1rem 0;
`;

export const LineTooltipContent = {
  Container: styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.grey[50]};
    color: ${({ theme }) => theme.colors.legacy.gray[500]};
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    line-height: 1.2rem;
    outline: none;
  `,
  Label: styled.span`
    display: block;
  `,
  Value: styled.span`
    &:after {
      content: ",";
    }

    &:last-of-type:after {
      content: "";
    }
  `,
};

export const LineChartWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  height: 20rem;
`;
