import { FormattedMessage } from "react-intl";
import { useModal } from "../../../../../context/modal/modal";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import {
    ButtonsWrapper,
    Description,
    ModalContentWrapperDiv,
    StyledCancelButton,
    StyledConfirmButton,
    Title
} from "../modal-contents.styles";
import Loader from "../../../loader";
import { deleteAccount } from "../../../../../api/settings";
import { useUser } from "../../../../../context/user";

interface Props {
    title: string;
    description?: string;
}

const DeleteAccountModal = ({
    title,
    description,
}: Props) => {
    const { instance } = useMsal();
    const { closeModal } = useModal();
    const [loading, setLoading] = useState(false);
    const { state: user } = useUser();

    const handleDeleteAccount = async () => {
        setLoading(true);
        await deleteAccount(user.id);
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
      };

    const handleOnCancel = () => {
        closeModal();
    };

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id={title} />
            </Title>

            <Description>
                    <FormattedMessage id={'account-delete:modal-contents:description'} values={{lineBreak: <br />}}/>
            </Description>

            <ButtonsWrapper>
                <StyledConfirmButton
                    variant="orange"
                    onClick={handleOnCancel}
                >      
                    <FormattedMessage id="account-delete:modal-contents:cancel" />  
                </StyledConfirmButton>

                <StyledCancelButton
                    onClick={handleDeleteAccount}
                    disabled={loading}
                >
                    {loading ? <Loader size="medium" color="#666666" fontSize="1.5rem"/> :
                        <FormattedMessage id="account-delete:modal-contents:delete" />
                    }   
                </StyledCancelButton>
            </ButtonsWrapper>

        </ModalContentWrapperDiv>
    );
};

export default DeleteAccountModal;
