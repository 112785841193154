import { Label, RadioWrapper, Container } from "./timeline-chart-styles";
import { useState } from "react";
import { StyledRadioButton } from "../../../../components/ui/styled-radio";
import CombinedChart from "./combined-chart";
import { ChartData, LookoutData } from "../../../../types/combined-chart";

type chartProps = {
  [k: string]: {
    label: string;
    color: string;
  } | undefined;
};

export type chartState = {
  [k: string]: boolean | undefined;
};

type Props = {
  areaChartData: ChartData[],
  LookoutData: LookoutData[]
};

const TimelineChart = ({LookoutData, areaChartData}: Props) => {

  const chartTypes: chartProps = [...areaChartData,...LookoutData].reduce((acc:any, obj:any) => {
    if(obj.data.length !== 0){
      acc[obj.name] = {
        label: obj.radioLabel,
        color: obj.color,
        position: obj.position
      };
    } 
    return acc;
  }, {});

  //setting the state for each chart for toggle
  const chartState: chartState = {}
  for (let key in chartTypes) {
    chartState[key] = true
  }
  const [chartStateByType, setChartStateByType] = useState<chartState>(chartState);

  const changeHandler = (chartType: string) => {
    setChartStateByType((prevState) => ({
      ...prevState,
      [chartType]: !prevState[chartType]
    }));
  };

  return (
    <Container>

      <RadioWrapper>
        {Object.entries(chartTypes).map(([chartType, chartProps]) => (
          <Label key={chartType} htmlFor={chartType} className={chartStateByType[chartType] ? '' : 'unchecked'}>
            <input
              type="checkbox"
              defaultChecked
              style={{ display: "none" }}
              id={chartType}
              onChange={() => changeHandler(chartType)}
            />
            <StyledRadioButton
              selected={!!chartStateByType[chartType]}
              color={chartProps?.color}
            ></StyledRadioButton>
            {chartProps?.label}
          </Label>
        ))}
      </RadioWrapper>

      <CombinedChart
        areaChartData={areaChartData}
        LookoutData={LookoutData}
        chartStateByType={chartStateByType}
      />
    </Container>
  );
};

export default TimelineChart;
