import styled, { keyframes } from "styled-components";
import base from "../../themes/base/base";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  pointer-events: none;
  gap: 1rem;

  @media (max-width: 1023px) {
    flex-flow: row-reverse;

    & > :last-child {
      display: none;
    }
  }
`;

export const NameDiv = styled.div``;

export const Name = styled.div`
  ${base.typography.baseRegular};
  color: ${base.colors.neutral.grey[50]};
`;

export const Email = styled.div`
  ${base.typography.smallRegular};
  color: ${base.colors.neutral.grey[300]};
`;

const listPop = keyframes`
  from {
    opacity: 0;
    transform: translateY(0.2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0rem);
  }
`;

export const List = styled.ul`
  position: absolute;
  bottom: calc(100% + 0.2rem);
  left: 0;
  list-style: none;
  width: 19.5rem;
  background-color: ${({ theme }) => theme.colors.legacy.gray[300]};
  box-shadow: 0 0 8px 2px ${({ theme }) => theme.colors.legacy.gray[250]};
  border: 1px solid ${({ theme }) => theme.colors.neutral.grey[300]};
  border-radius: 4px;
  margin: 0;
  padding: 0;
  animation: ${listPop} 0.1s ease-in;

  @media (max-width: 1024px) {
    top: 100%;
    right: 0;
    left: initial;
    width: 12rem;
    border: none;
    bottom: initial;
  }
`;

export const ListItem = styled.li`
  padding: 0.65rem 0.6rem;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;

  & > * {
    pointer-events: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
  }

  &:first-of-type {
    border-radius: 0.2rem 0.2rem 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 0.2rem 0.2rem;
  }
`;

export const ListItemLabel = styled.span`
  margin-left: 0.5rem;
`;
