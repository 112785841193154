import styled from "styled-components";

export const List = styled.section`
`;

type RowProps = {
    padding?: string,
    direction?: string
}

export const Row = styled.div<RowProps>`
    display: flex;
    padding: ${({ padding }) => padding || '0.65rem 0.5rem'};
    flex-direction: ${({ direction }) => direction || 'row'};
    flex: 1;
`;

type ColumnProps = {
    width?: number,
    direction?: string,
    justify?: string,
    align?: string
}


export const Column = styled.div<ColumnProps>`
    display: flex;
    flex: ${({ theme, width = 1 }) => width / 100};
    flex-direction: ${({ direction }) => direction || 'row'};
    align-items: ${({ align }) => align || 'flex-start'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
    padding: 0 0.8rem;
    
`;


export const Header = styled(Row)`
    background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-radius: 0.3rem 0.3rem 0 0;

    @media (max-width: 1024px) {
        display: none;
    }
`;


export const ListItem = styled(Row)`
    background-color: ${({ theme }) => theme.colors.legacy.gray[400]};
    display: flex;
    height: 5rem;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.legacy.gray[300]};
    padding: 0.65rem 0.5rem;
    box-sizing: border-box;

    
    @media (max-width:1024px) {
        background:  ${({ theme }) => theme.colors.legacy.gray[300]};
        border-color: ${({ theme }) => theme.colors.neutral.grey[700]};
        border-width: 1px;
        flex-direction: column;
        height: auto;
        padding: 1.1rem 3rem 1.1rem 0.5rem;
        position: relative;
        margin-top: -1px;

        &:nth-child(2) {
            border-radius: 0.2rem 0.2rem 0 0;
        }

        &:last-item {
            border-radius: 0 0 0.2rem 0.2rem;
        }

        & > div:last-of-type {
            position: absolute;
            right: 0;
            top: 0.9rem;
        }
    }


`;

export const TraineeInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;

    @media (max-width:1024px) {
        overflow: hidden;
    }
`;

export const Name = styled(Column)`
    padding: 0;
    font-size: 0.9rem;
    font-weight: 500;

    @media (max-width:1024px) {
        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
`;

export const Email = styled(Column)`
    padding: 0;
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
    font-size: 0.9rem;

    @media (max-width:1024px) {
        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
`;

export const DateAdded = styled.span`
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
    font-size: 0.85rem;
    
    @media (max-width:1024px) {
        margin-top: 1rem;
        font-weight: 600;
    }
`;

export const Role = styled.span`
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
    font-size: 0.85rem;
    
    @media (max-width:1024px) {
        margin-top: 0.2rem;
        font-weight: 600;
    }
`;

export const LastInvitationSent = styled.span`
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
    font-size: 0.85rem;
    
    @media (max-width:1024px) {
        margin-top: 02.rem;
        font-weight: 600;
    }
`;

export const Status = styled.span`
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
    font-size: 0.85rem;
    
    @media (max-width:1024px) {
        margin-top: 1rem;
        font-weight: 600;
    }
`;

export const Label = styled.span`
    margin-right: 0.5rem;    
    color: ${({ theme }) => theme.colors.legacy.gray[150]};
`;