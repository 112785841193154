import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import { Container, Title, Wrapper } from "./settings.styles";
import { PrimaryButton, SecondaryButton } from "../shared/shared.styles";
import { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { useModal } from "../../context/modal/modal";
import { useUser } from "../../context/user";
import { Roles } from "../../enums/user";
import DeleteAccountModal from "../../components/ui/modal-with-context/modal-contents/delete-account-confirmation/delete-account-modal-content";
import DeleteAccountAdminModal from "../../components/ui/modal-with-context/modal-contents/delete-account-confirmation/delete-account-admin-modal-content";
import { useFeatureToggles } from "../../context/feature-toggles";
import { Features } from "../../enums/features";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const SettingsPage = () => {
    const { instance } = useMsal();
    const { state: user } = useUser();
    const { isFeatureActive } = useFeatureToggles();
    const { openModal, setWidth, setHeight } = useModal();

    const handleChangePassword = useCallback(() => {
        instance.loginRedirect({
            authority: process.env.REACT_APP_MSAL_PASSWORD_CHANGE_AUTHORITY,
            scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE],
        });
      }, [instance]);

    const handleDeleteAccount = () => {
        setWidth(504);
        setHeight(504);
        openModal(
            (user.role === Roles.Admin || isFeatureActive(Features.BackofficeDeleteAccount)) ?
                <DeleteAccountAdminModal
                title="account-delete:modal-contents:admin:description" /> 
                :
                <DeleteAccountModal 
                    title="account-delete:modal-contents:title" 
                    description="account-delete:modal-contents:description"/>
            );
      }

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="settings" fallback={<PageLoader />}>
            <Page title="Settings">
                <Wrapper>
                    <Container>
                        <Title>
                            <FormattedMessage id={"settings:change-password:title"} />
                        </Title>
                        <PrimaryButton onClick={handleChangePassword}>
                            <FormattedMessage id={"settings:change-password"} />
                        </PrimaryButton>
                    </Container>

                    <Container>
                        <Title>
                            <FormattedMessage id={"settings:delete-account:title"} />
                        </Title>
                        <SecondaryButton onClick={handleDeleteAccount}>
                            <FormattedMessage id={"settings:delete-account"} />
                        </SecondaryButton>
                    </Container>
                </Wrapper>
            </Page>
        </VRIntlProviderComponent>
        )
}

export default SettingsPage;
