import React from "react";
import { User } from "../../../../../../pages/users/users.types";
import {
    ButtonsWrapper,
    Description,
    ModalContentWrapperDiv,
    StyledCancelButton,
    StyledConfirmButton,
    Title
} from "../../modal-contents.styles";
import ConfirmationModalContent from "../confirmation/confirmation-modal-content";
import { useModal } from "../../../../../../context/modal/modal";
import {
    UserStatus,
    UserStatusAction,
    UserStatusActionNoun,
    UserStatusPerformedAction
} from "../../../../../../pages/users/components/users-table/users-table.types";
import { FormattedMessage } from "react-intl";
import { getStatus } from "../../../../../../pages/users/utils/utils";
import { useMutation, useQueryClient } from "react-query";
import { updateUserStatus } from "../../../../../../api/users";
import GenericError from "../../../../generic-error";

interface ChangeStatusModalContentProps {
    user: User;
    setShouldUpdateUsersList: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeStatusModalContent = ({ user, setShouldUpdateUsersList }: ChangeStatusModalContentProps) => {
    const { closeModal, openModal } = useModal();

    const queryClient = useQueryClient();

    const userId = user.userId;

    const currentStatus = getStatus(
        user.isRegistrationCompleted,
        user.isActive
    );

    const futureStatusActionNoun =
        currentStatus === UserStatus.Active
            ? UserStatusAction.Deactivate
            : UserStatusAction.Activate;

    const futureStatusAction =
        currentStatus === UserStatus.Active
            ? UserStatusActionNoun.Deactivation
            : UserStatusActionNoun.Activation;

    const oppositeAction =
        currentStatus === UserStatus.Active
            ? UserStatusAction.Reactivate
            : UserStatusAction.Deactivate;

    const statusDisplay =
        futureStatusActionNoun === UserStatusAction.Deactivate
            ? UserStatusPerformedAction.Deactivated
            : UserStatusPerformedAction.Activated;

    const mutation = useMutation(updateUserStatus, {
        onSuccess: () => {
            const queryKey = ["getUsers", null, null];

            queryClient.invalidateQueries(queryKey); // Invalidates cached data associated with the "getUsers" query key

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(true);
            }

            openModal(
                <ConfirmationModalContent
                    title="users:users-table:modal-contents:change-status:confirmation-modal:title"
                    titleIntlValues={{ status: statusDisplay }}
                    description="users:users-table:modal-contents:change-status:confirmation-modal:description"
                    descriptionIntlValues={{
                        oppositeAction: oppositeAction.toLowerCase()
                    }}
                />
            );

            if (setShouldUpdateUsersList) {
                setShouldUpdateUsersList(false);
            }
        },
        onError: () => {
            openModal(<GenericError />);
        }
    });

    const handleChangeStatusButton = () => {
        const isActive = currentStatus === UserStatus.Active ? false : true;

        mutation.mutate({ userId, isActive }); //PUT request to change status -> /User?UpdateRole API { userId: string, isActive: boolean}
    };

    return (
        <ModalContentWrapperDiv>
            <Title>{`${futureStatusActionNoun} user`}</Title>
            <Description>
                <FormattedMessage
                    id="users:users-table:modal-contents:change-status:description"
                    values={{
                        action: futureStatusAction,
                        name: user.displayName,
                        oppositeAction: oppositeAction.toLowerCase()
                    }}
                />
            </Description>
            <ButtonsWrapper>
                <StyledConfirmButton
                    variant="orange"
                    onClick={handleChangeStatusButton}
                >
                    <FormattedMessage id="users:users-table:modal-contents:change-status:primary-button" />
                </StyledConfirmButton>
                <StyledCancelButton onClick={closeModal}>
                    <FormattedMessage id="users:users-table:modal-contents:secondary-button" />
                </StyledCancelButton>
            </ButtonsWrapper>
        </ModalContentWrapperDiv>
    );
};

export default ChangeStatusModalContent;
