import styled from "styled-components";
import base from "../../../../themes/base/base";
import { CustomCheckedIcon, StyledRadioGroup } from "../../../onboarding/survey/survey.styles";

export const GroupName = styled.span`
  ${base.typography.sMedium};
  display: flex;
  padding: 8px 12px;
  color: ${({ theme }) => theme.colors.neutral.grey[300]};
`;

export const RoleContainer = styled.div`
  
`;

export const StatusContainer = styled.div`
  
`;

export const Divider = styled.div`
    background-color: #121212;
    height: 1px;
    margin: 0 0.75rem;
`;

export const FilterRadioButton = styled(StyledRadioGroup)`
    flex-direction: column;

    .MuiFormControlLabel-root{
        padding: 8px 12px;
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .MuiButtonBase-root{
            padding-left: 0;
        }
    }

    .radio-icon {
        width: 16px;
        height: 16px;
    }

    .MuiTypography-root{
        ${base.typography.sRegular};
    }
`;

export const FilterCheckedIcon = styled(CustomCheckedIcon)`
    width: 16px;
    height: 16px;

    &:before{
        width: 16px;
        height: 16px;
    }
`;