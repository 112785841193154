import styled from "styled-components";
import base from "../../../themes/base/base";

export const Statement = styled.span`
    border-top: 1px solid ${base.colors.primary.orange[500]};
    padding-top: 1.5rem;
    ${base.typography.regular};  
    white-space: pre-wrap;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
