import React from 'react';
import PlayPauseButton from './play-pause-button';
import FlightSlider from './flight-slider';
import { ControlsContainer, ControlsInnerContainer, TimeDisplay } from './styles'

interface ControlsProps {
  isDisabled: boolean;
  isPlaying: boolean;
  play: () => void;
  pause: () => void;
  currentTick: number;
  setTick: (index: number) => void;
  totalTickCount: number;
  currentTime: string;
  duration: string;
}

const Controls: React.FC<ControlsProps> = ({
  isDisabled,
  isPlaying,
  currentTick,
  play,
  pause,
  totalTickCount,
  setTick,
  currentTime,
  duration,
}) => {
  return (
    <ControlsContainer>
      <ControlsInnerContainer>
        <PlayPauseButton
          isDisabled={isDisabled}
          isPlaying={isPlaying}
          togglePlayPause={() => isPlaying ? pause() : play()}
        />
        <FlightSlider
          isDisabled={isDisabled}
          currentTime={currentTime}
          currentTick={currentTick}
          totalTickCount={totalTickCount}
          onSliderChange={(newValue) => setTick(newValue)}
          onPausePlayback={() => pause()}
          onResumePlayback={() => play()}
        />
      </ControlsInnerContainer>
      <TimeDisplay isDisabled={isDisabled}>{currentTime} / {duration}</TimeDisplay>
    </ControlsContainer>
  );
};

export default Controls;
