import styled from "styled-components";
import { Container } from "./../onboarding-styles";

export const SuccessContainer = styled(Container)`
    gap: 0;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28.3rem;
    height: 28.3rem;

    button{
        margin-top: 2rem;
    }
`;