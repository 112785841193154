import styled from "styled-components";

export const InstrumentsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
`;

export const InstrumentsInnerContainer = styled.div`
  display: flex;
  width: 50rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const NoDataContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: #53555A;
  font-size: 24px;
  z-index: 1;
`;

export const NoDataText = styled.div`
  line-height: 36px;
  font-size: 24px;

  & + & {
    line-height: 27px;
    font-size: 18px;
  }
`;

interface InstrumentWrapperProps {
  isDisabled?: boolean;
  noData?: boolean;
}

export const InstrumentWrapper = styled.div<InstrumentWrapperProps>`
  ${({ isDisabled, noData }) => `
    ${isDisabled ? `
        opacity: 0.3;
        filter: grayscale(100%);
      ` : ''}
    ${noData ? `
        svg {
          filter: brightness(0%);
        }
      ` : ''}
  `}
`;