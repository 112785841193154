import styled, { css } from "styled-components";
import base from "../../themes/base/base";
import Button from "../../components/ui/button";
import DateFilter from "../../components/filters/date";
import TraineesFilter from "../../components/filters/trainees";
import { PrimaryButton } from "../shared/shared.styles";

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - var(--heat-header-height) - var(--sessions-page-header-height));
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
    align-items: center;
    border-radius: 0.5rem;
    width: 31.5rem;
    height: 32rem;
    margin-bottom: 2rem;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};
    margin-bottom: 1.5rem;
}
`;

export const Description = styled.span`
    ${base.typography.medium};
`;


export const ButtonStyles = css`
    width: 28.5rem;
    padding: 0.5rem 0.75rem;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.125rem;
    background: ${base.colors.neutral.grey[900]};

    &:focus{
        border: 1px solid ${base.colors.primary.orange[500]};
    }

    span{
        ${base.typography.smallRegular};
    }   

    &:disabled{
        color: ${base.colors.neutral.grey[500]};
        background-color: ${base.colors.neutral.grey[900]};
        border: 0;

        & svg path {
            fill: ${base.colors.neutral.grey[500]};
          }
    }

    svg:nth-of-type(2){
        margin-left: auto;
    }
`;

export const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.5rem;

    > button {
        ${ButtonStyles};
    }
`;

export const StyledDateFilter = styled(DateFilter)`
    > button {
        ${ButtonStyles};
    }
`;

export const StyledTraineesFilter = styled(TraineesFilter)`
    > button {
        ${ButtonStyles};
    }
`;

export const Toggle = styled(Button)`
    ${ButtonStyles};
`;

export const StyledPrimary = styled(PrimaryButton)`
    margin-top: 1.5rem;
`;

export const Text = styled.span` 
`;

