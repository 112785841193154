import client from "../../client";

export const getImprovementScore = async () => {
	try {
		const { data } = await client.get(`/v1/Promo/score-comparison`);

		return data;
	} catch (e) {
		return {
			"dataset": [
				{
					"result": "",
					"unit": ""
				}
			],
			"heatLogoUrl": "https://hcsampleorgsastatic.blob.core.windows.net/images/Icon_HEATLogo.png",
			"simLogoUrl": "https://hcsampleorgsastatic.blob.core.windows.net/images/promo-page.png",
		};
	}
};