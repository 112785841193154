import { Container, Wrapper, LogoutWrapper } from "./styles";
import UserBadge from "../user-badge";
import { LogoutIcon } from "../../components/icons";
import { useModal } from "../../context/modal/modal";
import LogoutConfirmationModal from "../../components/ui/modal-with-context/modal-contents/logout-confirmation/logout-confirmation-modal-content";

const TopBar = () => {
  const { openModal, setWidth, setHeight } = useModal();

  const confirmLogout = () => {
    setWidth(504);
    setHeight(504);
    openModal(
      <LogoutConfirmationModal 
        title="logout:modal-contents:title" 
        description="logout:modal-contents:description"/>
    );
  }

  return (
    <Wrapper>
      <Container>
        <LogoutWrapper onClick={confirmLogout}>
          <LogoutIcon />
        </LogoutWrapper>
        <UserBadge />
      </Container>
    </Wrapper>
  );
};

export default TopBar;
