import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { getSessionData } from "../../../../api/dashboard";
import { ClockIcon } from "../../../../components/icons";
import Frame from "../../../../components/ui/frame";
import Message from "../../../../components/ui/message";
import Text from "../../../../components/ui/text";
import { useDashboardFilters } from "../../../../context/dashboard-filters";
import { useFeatureToggles } from "../../../../context/feature-toggles";
import { useUser } from "../../../../context/user";
import { Features } from "../../../../enums/features";
import { Pages } from "../../../../enums/pages";
import { Roles } from "../../../../enums/user";
import { Session, SessionEntry } from "../../../../types/session";
import buildQueryString from "../../../../utils/query-string";
import SessionsList from "../../../shared/sessions-list";
import { FullContainer } from "../../dashboard.styles";
import { Day, DayContainer, ListContainer, MessageContainer } from "./session-list.styles";

export const DAY_MS = 1000 * 3600 * 24;

type SessionListProps = {
  data: any;
  loading: boolean;
};

const List = ({ data, loading }: SessionListProps) => {
  const history = useHistory();
  const params: any = useParams();
  const { state: user } = useUser();

  if (loading) {
    return <span>Loading</span>;
  }

  const sessions = data
    .reduce((acc: Session[], { scenarios }: SessionEntry) => {
      acc.push(...scenarios);
      return acc;
    }, [])
    .sort((a: Session, b: Session) => (new Date(a.from).getTime() > new Date(b.from).getTime() ? 1 : -1));

  const minDate: Date = sessions.map(({ from }: Session) => new Date(from)).shift() || new Date();
  minDate.setHours(0, 0, 0, 0);

  const sessionsByDay = data.reduce((acc: any, { id, logoUrl, traineeName, simulationName, scenarios }: SessionEntry) => {
    scenarios.forEach((session: Session) => {
      const { from } = session;
      const d = new Date(from);
      const dayTime = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
      const minTime = minDate.getTime();

      const day = Math.floor((dayTime - minTime) / DAY_MS);

      if (!acc.hasOwnProperty(day)) {
        acc[day] = {};
      }

      if (!acc[day].hasOwnProperty(id)) {
        acc[day][id] = [];
      }

      session.logoUrl = logoUrl;
      session.simulationName = simulationName;
      session.traineeName = traineeName;
      acc[day][id].push(session);
    });

    return acc;
  }, {});

  const byDayFlattened = Object.keys(sessionsByDay).reduce((acc: any, value) => {
    acc[value] = Object.keys(sessionsByDay[value])
      .map((id) => ({
        id,
        scenarios: sessionsByDay[value][id],
      }))
      .sort((a: any, b: any) => (a.scenarios[0].from < b.scenarios[0].from ? -1 : 1));
    return acc;
  }, {});

  const handleOnItemClick = ({ session, sessionId }: { session: Session; sessionId: string }) => {
    history.push(
      `${Pages.SessionDetail}${buildQueryString({
        userId: user.role === Roles.Instructor ? params.userId : user.id,
        from: session.from,
        to: session.to,
        scenarioId: session.scenarioId,
        simulationInstanceId: sessionId,
        scenarioInstanceId: session.scenarioInstanceId,
        name: session.scenario,
      })}`, {
        logoUrl: session.logoUrl,
        simulationName: session.simulationName ?? "",
        traineeName: session.traineeName ?? ""
      }
    );
  };

  return (
    <>
      {Object.keys(byDayFlattened)
        .reverse()
        .map((key: string) => {
          const day = parseInt(key);
          const now = moment(minDate);

          const [min, max] = byDayFlattened[day]
            .map(({ scenarios }: any) =>
              scenarios.reduce((acc: string[], value: Session) => {
                acc.push(value.from, value.to);
                return acc;
              }, [])
            )
            .reduce((acc: string[], value: string[]) => {
              acc.push(...value);
              return acc;
            }, [])
            .map((value: string) => new Date(value).getTime())
            .sort((a: number, b: number) => a > b)
            .reduce((acc: null | number[], value: number) => {
              if (!acc) {
                acc = [value, value];
              }

              if (value < acc[0]) {
                acc[0] = value;
              }

              if (value > acc[1]) {
                acc[1] = value;
              }
              return acc;
            }, null);

          const diffDuration = moment.duration(moment(new Date(max)).diff(moment(new Date(min))));

          return (
            <DayContainer key={`session-list-${day}`}>
              <Day>
                <Text variant="medium">{now.add(day, "days").format("ll")}</Text>{" "}
              </Day>
              <ListContainer>
                {byDayFlattened[day].reverse().map((val: any, index: number) => {
                  const list = byDayFlattened[day][index];
                  return <SessionsList key={list.id} sessionId={list.id} data={list.scenarios.reverse()} onItemClick={handleOnItemClick} />;
                })}
              </ListContainer>
            </DayContainer>
          );
        })}
    </>
  );
};

const Wrapper = () => {
  const intl = useIntl();
  const { state: user } = useUser();
  const { state: filters } = useDashboardFilters();
  const { isFeatureActive } = useFeatureToggles();
  const params: any = useParams();

  const id = user.role === Roles.Trainee ? user.id : params?.userId;

  const { isLoading, data } = useQuery(["get-global-timeline", { ...filters, userId: id }], () => getSessionData({ ...filters, userId: id }));

  const hasData = data.length > 0;

  if (data.length === 0 && isFeatureActive(Features.HideEmptyCharts)) {
    return null;
  }

  return (
    <FullContainer>
      <Frame transparent title={intl.formatMessage({ id: "dashboard:sessions-list" })} icon={<ClockIcon />}>
        {hasData ? (
          <List data={data} loading={isLoading} />
        ) : (
          <MessageContainer>
            <Message>
              <FormattedMessage id="no-data" />
            </Message>
          </MessageContainer>
        )}
      </Frame>
    </FullContainer>
  );
};

export default Wrapper;
