import { useState, useEffect, useMemo, useCallback } from 'react';

// Function to calculate the current time in a timecode format based on the current index and step interval
export const calculateTime = (currentIndex: number, stepInterval: number) => {
  if (currentIndex < 0) {
    return '0:00'
  }
  const currentTimeInSeconds = currentIndex * stepInterval / 1000; // Convert milliseconds to seconds
  const currentMinutes = Math.floor(currentTimeInSeconds / 60); // Calculate minutes
  const currentSeconds = Math.floor(currentTimeInSeconds % 60); // Calculate remaining seconds
  const currentTime = `${currentMinutes}:${currentSeconds.toString().padStart(2, '0')}`; // Format time as MM:SS
  return currentTime;
};

// Function to get the last observation for each timestamp
const flattenObservations = (sortedData: any) => {
  return sortedData.reduce((acc: any, dataPoint: any) => {
    acc[new Date(dataPoint.DateTime).toISOString()] = dataPoint; // Use ISO string as key for each data point
    return acc;
  }, {});
};

// Function to get either the minimum or maximum DateTime from all instruments
const getMinMaxDateTime = (flattenedChartData: any, getMin: boolean) => {
  return Object.values(flattenedChartData).reduce((resultDateTime: Date | null, instrumentData: any) => {
    const instrumentDataArray = Object.values(instrumentData).flat(); // Flatten the data points
    return instrumentDataArray.reduce((currentResultDateTime: Date | null, dataPoint: any) => {
      const dataPointDateTime = new Date(dataPoint.DateTime); // Convert to Date object
      if (!currentResultDateTime) return dataPointDateTime; // Initial case
      // Return either the min or max DateTime
      return getMin ? (dataPointDateTime < currentResultDateTime ? dataPointDateTime : currentResultDateTime)
                    : (dataPointDateTime > currentResultDateTime ? dataPointDateTime : currentResultDateTime);
    }, resultDateTime);
  }, null);
};

// Custom hook to manage flight progress state
export const useFlightDataTimeline = (chartData: any, initialIndex = 0, tickDurationMs = 20) => {
  const [currentTick, setTick] = useState(initialIndex); // Current tick index
  const [isPlaying, setIsPlaying] = useState(false); // Play/pause state
  const [currentObservations, setCurrentObservations] = useState<any>({}); // Current observations state

  // Flatten chart data for easier access
  const flattenedChartData: any = useMemo(() => {
    return Object.keys(chartData).reduce((acc: any, instrument) => {
      acc[instrument] = flattenObservations(chartData[instrument])
      return acc;
    }, {});
  }, [chartData]);

  // Calculate min and max DateTime from all instruments
  const dateTimeRange = useMemo(() => {
    const minDateTime = getMinMaxDateTime(flattenedChartData, true);
    const maxDateTime = getMinMaxDateTime(flattenedChartData, false);
    return {
      minDateTime: minDateTime?.toISOString(),
      maxDateTime: maxDateTime?.toISOString(),
    };
  }, [flattenedChartData]);

  // Calculate current DateTime based on the tick index
  const currentDateTime = useMemo(() => {
    if (!dateTimeRange.minDateTime) {
      return null;
    }
    const startDateTime = new Date(dateTimeRange.minDateTime);
    const currentDateTime = new Date(startDateTime.getTime() + currentTick * tickDurationMs);
    return currentDateTime;
  }, [dateTimeRange, currentTick, tickDurationMs]);

  // Calculate total tick count based on the time range
  const totalTickCount = useMemo(() => {
    if (!dateTimeRange.minDateTime || !dateTimeRange.maxDateTime) {
      return 0;
    }
    const minDateTime = new Date(dateTimeRange.minDateTime);
    const maxDateTime = new Date(dateTimeRange.maxDateTime);
    const differenceInMilliseconds = maxDateTime.getTime() - minDateTime.getTime();
    const differenceInTicks = Math.ceil(differenceInMilliseconds / tickDurationMs);
    return differenceInTicks;
  }, [dateTimeRange]);

  // Calculate duration and current timecodes
  const durationTimecode = useMemo(() => calculateTime(totalTickCount - 1, tickDurationMs), [totalTickCount, tickDurationMs]);
  const currentTimecode = useMemo(() => calculateTime(currentTick, tickDurationMs), [currentTick, tickDurationMs]);

  // Update current observations based on the current DateTime
  useEffect(() => {
    if (!currentDateTime) {
      setCurrentObservations({});
      return;
    }
    const newObservations = Object.keys(flattenedChartData).reduce((acc: any, instrument) => {
      const currentDateTimeFloor = Math.floor(currentDateTime.getTime() / 1000) * 1000;
      const currentDateTimeCeil = Math.ceil(currentDateTime.getTime() / 1000) * 1000;
      const instrumentDataFrom = flattenedChartData[instrument][new Date(currentDateTimeFloor).toISOString()];
      const instrumentDataTo = flattenedChartData[instrument][new Date(currentDateTimeCeil).toISOString()];

      if (instrumentDataFrom && instrumentDataTo) {
        const ratio = (currentDateTime.getTime() - currentDateTimeFloor) / 1000;
        const interpolatedData = instrumentDataFrom.Data + ratio * (instrumentDataTo.Data - instrumentDataFrom.Data);
        acc[instrument] = interpolatedData;
      } else {
        acc[instrument] = null;
      }
      return acc;
    }, {});
    setCurrentObservations(newObservations); // Set the current observations state
  }, [currentDateTime, flattenedChartData]);

  // Function to start the playback
  const play = useCallback(() => {
    setIsPlaying(true); // Set the play state to true
  }, []);

  // Function to pause the playback
  const pause = useCallback(() => {
    setIsPlaying(false); // Set the play state to false
  }, []);

  // Function to reset the playback to the initial state
  const reset = useCallback(() => {
    setTick(initialIndex); // Reset the tick index to the initial value
    setIsPlaying(false); // Ensure the playback is paused
  }, [initialIndex]);

  // Effect to handle the playback tick updates
  useEffect(() => {
    let interval: any = null;
    if (isPlaying) {
      interval = setInterval(() => {
        setTick((prevTick) => {
          const nextTick = prevTick + 1;
          if (nextTick >= totalTickCount) {
            clearInterval(interval); // Stop the interval if the end is reached
            return prevTick; // Keep the tick at the last value
          }
          return nextTick; // Update the tick to the next value
        });
      }, tickDurationMs);
    } else if (interval) {
      clearInterval(interval); // Clear the interval if the playback is paused
    }
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [isPlaying, totalTickCount, tickDurationMs]);

  // Return the hook's state and control methods
  return {
    currentTick,
    setTick,
    isPlaying,
    currentObservations,
    play,
    pause,
    reset,
    durationTimecode,
    currentTimecode,
    totalTickCount,
  };
};

export default useFlightDataTimeline;
