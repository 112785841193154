import React, { useState } from "react";
import {
    HeadTableCellWithTooltipContent,
    LoaderContainer,
    StyledTableContainer
} from "./users-table.styles";
import {
    ActionMenuOption,
    UserStatus,
    UsersTableProps
} from "./users-table.types";
import moment from "moment";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import { Roles } from "../../../../enums/user";
import ChangeStatusModalContent from "../../../../components/ui/modal-with-context/modal-contents/users-page/change-status/change-status-modal-content";
import ChangeRoleModalContent from "../../../../components/ui/modal-with-context/modal-contents/users-page/change-role/change-role-modal-content";
import { User } from "../../users.types";
import Tooltip from "../../../../components/ui/tooltip/tooltip";
import { useModal } from "../../../../context/modal/modal";
import { FormattedMessage } from "react-intl";
import ResendInviteModalContent from "../../../../components/ui/modal-with-context/modal-contents/users-page/resend-invite/resend-invite-modal-content";
import { getStatus } from "../../utils/utils";
import UsersTableRow from "../users-table-row/users-table-row";
import GenericError from "../../../../components/ui/generic-error";
import Loader from "../../../../components/ui/loader";

const UsersTable = ({
    usersList,
    lastUserInRef,
    isFetching,
    isError,
    setShouldUpdateUsersList,
}: UsersTableProps) => {
    const { openModal, setWidth, setHeight } = useModal();

    const [activeDropdownIndex, setActiveDropdownIndex] =
        useState<number | null>(null);
    const [clickedItem, setClickedItem] = useState<string | null>(null);

    //DROPDOWN MENU
    const handleToggleDropdown = (index: number) => {
        if (activeDropdownIndex === index) {
            setActiveDropdownIndex(null); // Close if the same icon is clicked again
        } else {
            setActiveDropdownIndex(index); // Open the new dropdown
            setClickedItem(null); // Reset clicked item when a new dropdown is opened
        }
    };

    const handleDropdownMenuOptionClick = (menuOption: string, user: User) => {
        setClickedItem(menuOption);
        setActiveDropdownIndex(null);
        setWidth(504); //set the width of the modal for all modal-contents
        setHeight(504); //set the height of the modal for all modal-contents

        switch (menuOption) {
            case ActionMenuOption.ChangeRole:
                openModal(<ChangeRoleModalContent user={user} setShouldUpdateUsersList={setShouldUpdateUsersList} />);
                break;
            case ActionMenuOption.DeactivateUser:
            case ActionMenuOption.ReactivateUser:
                openModal(<ChangeStatusModalContent user={user} setShouldUpdateUsersList={setShouldUpdateUsersList} />);
                break;
            case ActionMenuOption.ResendInvite:
                openModal(<ResendInviteModalContent user={user} />);
                break;
            default:
                setWidth(1032); //default width of the modal
                setHeight(581); //default height of the modal
                openModal(<GenericError />);
        }
    };

    return (
        <>
            <StyledTableContainer sx={{ overflowX: "unset" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "33.16%" }}>
                                <FormattedMessage id="users:users-table:table-cell:name" />
                            </TableCell>
                            <TableCell sx={{ width: "20.57%" }} align="left">
                                <HeadTableCellWithTooltipContent>
                                    <FormattedMessage id="users:users-table:table-cell:role" />
                                    <Tooltip
                                        textArray={[
                                            "users:users-table:tooltip:role:admin",
                                            "users:users-table:tooltip:role:instructor",
                                            "users:users-table:tooltip:role:trainee"
                                        ]}
                                    />
                                </HeadTableCellWithTooltipContent>
                            </TableCell>
                            <TableCell sx={{ width: "20.57%" }} align="left">
                                <HeadTableCellWithTooltipContent>
                                    <FormattedMessage id="users:users-table:table-cell:status" />
                                    <Tooltip
                                        textArray={[
                                            "users:users-table:tooltip:status:active",
                                            "users:users-table:tooltip:status:pending",
                                            "users:users-table:tooltip:status:deactivated"
                                        ]} 
                                    />
                                </HeadTableCellWithTooltipContent>
                            </TableCell>
                            <TableCell sx={{ width: "20.57%" }} align="left">
                                <FormattedMessage id="users:users-table:table-cell:date-added" />
                            </TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersList?.map((user, index) => {
                            const role = user.roles.includes(Roles.Admin) ?
                                Roles.Admin : user.roles.includes(Roles.Instructor) ?
                                Roles.Instructor : Roles.Trainee;

                            const dateAdded = moment(user.createDate).format("DD/MM/YYYY");

                            const status = getStatus(user.isRegistrationCompleted, user.isActive); 

                            let dropdownMenuOptions: ActionMenuOption[] = [];
                            switch (status) {
                                case UserStatus.Active:
                                    dropdownMenuOptions = [
                                        ActionMenuOption.ChangeRole,
                                        ActionMenuOption.DeactivateUser
                                    ];
                                    break;
                                case UserStatus.Pending:
                                    dropdownMenuOptions = [
                                        ActionMenuOption.ResendInvite
                                    ];
                                    break;
                                case UserStatus.Deactivated:
                                    dropdownMenuOptions = [
                                        ActionMenuOption.ChangeRole,
                                        ActionMenuOption.ReactivateUser
                                    ];
                                    break;
                            }

                            const isLastUserInRef = usersList?.length === index + 1;

                            if (isLastUserInRef) {
                                return (
                                    <UsersTableRow
                                        key={`lastUserInRef${user.email}`}
                                        user={user}
                                        lastUserInRef={lastUserInRef}
                                        role={role}
                                        status={status}
                                        dateAdded={dateAdded}
                                        activeDropdownIndex={activeDropdownIndex}
                                        setActiveDropdownIndex={setActiveDropdownIndex}
                                        index={index}
                                        clickedItem={clickedItem}
                                        handleToggleDropdown={handleToggleDropdown}
                                        dropdownMenuOptions={dropdownMenuOptions}
                                        handleDropdownMenuOptionClick={handleDropdownMenuOptionClick}
                                    />
                                );
                            } else return (
                                <UsersTableRow
                                    key={user.email}
                                    user={user}
                                    role={role}
                                    status={status}
                                    dateAdded={dateAdded}
                                    activeDropdownIndex={activeDropdownIndex}
                                    setActiveDropdownIndex={setActiveDropdownIndex}
                                    index={index}
                                    clickedItem={clickedItem}
                                    handleToggleDropdown={handleToggleDropdown}
                                    dropdownMenuOptions={dropdownMenuOptions}
                                    handleDropdownMenuOptionClick={handleDropdownMenuOptionClick}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
                {(isFetching && !isError) && 
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                }
            </StyledTableContainer>
        </>
    );
};

export default UsersTable;
